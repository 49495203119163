<template>
  <v-container class="px-10" fluid style="height:100vh; background: #e7f0f7;">
    <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
      MEMBERS REPORT
    </div>
    <searchAndFilterToolbar :btn_text="serv_text" @btn_action="openAddMember" @search="searhMember"
      @filterBtn="openFilter()" :placeholder="'Search with ID, Name and Email'" />
    <Transition name="slide-fade">
      <div class="mt-4 mb-2" v-if="filterDisplay">
        <v-row>
          <v-col cols="12" md="2">
            <v-autocomplete v-model="selStatus" hide-details variant="outlined" density="comfortable" :items="statuses"
              item-title="name" clearable item-value="id" label="Status">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete v-model="memRole" hide-details variant="outlined" density="comfortable" :items="memberRole"
              item-title="type" clearable item-value="id" label="Type">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete v-model="Dt" hide-details variant="outlined" density="comfortable" :items="dietType"
              item-title="name" item-value="id" clearable label="Diet Type">
            </v-autocomplete>
          </v-col>
          <!-- <v-col cols="12" md="2">
            <v-autocomplete v-model="selBorough" hide-details variant="outlined" density="comfortable"
              :items="boroughList" item-title="name" item-value="id" clearable label="Borough">
            </v-autocomplete>
          </v-col> -->
          <v-col cols="12" md="2">
            <v-select v-model="selGrp" hide-details :items="GrpType" item-title="name" item-value="id" clearable
              label="Group" variant="outlined" density="comfortable">
            </v-select>
          </v-col>
          <v-col cols="12" md="2" style="align-self: center;">
            <div class="d-flex">
              <v-btn style="background: rgb(4 43 76) ;" @click="filterdata()"> <span style="color: white">filter</span>
              </v-btn>
              <JsonCSV :data="json_data" l name="memberReport">
                <v-btn style="background: rgb(4 43 76) ;" class="ml-5"> <span style="color: white">Export CSV</span>
                </v-btn>
              </JsonCSV>
            </div>
          </v-col>
        </v-row>
      </div>
    </Transition>
    <v-table class="rounded-lg mt-4">
      <thead>
        <tr>
          <th class="text-left">
            ID
          </th>
          <th class="text-left">
            NAME
          </th>
          <th class="text-left">
            PHONE
          </th>
          <th class="text-left">
            TYPE
          </th>
          <th class="text-left">
            BOROUGH
          </th>
          <th class="text-left">
            UNIVERSITY
          </th>
          <th class="text-left">
            DIET TYPE
          </th>
          <th class="text-left">
            REGISTRATION DATE
          </th>
          <th class="text-left">
            STATUS
          </th>
          <!-- <th class="text-left">
                      Actions
                  </th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item) in memberList" :key="item.id">

          <td>
            {{ item.id }}
            <v-icon class="ml-1" v-if="item.parent && item.groupId" style="color: #f18205;">
              mdi-account-group
            </v-icon>
          </td>
          <td>{{ item.firstname + ' ' + item.lastname }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.membersRole.type }}</td>
          <td>{{ item.borough }}</td>
          <td>{{ item.university?.name }}</td>
          <td>{{ item.dietType.type }}</td>
          <!-- <td v-if="item.diettype"> Vegeterian </td>
          <td v-else> Halal </td> -->

          <td>{{ item.createdAt.split('T')[0] }}</td>
          <!-- <td>{{ item.status }}</td> -->

          <td class="">
            <v-chip color="green" v-if="item.status.statustype == 'Active'" style="min-width: 100px; cursor: pointer;">
              <span class="ma-auto">{{
              item.status.statustype
              }}</span>
              <!-- <v-menu activator="parent">
                              <v-list class="pa-0">
                                  <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                     
                                      :key="it">
                                      <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                  </v-list-item>
                              </v-list>
                          </v-menu> -->
            </v-chip>
            <v-chip color="#fd5e00" v-else-if="item.status.statustype == 'In-Active'"
              style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
              item.status.statustype
              }}</span>
              <!-- <v-menu activator="parent">
                              <v-list class="pa-0">
                                  <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                     
                                      :key="it">
                                      <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                  </v-list-item>

                              </v-list>
                          </v-menu> -->
            </v-chip>
          </td>
          <!-- <td>
                      <v-hover v-slot="{ isHovering, props }">
                          <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="toInnerPage(item)"
                              :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                              style="cursor: pointer;">
                              <v-icon size="18" icon="mdi-eye-outline"></v-icon>
                          </v-avatar>
                      </v-hover>
                      <v-hover v-slot="{ isHovering, props }">
                          <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                              :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                              style="cursor: pointer;">
                              <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                          </v-avatar>
                      </v-hover>
                      <v-hover v-slot="{ isHovering, props }">
                          <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteDailog(item)"
                              :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                              style="cursor: pointer;">
                              <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                          </v-avatar>
                      </v-hover>
                  </td> -->
        </tr>
      </tbody>
    </v-table>
    <paginationVue :length="memberTotalPage" @chanegePage="chanegePage" />
    <addMembers :visible="dialog" @close="dialog = false" @add_memeber="addMembers" :title="dialog_title" />
    <editMember :visible="edtDialog" @close="edtDialog = false" @update_memeber="updateMember" :title="editDialog_title"
      :val="memmerWithId" />
    <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false" :item="deleteValue" @delete="deleteMember" />
  </v-container>
</template>

<script>
import JsonCSV from 'vue-json-csv'

import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import addMembers from '@/components/members/addMembers.vue';
import editMember from '@/components/members/editMember.vue';
import deleteWarnVue from '@/components/deleteWarn.vue';
import paginationVue from '@/components/pagination.vue';

import { mapActions, mapState } from 'vuex';
export default {
  name: 'membersReport',
  components: {
    searchAndFilterToolbar,
    addMembers,
    editMember,
    deleteWarnVue,
    paginationVue,
    JsonCSV

  },
  data() {
    return {
      selStatus: '',
      memRole: '',
      Dt: '',
      selBorough: '',
      filterDisplay: false,
      // serv_text: 'Add Members',
      dialog: false,
      edtDialog: false,
      delete_dialog: false,
      deleteValue: {},
      dialog_title: 'Add Members',
      editDialog_title: 'Edit Members',
      edit_value: {},
      statuses: [
        {
          id: 1,
          name: 'Active'
        },
        {
          id: 2,
          name: 'In-Active'
        },
      ],
      memRole: '',
      dietType: [{ id: 1, name: 'Halal' }, { id: 2, name: 'Non-Halal' }, { id: 3, name: 'veg' }],
      bo: ['Borough 1', 'Borough 2'],
      json_data: [],
      GrpType: [
        {
          id: 1,
          name: 'Group'
        },
        {
          id: 2,
          name: 'Non-Group'
        },
      ],
      selGrp: ''
    }
  },
  computed: {
    ...mapState('borough', ['boroughList']),
    ...mapState('member', ['memberList', 'memmerWithId', 'memberRole', 'memberTotalPage', 'memberTotalCount', 'memberRoportList']),
    ...mapState('university', ['universityLis'])

  },

  methods: {
    ...mapActions('member', ['GET_MEMBER_LIST', 'GET_MEMBER_ROLE', 'ADD_MEMBER', 'DELETE_MEMBER', 'GET_MEMBER_BY_ID', 'UPDATE_MEMBER', 'GET_MEMBER_REPORT']),
    ...mapActions('borough', ['GET_BORUGH_LIST']),
    ...mapActions('university', ['GET_UNIVERSITY_LIST']),

    // async addMembers(item) {
    //   console.log(item);
    //   await this.ADD_MEMBER(item);
    //   this.dialog = false;
    //   this.GET_MEMBER_LIST();


    // },
    // openAddMember() {
    //   if (window.innerWidth < 780) {
    //     this.$router.push({ name: 'addmembers' })
    //   } else {
    //     this.dialog = true

    //   }
    // },

    // async openEditDialog(item) {
    //   const payload = {
    //     id: item.id
    //   }
    //   await this.GET_MEMBER_BY_ID(payload).then(() => {
    //     this.memmerWithId = this.edit_value
    //     this.edtDialog = true
    //   })
    // },
    // async updateMember(item) {
    //   // console.log(item);
    //   await this.UPDATE_MEMBER(item);
    //   this.edtDialog = false;
    //   await this.GET_MEMBER_LIST();
    // },

    // toInnerPage(item) {
    //   this.$router.push({ name: 'membersInner', params: { memberId: item.id } })
    // },
    openFilter() {
      this.toCSV();
      this.filterDisplay = !this.filterDisplay
    },
    openDeleteDailog(item) {
      this.delete_dialog = true
      this.deleteValue = item
    },
    async deleteMember(item) {
      // console.log(item);
      await this.DELETE_MEMBER(item);
      this.delete_dialog = false;
      await this.GET_MEMBER_LIST();
    },
    filterdata() {
      const query = {
        sid: this.selStatus,
        rid: this.memRole,
        diet: this.Dt,
        bid: this.selBorough,
        grp: this.selGrp,
        size: 15,
      }
      this.GET_MEMBER_LIST(query).then(() => {
        this.toCSV()
      })
    },
    searhMember(item) {
      const query = {
        size: 15,
        search: item

      }
      this.GET_MEMBER_LIST(query);
    },
    chanegePage(item) {
      const query = {
        page: item,
        sid: this.selStatus,
        rid: this.memRole,
        diet: this.Dt,
        bid: this.selBorough,
        grp: this.selGrp,
        size: 15
      }
      this.GET_MEMBER_LIST(query).then(() => {
        this.toCSV()
      })
    },
    async changeStatus(stat, item) {
      // console.log({'stat': stat, 'item': item } )
      const payload = {
        statusId: stat.id,
        id: item.id
      }
      await this.UPDATE_MEMBER(payload);
      await this.GET_MEMBER_LIST();
    },
    async toCSV() {
      const params = {
        size: this.memberTotalCount,
        sid: this.selStatus,
        rid: this.memRole,
        diet: this.Dt,
        bid: this.selBorough,
        grp: this.selGrp,
      }
      await this.GET_MEMBER_REPORT(params);
      this.json_data = []
      this.memberRoportList.forEach((el, i) => {
        this.json_data.push({
          ID: el.id,
          NAME: el.firstname + ' ' + el.lastname,
          PHONE: el.phone,
          TYPE: el.membersRole.type,
          BOROUGH: el.borough,
          UNIVERSITY: el.university?.name,
          DIET_TYPE: this.setDietType(el.diettype),
          STATUS: el.status.statustype,
          REGISTRATION_DATE: el.createdAt.split('T')[0],
          ADDRESS: el.address,
          ZIP: el.zip,
          DEPENDENT_NAME: el.dependentname,
          DEPENDENT_EMAIL: el.dependentemail,
          DEPENDENT_PHONE: el.dependentphone,

        })

        el.childrens.forEach((da, ind) => {
          // console.log(da)
          var child = `CHILD${ind + 1}`;
          var dob = `DOB${ind + 1}`;
          this.json_data[i][child] = da.name;
          this.json_data[i][dob] = da.dob.split('T')[0];
        })

      })
      console.log(this.json_data);
    },
    setDietType(bol) {
      if (bol == true) {
        return 'Vegeterian'
      } else {
        return 'Halal'
      }
    }

  },
  async mounted() {
    const query = {
      size: 100
    }
    await this.GET_MEMBER_LIST()


    this.GET_MEMBER_ROLE();
    this.GET_UNIVERSITY_LIST(query);
    // 

  }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.Open {
  background: #1985d0
}

.Accepted {
  background: #20ad8c
}

.Completed {
  background: #fd5e00
}

.verified {
  background: #1ad539
}
</style>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>