<template>
    <v-container class="px-10" fluid style="height: 100vh; background: #e7f0f7;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            DISTRIBUTION LOG
        </div>
        <searchAndFilterToolbar :btn_text="serv_text" @btn_action="dialog = true" @filterBtn="openFilter()" @search="searhInDistribution" :placeholder="searchPlaceHolder" />
        <Transition name="slide-fade">
            <div class="mt-4 mb-2" v-if="filterDisplay">
                <v-row>
                    <v-col cols="12" md="3">
                        <v-autocomplete v-model="type" hide-details variant="outlined" density="comfortable"
                            :items="memberRole" item-title="type" clearable item-value="id" label="Type">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete v-if="appUserList" v-model="staffId" hide-details variant="outlined"
                            density="comfortable" :items="appUserList" item-title="fullname" clearable item-value="id"
                            label="Staff">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field color="" hide-details label="Week Number" clearable density="comfortable"
                            v-model="Wno" variant="outlined">
                        </v-text-field>
                    </v-col>


                    <v-col cols="12" md="2" style="align-self: center;">
                        <v-btn style="background: rgb(4 43 76) ;" @click="filter()"> <span
                                style="color: white">filter</span> </v-btn>
                    </v-col>
                </v-row>
            </div>
        </Transition>
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <th class="text-left">
                        MEMBER ID
                    </th>
                    <th class="text-left">
                        NAME
                    </th>
                    <th class="text-left">
                        TYPE
                    </th>
                    <th class="text-left">
                        EXTRAS
                    </th>
                    <th class="text-left">
                        STAFF
                    </th>
                    <th class="text-left">
                        WEEK NO
                    </th>
                    <!-- <th class="text-left">
                        YEAR
                    </th> -->
                    <th class="text-left">
                        COLLECTION DATE
                    </th>
                    <th class="text-left">
                        ACTIONS
                    </th>


                </tr>
            </thead>
            <tbody>
                <tr v-for="item in destributionLogList" :key="item.name">


                    <td>{{ item.memberId }}</td>
                    <td>{{ item.member.firstname + ' ' + item.member.lastname }}</td>
                    <td>{{ item.member.membersRole.type }}</td>

                    <td>
                        <!-- <span v-if="index != names.length - 1">, </span> -->
                        <span v-if="item.collectionLogs.length != 0" class="item d-flex">
                            <p v-for="(extras, i) in item.collectionLogs" :key="i">
                                {{ extras.extra.extrasname }} <span v-if="i != item.collectionLogs.length - 1">,</span>
                            </p>
                        </span>
                    </td>
                    <td v-if="item.staff">{{ item.staff.fullname }}</td>
                    <td>{{ item.weekNumber }}</td>
                    <!-- <td v-else>Admin</td> -->
                    <td>{{ item.createdAt.split('T')[0] }}</td>
                    <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="toInnerPage(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-eye-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteDailog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                    </td>

                </tr>
            </tbody>
        </v-table>
        <!-- <paginationVue :length="destributionLogTotalPage" @chanegePage="chanegePage" /> -->
        <v-pagination rounded="circle" v-model="page" class="my-4" :length="destributionLogTotalPage" :total-visible="6"
            @update:model-value="chanegePage"></v-pagination>
        <addDistibutionVue :visible="dialog" @close="dialog = false" @save="addDistribution" :title="serv_text" />
        <editDistributionVue :visible="edit_dialog" @close="edit_dialog = false" @save="addDistribution"
            :title="edit_text" :item="destributionLogWithId" />
        <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false" :item="deleteValue"
            @delete="deleteDistLog" />
        <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />

    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import paginationVue from '@/components/pagination.vue';

import addDistibutionVue from '@/components/distribution/addDistibution.vue';
import editDistributionVue from '@/components/distribution/editDistribution.vue';
import deleteWarnVue from '@/components/deleteWarn.vue';
import snackbarVue from '@/components/snackbar.vue'

// import addcalibrationVue from '@/components/addCalibration.vue';
export default {
    name: 'distribution',
    components: {
        searchAndFilterToolbar,
        paginationVue,
        addDistibutionVue,
        editDistributionVue,
        deleteWarnVue,
        snackbarVue
    },
    data() {
        return {
            serv_text: 'Add Distribution',
            edit_text: 'Edit Distribution',
            dialog: false,
            edit_dialog: false,
            filterDisplay: false,
            type: '',
            Wno: '',
            delete_dialog: false,
            deleteValue: {},
            snackbar: false,
            staffId: '',
            mssg: '',
            snackColor: '',
            searchPlaceHolder: 'Search with Member ID, Name and Email',

            currentPage: '',
            page: 1,

        }
    },
    computed: {
        ...mapState('destributionLog', ['destributionLogList', 'destributionLogTotalPage', 'destributionLogWithId']),
        ...mapState('appUser', ['appUserList', 'appUserWithId', 'appUserTotalPage']),
        ...mapState('member', ['memberRole']),
    },
    methods: {
        ...mapActions('appUser', ['GET_APP_USER_LIST']),
        ...mapActions('member', ['GET_MEMBER_ROLE']),
        ...mapActions('destributionLog', ['GET_DESTRIBUTION_LOG_LIST', 'GET_DISTRIBUTION_LOG_BY_ID', 'DELETE_DIST_LOG']),
        ...mapActions('extras', ['GET_EXTRA_LIST']),

        changeStatus(status, item) {


        },
        openFilter() {

            this.filterDisplay = !this.filterDisplay
        },
        chanegePage(item) {
            this.currentPage = item
            this.$router.replace({ query: { page: this.currentPage } })
            const query = {
                page: item,
                size: 15
            }
            this.GET_DESTRIBUTION_LOG_LIST(query)
        },
        filter() {
            this.page = 1
            this.$router.replace({ query: { page: 1 } })
            const query = {
                size: 15,
                wn: this.Wno,
                rid: this.type,
                sid: this.staffId,
                page: 1
            }
            this.GET_DESTRIBUTION_LOG_LIST(query)

        },
        addDistribution(payload) {
            console.log(payload)
        },
        toInnerPage(item) {
            this.$router.push({ name: 'distribution_log_inner_page', params: { id: item.id } })
            // const query = {
            //     id: item.id
            // }
            // this.GET_DESTRIBUTION_LOG_LIST(query)
        },
        openEditDialog(item) {
            const query = {
                id: item.id
            }
            this.GET_DISTRIBUTION_LOG_BY_ID(query).then(() => {
                const quer2 = {
                    role: this.destributionLogWithId.member.membersRoleId
                }
                // this.GET_EXTRA_LIST(query2)
                this.edit_dialog = true

            })
        },
        openDeleteDailog(item) {
            this.delete_dialog = true
            this.deleteValue = item
        },
        searhInDistribution(val){
            this.page = 1
            this.$router.replace({ query: { page: 1 } })

            const query = {
                search: val,
                wn: this.Wno,
                rid: this.type,
                page: 1,
                size: 15
            }
            this.GET_DESTRIBUTION_LOG_LIST(query)

        },
        deleteDistLog(val) {
            // console.log(val)
            this.DELETE_DIST_LOG(val).then(() => {
                this.mssg = 'Distribution Log Deleted Successfully';
                this.snackColor = 'success';
                this.snackbar = true
                this.GET_DESTRIBUTION_LOG_LIST({ page: this.$route.query.page, size: 15 })
                this.delete_dialog = false

            }).catch(err => {
                console.log(err)
            })

        }
    },
    mounted() {
        const query = {
            size: 99999
        }
        this.page = parseInt(this.$route.query.page)

        this.GET_DESTRIBUTION_LOG_LIST({ page: this.$route.query.page, size: 15  });
        this.GET_MEMBER_ROLE();
        this.GET_EXTRA_LIST();

        this.GET_APP_USER_LIST(query);
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.item+.item:before {
    content: ", ";
}
</style>