<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-bottom-transition" activator="parent">
            <v-card width="800">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">{{ title }}</div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field color="#9c27b0" label="Member ID" disabled density="comfortable"
                                    v-model="val.memberId" variant="outlined">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-autocomplete variant="outlined" density="comfortable" v-model="val.eventId" dense
                                    clearable :items="eventList" item-title="eventname" item-value="id" label="Events">
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-textarea variant="outlined" hide-details label="Remarks" v-model="val.remark" auto-grow
                                outlined rows="2" row-height="25" shaped>
                            </v-textarea>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="updateEventLog()">
                        save
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />

</template>
<script>
import { mapState, mapActions } from 'vuex';
import snackbarVue from '@/components/snackbar';
import axios from 'axios'

export default {
    name: 'addDistribution',
    components: {
        snackbarVue
    },
    data() {
        return {
            dialog: false,


            snackbar: false,
            snackColor: '',
            mssg: '',

            remarks: '',

        }
    },
    watch: {
        async 'val.eventId'(val, oldVl) {
            if (val != this.temp) {
                console.log(this.temp)
                const auth = JSON.parse(localStorage.getItem('user'));
                const check = await axios({
                    method: 'get',
                    url: `${process.env.VUE_APP_BASE_URL}/check_user_eventdistribution`,
                    params: {
                        mid: this.val.memberId,
                        evid: val
                    },
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
                })
                console.log(check)
                if (check.data.response === 'failed' || check.data.response === 'error') {
                    this.mssg = check.data.message;
                    this.snackColor = 'error';
                    this.snackbar = true
                    return this.val.eventId = this.temp 
                }
            }

        }
        // extrasID(newVl, oldVl) {
        //     // console.log(newVl[newVl.length-1])
        //     this.checkExtra(newVl[newVl.length - 1])
        // }
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        },
        val: {
            type: Array
        },
        temp: {
            type: Number
        }

    },
    computed: {
        ...mapState('events', ['eventList', 'eventTotalPage']),


        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        ...mapActions('eventsLog', ['GET_EVENT_LOG_LIST', 'DELETE_EVENT_LOG', 'UPDATE_EVENT_LOG']),
        updateEventLog(){
            const payload = {
                id: this.val.id,
                memberId: this.val.memberId,
                eventId: this.val.eventId,
                remarks: this.val.remark
            }
                this.UPDATE_EVENT_LOG(payload).then(()=>{
                this.show = false
                this.GET_EVENT_LOG_LIST({ page: this.$route.query.page, size: 15 })
            })
        }
    },
    created() {
        this.name = '';
        this.image = '';
        this.modelNo = ''
    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
}
</style>