<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-top-transition" activator="parent">
            <v-card width="1000">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">Edit Support</div>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field color="" label="name" hide-details density="comfortable"
                                v-model="val.fullname" variant="outlined">
                            </v-text-field>
                            <span v-if="v$.name.$error" style="color: red">
                                {{ v$.name.$errors[0].$message }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field color="" label="Mobile" hide-details density="comfortable"
                                v-model="val.mobile" variant="outlined">
                            </v-text-field>
                            <span v-if="v$.mobile.$error" style="color: red">
                                {{ v$.mobile.$errors[0].$message }}
                            </span>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field color="" label="Email" hide-details density="comfortable" v-model="val.email"
                                variant="outlined">
                            </v-text-field>
                            <span v-if="v$.email.$error" style="color: red">
                                {{ v$.email.$errors[0].$message }}
                            </span>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">
                            <div>

                                <v-card-text class="px-0 pb-1 pt-5">
                                    <!-- <div v-if="v$.radios.$error" style="color: red;font-size: 16px;">
                                        Please Add your Accommodation type
                                    </div> -->
                                    <div>
                                        Accommodation Type
                                    </div>
                                </v-card-text>
                                <v-radio-group v-model="val.accommodation" inline hide-details>
                                    <v-radio color="#ef8204" label="House" value="House"></v-radio>
                                    <v-radio color="#ef8204" label="Flat" value="Flat"></v-radio>
                                    <v-radio color="#ef8204" label="One Room in shared accomodation"
                                        value="One Room in shared accomodation"></v-radio>
                                    <v-radio color="#ef8204" label="Two rooms in shared accomodation"
                                        value="Two rooms in shared accomodation"></v-radio>
                                    <!-- <span v-if="v$.others.$error" style="color: red;margin-left: 39px;">
                                        Maximum 60 characters
                                    </span> -->
                                    <v-radio color="#ef8204" label="Other " value="Other">
                                        <!-- <template v-slot:label>
                                            <v-text-field placeholder="other" hide-details="" v-model="others"
                                                variant="outlined" counter="" density="compact"
                                                @click="radios = 'Other '"></v-text-field>
                                        </template> -->
                                    </v-radio>
                                </v-radio-group>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6" v-if="val.accommodation.startsWith('Other')">
                            <v-text-field color="" label="Other" hide-details density="comfortable"
                                v-model="val.accommodation" variant="outlined">
                            </v-text-field>
                            <!-- <span v-if="v$.mobile.$error" style="color: red">
                                {{ v$.mobile.$errors[0].$message }}
                            </span> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field color="" label="First Language" hide-details density="comfortable"
                                v-model="val.firstLanguage" variant="outlined">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select :items="lang" variant="outlined" hide-details density="comfortable"
                                v-model="val.english" clearable label="Speaks English">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select :items="emp" item-title="title" item-value="val" variant="outlined"
                                density="comfortable" v-model="val.type" clearable label="">
                                <template v-slot:label>
                                    <div>Type Of Employment</div>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field color="" label="Emigration status" hide-details density="comfortable"
                                v-model="val.immigrationStatus" variant="outlined">
                            </v-text-field>
                            <!-- <span v-if="v$.mobile.$error" style="color: red">
                                {{ v$.mobile.$errors[0].$message }}
                            </span> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select :items="items" variant="outlined" hide-details density="comfortable"
                                v-model="val.subject" clearable label="subjects">
                            </v-select>
                            <span v-if="v$.selSub.$error" style="color: red">
                                {{ v$.selSub.$errors[0].$message }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-textarea variant="outlined" label="Please explain your problem in detail"
                                v-model="val.description" auto-grow outlined rows="3" row-height="25" shaped>
                            </v-textarea>
                            <span v-if="v$.desc.$error" style="color: red">
                                {{ v$.desc.$errors[0].$message }}
                            </span>
                        </v-col>
                    </v-row>


                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="editSup()">
                        save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { helpers, required, numeric, decimal, maxLength, email } from "@vuelidate/validators";

export default {
    name: 'edditSupport',
    data() {
        return {
            v$: useVuelidate(),

            dialog: false,
            fdate: '',
            items: ['Employment', 'Landlord/property housing issues', 'Immigration', 'Other'],
            distribution: [
                {
                    id: 1,
                    name: 'Weekly'
                },
                {
                    id: 2,
                    name: 'Monthly'
                },
                {
                    id: 3,
                    name: 'Twice a month'
                },
            ],

            name: '',
            email: '',
            mobile: '',
            selSub: '',
            desc: '',
            lang: ["Yes", "No"],
            emp: [
                {
                    title: 'Full time employee (40 hrs/week)',
                    val: true
                },
                {
                    title: 'Part time employee ( less than 39 hrs/week)',
                    val: false
                }
            ],

        }
    },
    validations() {
        return {
            name: { required },
            email: { required, email },
            mobile: { required, numeric, maxLength: maxLength(12) },
            selSub: { required },
            desc: { required },
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        val: {
            type: Array
        }


    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    watch: {
        // date(newVl, oldVl){
        //     this.fdate = this.date.split('T')[0]
        // }
    },
    methods: {
        editSup() {
            this.name = this.val.fullname;
            this.email = this.val.email;
            this.mobile = this.val.mobile;
            this.selSub = this.val.subject;
            this.desc = this.val.description;

            this.v$.$validate()
            const payload = {
                id: this.val.id,
                name: this.val.fullname,
                email: this.val.email,
                mobile: this.val.mobile,
                subject: this.val.subject,
                description: this.val.description,
                address:this.val.address,
                accommodation: this.val.accommodation,
                immigrationStatus: this.val.immigrationStatus,
                firstLanguage: this.val.firstLanguage,
                english: this.val.english,
                type:this.val.type
            }
            if (!this.v$.$error) {
                this.$emit('save', payload);
            }
        }
    },
    created() {
        // this.name = '';

    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>