<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-top-transition" activator="parent">
            <v-card width="400">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">{{ title }}</div>
                </v-card-title>

                <v-card-text>


                    <v-text-field color="" label="name" hide-details  density="comfortable" v-model="name"
                        variant="outlined">
                    </v-text-field>
                    <!-- <div class="date d-flex pt-3" style="">
                                        <p class="mr-2" style="color:#7d7d86;"> Date:</p>
                                        <input type="date" v-model="fromDate" />
                     </div> -->
                    <!-- <div class="date d-flex pt-3" style="">
                                        <p class="mr-2" style="color:#7d7d86;">To Date:</p>
                                        <input type="date" v-model="toDate" />
                     </div> -->

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="addEvent()">
                        save
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: 'addEvent',
    data() {
        return {
            dialog: false,
            name: '',
            fromDate: '',
            toDate: '',
         
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        }

    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        addEvent() {

            const payload = {
                eventsName: this.name,
                // date: this.fromDate
                statusId: 1
            }
            if(!this.name ) return alert('please enter name !')
                // console.log(payload)
            this.$emit('save', payload);
            this.name = '';
            // this.fromDate = '';

        }
    },
    created() {
        

    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>