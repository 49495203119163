<template>
    <v-container class="px-10 " fluid style=" background:#e7f0f7 ;">
        <v-icon @click="routerBack()">mdi-arrow-left</v-icon>
        <v-card class=" mt-3 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-title class="text-h5">
                Personal Details
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Id: </span> {{ memmerWithId.id }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap"> <span class="detval"> Name: </span> {{ memmerWithId.firstname + ' ' +
                                    memmerWithId.lastname
                            }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Phone: </span> {{ memmerWithId.phone }} </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Email: </span> {{ memmerWithId.email }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap" v-if="memmerWithId.membersrole"> <span class="detval"> Type: </span> {{
                                    memmerWithId.membersrole.type
                            }} </span>


                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap" v-if="memmerWithId.dependentname"> <span class="detval"> Dependent Name:
                                </span> {{ memmerWithId.dependentname }} </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="4">

                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap" v-if="memmerWithId.membersRole"> <span class="detval"> Type:
                                </span> {{ memmerWithId.membersRole.type }} </span>
                            <span v-if="memmerWithId.membersRoleId > 1">( {{ memmerWithId.membertype }} )</span>

                        </v-card-title>
                        <!-- <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap" v-if="memmerWithId.noofkids"> <span class="detval"> Type:
                                </span> {{ memmerWithId.noofkids }} </span>

                        </v-card-title> -->
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class=" mt-3 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-title class="text-h5">
                Conatct Details
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <!-- <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span class="cap" v-if="memmerWithId.area"> <span class="detval"> City/Town: </span> Town
                            </span>
                            <span class="cap" v-else> <span class="detval"> City/Town: </span> Town </span>

                        </v-card-title> -->
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap"> <span class="detval"> Zip: </span> {{ memmerWithId.zip }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap"> <span class="detval"> Borough: </span> {{ memmerWithId.borough }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap"> <span class="detval"> County: </span> {{ memmerWithId.country }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span v-if="memmerWithId.borough"> <span class="detval"> Borough: </span> {{
                                    memmerWithId.borough.name
                            }} </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> House No: </span> {{ memmerWithId.houseno }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Adress-line-1: </span> {{ memmerWithId.road }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Adress: </span> {{ memmerWithId.address }} </span>
                        </v-card-title>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class=" mt-3 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-title class="text-h5">
                Education Details
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span v-if="memmerWithId.arrivedin"> <span class="detval"> Arrived date: </span> {{
                                    memmerWithId.arrivedin.split('T')[0]
                            }} </span>


                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> StudentId: </span> {{ memmerWithId.studentid }} </span>

                            <!-- <span class="detval"> StudentId </span>
                            : 5613256 -->
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span v-if="memmerWithId.university"> <span class="detval"> University: </span> {{
                                    memmerWithId.university.name
                            }} </span>

                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span v-if="memmerWithId.coursestart"> <span class="detval"> Course Start date: </span> {{
                                    memmerWithId.coursestart.split('T')[0]
                            }}
                            </span>

                            <!-- <span class="detval"> Course Start date </span>
                            : 12/6/2021 -->
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span v-if="memmerWithId.courseend"> <span class="detval"> Course End date: </span> {{
                                    memmerWithId.courseend.split('T')[0]
                            }} </span>

                            <!-- <span class="detval"> Course End date </span>
                            : 12/6/2021 -->
                        </v-card-title>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class=" mt-3 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-title class="text-h5">
                Food Details
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <!-- <span v-if="memmerWithId.diettype"> <span class="detval" > Dietary Requirements: </span> Vegetarian </span>
                            <span v-else > <span class="detval" > Dietary Requirements: </span> Halal </span> -->
                            <span v-if="memmerWithId.dietType"> <span class="detval"> Diet Type: </span> {{
                                    memmerWithId.dietType.type
                            }} </span>

                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span v-if="memmerWithId.eggs"> <span class="detval"> Eggs: </span> Yes </span>
                            <span v-else> <span class="detval"> Eggs: </span> No </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Preferred Collection Day: </span>
                                {{ memmerWithId.preferredcollectionday }} </span>
                        </v-card-title>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-if="memmerWithId.dependentname" class="mt-3 rounded-lg"
            style="font-family: 'Montserrat', sans-serif;">
            <v-card-title class="text-h5">
                Dependent Details
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Dependent Name: </span> {{ memmerWithId.dependentname }}
                            </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Email: </span> {{ memmerWithId.dependentemail }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Phone: </span> {{ memmerWithId.dependentphone }} </span>
                        </v-card-title>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-3 rounded-lg" 
            style="font-family: 'Montserrat', sans-serif ">
            <v-card-title class="text-h5">
                Kid's Details
            </v-card-title>


            <v-card-text>
                <v-card-title class="pa-1 d-flex">
                    <span> <span class="detval"> Number of Kid's: </span> {{ memmerWithId.noofkids }} </span>
                </v-card-title>
                <v-card-title class="pa-1 d-flex" v-if="memmerWithId.expected">
                    <span> <span class="detval"> Expected Date: </span> {{ memmerWithId.expected.split('T')[0] }} </span>
                </v-card-title>

                <v-row v-if="memmerWithId.noofkids !== 'None' && memmerWithId.noofkids !== 'Expected'">
                    <v-col cols="12" md="6" v-for="(kid, i) in memmerWithId.childrens" :key="i">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">

                            <span> <span class="detval"> Name: </span> {{ kid.name }} </span>

                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> DOB: </span> {{ kid.dob.split('T')[0] }} </span>

                        </v-card-title>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-3 rounded-lg" v-if="memmerWithId.group" style="font-family: 'Montserrat', sans-serif;">
            <v-card-title class="text-h5">
                Group
            </v-card-title>


            <v-card-text v-if="memmerWithId.group">
                <v-card-title class="pa-1 ">
                    <span> <span class="detval"> Group ID: </span> {{ memmerWithId.groupId }} </span>
                </v-card-title>
                <v-card-title class="pa-1 ">
                    <span> <span class="detval"> Zip: </span> {{ memmerWithId.group.zip }} </span>
                </v-card-title>
                <v-card-title class="pa-1 ">
                    <span> <span class="detval"> Adress: </span> {{ memmerWithId.group.address }} </span>
                </v-card-title>
                <v-card-title class="pa-1 ">
                    <span> <span class="detval"> Building No: </span> {{ memmerWithId.group.houseno }} </span>
                </v-card-title>

                <!-- <v-row>
            
                </v-row> -->
            </v-card-text>
            <v-card-text v-if="memmerWithId.group && memmerWithId.parent">
                <!-- <v-card-title class=""> -->
                <span class="text-h5"> Group Members </span>
                    
                <!-- {{ memmerWithId.group?.groupmembers.length }} -->
                    
                <!-- </v-card-title> -->
                <v-table class="rounded-lg mt-4" style="border: 1px solid #9ebdf5;">
                    <thead>
                        <tr>

                            <th class="text-left">
                                ID
                            </th>
                            <th class="text-left">
                                Name
                            </th>
                            <th class="text-left">
                                Phone
                            </th>
                            <th class="text-left">
                                Type
                            </th>
                            <th class="text-left">
                                Borough
                            </th>
                            <th class="text-left">
                                University
                            </th>

                            <th class="text-left">
                                Diet Type
                            </th>
                            <th class="text-left">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in memmerWithId.group.groupmembers" :key="item.id">
                                <td v-if="item.member" >{{ item.memberId }}</td>
                                <td v-else ></td>
                                <td v-if="item.member" >{{ item.member?.firstname + ' ' + item.member?.lastname }}</td>
                                <td v-else></td>
                                <!-- <td>{{ item.member?.firstname + ' ' + item.member?.lastname }}</td> -->
                                <td>{{ item.member?.phone }}</td>
                                <td>{{ item.member?.membersRole.type }}</td>
                                <td>{{ item.member?.borough }}</td>
                                <td>{{ item.member?.university?.name }}</td>
                                <td>{{ item.member?.dietType.type }}</td>
                                <td>{{ item.member?.status.statustype }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: 'serviceInner',
    computed: {
        ...mapState('member', ['memmerWithId'])
    },
    methods: {
        ...mapActions('member', ['GET_MEMBER_BY_ID']),
        routerBack() {
            this.$router.back()
        }

    },
    mounted() {
        const payload = {
            id: this.$route.params.memberId
        }
        this.GET_MEMBER_BY_ID(payload)
        // .then(() => {
        //     console.log(this.memmerWithId);

        // })
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

td {
    font-weight: 600;
}

.details {
    font-size: large;
    font-weight: 500;
    margin-top: 19px;
    border: 1px solid #bbbfee;
    padding: 23px;
}

.detval {
    font-weight: 600;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

.cap {
    text-transform: capitalize;
}
</style>