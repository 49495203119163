<template>
    <v-toolbar height="40" class="px-0" style="border:1px solid #767373 ;" rounded="lg">

        <v-btn v-if="btn_text" style="background:#042b4c;" rounded="0" color="white" height="inherit"
            @click="addActoin"><span style="text-transform: none; font-family:'Montserrat', sans-serif !important">
                <v-icon>mdi-plus</v-icon>
                {{ btn_text }}
            </span></v-btn>
        <v-text-field bg-color="#f6fbfe" v-model="text" density="compact" class="" hide-details variant="plain"
            :placeholder="placeholder" single-line clearable @keyup.enter="search()">
        </v-text-field>
        <v-btn v-if="filButton" style="background:#fefefe;" rounded="0" color="white" height="inherit"
            @click="filterBtn"><span>
                <v-icon size="large" color="#042b4c">mdi-filter-outline</v-icon>

            </span></v-btn>
        <v-btn style="background:#042b4c;" rounded="0" color="white" height="inherit" @click="search()"><span
                style="text-transform: none; font-family:'Montserrat', sans-serif !important">
                Search
                <v-icon size="large">mdi-magnify</v-icon>
            </span></v-btn>
    </v-toolbar>
</template>

<script>
export default {
    name: 'searchFilteToolbar',
    data() {
        return {
            text: ''
        }
    },
    props: {
        btn_text: {
            type: String
        },
        placeholder: {
            type: String,
            default: ''
        },
        filButton: {
            type: Boolean,
            default: true
        }
        // add_btn:{
        //     type: Boolean,
        //     default: true
        // }
    },
    methods: {
        addActoin() {
            this.$emit('btn_action')
        },
        filterBtn() {
            this.$emit('filterBtn')
        },
        search() {
            this.$emit('search', this.text)
        }
    }
}
</script>

<style >
.v-field--single-line input {
    padding-left: 14px;
}
</style>