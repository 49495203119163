<template>
    <v-container class="px-10" fluid style="height: 100vh; background: #e7f0f7;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            DISTRIBUTION REPORT
        </div>
        <searchAndFilterToolbar :btn_text="serv_text" @btn_action="dialog = true" @filterBtn="openFilter()" />
        <!-- FILTER PART -->
        <Transition name="slide-fade">
            <div class="mt-4 mb-2" v-if="filterDisplay">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-autocomplete v-model="type" hide-details variant="outlined" density="comfortable"
                            :items="memberRole" item-title="type" clearable item-value="id" label="Type">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field color="" hide-details label="Week Number" clearable density="comfortable"
                            v-model="Wno" variant="outlined">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="date d-flex pt-3" style="">
                            <p class="mr-2" style="color:#7d7d86;">Start Date:</p>
                            <input type="date" v-model="stDate" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="date d-flex pt-3" style="">
                            <p class="mr-2" style="color:#7d7d86;">End Date:</p>
                            <input type="date" v-model="enDate" />
                        </div>
                    </v-col>


                    <v-col cols="12" md="2" style="align-self: center;">
                        <div class="d-flex">
                            <v-btn style="background: rgb(4 43 76) ;" @click="filter()"> <span
                                    style="color: white">filter</span> </v-btn>
                            <JsonCSV :data="json_data" name="distributionReport">
                                <v-btn style="background: rgb(4 43 76) ;" class="ml-5"> <span
                                        style="color: white">Export CSV</span> </v-btn>
                            </JsonCSV>
                        </div>
                    </v-col>
                    <!-- <v-col cols="12" md="2" style="align-self: center;">
                        <v-btn style="background: rgb(4 43 76) ;" > <span
                                style="color: white">Export CSV</span> </v-btn>
                    </v-col> -->
                </v-row>
            </div>
        </Transition>
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <th class="text-left">
                        MEMBER ID
                    </th>
                    <th class="text-left">
                        NAME
                    </th>
                    <th class="text-left">
                        TYPE
                    </th>
                    <th class="text-left">
                        EXTRAS
                    </th>
                    <th class="text-left">
                        WEEK NO
                    </th>
                    <!-- <th class="text-left">
                        YEAR
                    </th> -->
                    <th class="text-left">
                        COLLECTION DATE
                    </th>
                    <!-- <th class="text-left">
                        ACTIONS
                    </th> -->


                </tr>
            </thead>
            <tbody>
                <tr v-for="item in destributionLogList" :key="item.name">


                    <td>{{ item.memberId }}</td>
                    <td>{{ item.member.firstname + ' ' + item.member.lastname }}</td>
                    <td>{{ item.member.membersRole.type }}</td>

                    <td>
                        <!-- <span v-if="index != names.length - 1">, </span> -->
                        <span v-if="item.collectionLogs.length != 0" class="item d-flex">
                            <p v-for="(extras, i) in item.collectionLogs" :key="i">
                                {{ extras.extra.extrasname }} <span v-if="i != item.collectionLogs.length - 1">,</span>
                            </p>
                        </span>
                    </td>
                    <td>{{ item.weekNumber }}</td>
                    <!-- <td>{{ item.year }}</td> -->
                    <td>{{ item.createdAt.split('T')[0] }}</td>
                    <!-- <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="toInnerPage(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-eye-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                    </td> -->
                </tr>
            </tbody>
        </v-table>
        <paginationVue :length="destributionLogTotalPage" @chanegePage="chanegePage" />
        <!-- <addDistibutionVue :visible="dialog" @close="dialog = false" @save="addDistribution" :title="serv_text" /> -->
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import paginationVue from '@/components/pagination.vue';
import JsonCSV from 'vue-json-csv'

// import addDistibutionVue from '@/components/distribution/addDistibution.vue';
export default {
    name: 'distribution',
    components: {
        searchAndFilterToolbar,
        paginationVue,
        JsonCSV
        // addDistibutionVue
    },
    data() {
        return {
            // serv_text: 'Add Distribution',
            dialog: false,
            filterDisplay: false,
            type: '',
            Wno: '',
            stDate: '',
            enDate: '',
            json_data: [

            ]

        }
    },
    computed: {
        ...mapState('destributionLog', ['destributionLogList', 'destributionLogTotalPage', 'destributionLogReport', 'destributionLogTotalCount']),
        ...mapState('member', ['memberRole']),

    },
    methods: {
        ...mapActions('member', ['GET_MEMBER_ROLE']),
        ...mapActions('destributionLog', ['GET_DESTRIBUTION_LOG_LIST', 'GET_DESTRIBUTION_LOG_REPORT']),
        ...mapActions('extras', ['GET_EXTRA_LIST']),

        changeStatus(status, item) {


        },
        openFilter() {

            this.filterDisplay = !this.filterDisplay
        },
        chanegePage(item) {
            const query = {
                page: item,
                wn: this.Wno,
                rid: this.type,
                startdte: this.stDate,
                enddte: this.enDate,
                size: 15
            }
            this.GET_DESTRIBUTION_LOG_LIST(query).then(() => {
                this.toCSV()
            })
        },
        filter() {
            const query = {
                size: 15,
                wn: this.Wno,
                rid: this.type,
                startdte: this.stDate,
                enddte: this.enDate
            }
            this.GET_DESTRIBUTION_LOG_LIST(query).then(() => {
                this.toCSV()
            })

        },
        addDistribution(payload) {
            console.log(payload)
        },
        async toCSV() {
            // const test = []
            const query = {
                size: this.destributionLogTotalCount,
                wn: this.Wno,
                rid: this.type,
                startdte: this.stDate,
                enddte: this.enDate
            }
            await this.GET_DESTRIBUTION_LOG_REPORT(query)
            this.json_data = []
            this.destributionLogReport.forEach(el => {
                this.json_data.push({
                    'MEMBER ID': el.memberId,
                    'NAME': el.member.firstname + ' ' + el.member.lastname,
                    'TYPE': el.member.membersRole.type,
                    'WEEK NO': el.member.membersRole.type,
                    'YEAR': el.year,
                    'COLLECTION DATE': el.createdAt.split('T')[0],

                })
            })

            console.log(this.json_data)
        }

    },
    mounted() {
        this.GET_DESTRIBUTION_LOG_LIST().then(() => {
            this.toCSV()
        })
        this.GET_MEMBER_ROLE();
        this.GET_EXTRA_LIST()
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.item+.item:before {
    content: ", ";
}
</style>