<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-bottom-transition" activator="parent">
            <v-card class="mx-auto" width="1000">
                <!-- {{ this.val.dietTypeId }} -->
                <v-window v-model="step">
                    <v-window-item :value="1">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Personal Details
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="2">
                                    <!-- titles -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="val.title" dense clearable :items="Title" item item-title="name"
                                        item-value="id" label="Title">
                                    </v-autocomplete>
                                    <span v-if="v$.titles.$error" style="color: red">
                                        {{ v$.titles.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field color="#9c27b0" hide-details label="First Name" density="comfortable"
                                        v-model="val.firstname" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.firstName.$error" style="color: red">
                                        {{ v$.firstName.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field color="#9c27b0" hide-details label="Last Name" density="comfortable"
                                        v-model="val.lastname" variant="outlined">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field color="#9c27b0" hide-details label="Phone" density="comfortable"
                                        v-model="val.phone" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.phone.$error" style="color: red">
                                        {{ v$.phone.$errors[0].$message }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field color="#9c27b0" hide-details label="Email" density="comfortable"
                                        v-model="val.email" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.email.$error" style="color: red">
                                        {{ v$.email.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete :disabled="isGroup()" @click="roleFlag = true" hide-details
                                        variant="outlined" density="comfortable" v-model="val.membersRoleId" clearable
                                        :items="memberRole" item-title="type" item-value="id" label="Type">
                                    </v-autocomplete>
                                    <span v-if="v$.type.$error" style="color: red">
                                        {{ v$.type.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-autocomplete hide-details :disabled="isGroup()" variant="outlined" density="comfortable"
                                        v-model="val.noofkids" :items="CHnumber"
                                        label="Number Of kids Living with you in UK">
                                    </v-autocomplete>
                                    <span v-if="v$.NoKids.$error" style="color: red">
                                        {{ v$.NoKids.$errors[0].$message }}
                                    </span>
                                </v-col>

                            </v-row>
                            <!-- <v-row v-if="val.noofkids === 'Expected' || val.expected ">
                                <v-col cols="12" md="12">
                                    <div class="date d-flex pl-3" style="">
                                        <p class="mr" style="color:#7d7d86;">Expected Date(Aprox):</p>
                                        <input type="date" v-model="val.expected" />
                                    </div>
                                    <span v-if="v$.ExpectedDate.$error" style="color: red">
                                        {{ v$.ExpectedDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                            </v-row> -->
                            <v-row>

                            </v-row>
                            <v-row class="mt-5">
                                <v-col cols="12" md="6">
                                    <v-autocomplete variant="outlined" :disabled="isGroup()" density="comfortable" hide-details
                                        v-model="heveDependent" clearable :items="isDependent" item-title="title"
                                        item-value="val" label="Does the Member have dependent-living in UK ?">
                                    </v-autocomplete>
                                    <!-- <span v-if="v$.NoKids.$error" style="color: red">
                                         {{ v$.NoKids.$errors[0].$message }}
                                        </span> -->
                                </v-col>
                                <v-col cols="12" md="6" v-if="val.expected || val.noofkids === 'Expected'">
                                    <div class="date d-flex pl-3" style="">
                                        <p class="mr" style="color:#7d7d86;">Expected Date(Aprox):</p>
                                        <input type="date" v-model="ExpectedDate" />
                                    </div>
                                    <span v-if="v$.ExpectedDate.$error" style="color: red">
                                        {{ v$.ExpectedDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                            </v-row>
                            <div v-if="heveDependent">
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field color="#9c27b0" hide-details label="Dependent Email"
                                            density="comfortable" v-model="val.dependentemail" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.DepEmail.$error" style="color: red">
                                            {{ v$.DepEmail.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field color="#9c27b0" hide-details label="Dependent name"
                                            density="comfortable" v-model="val.dependentname" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.DependentName.$error" style="color: red">
                                            {{ v$.DependentName.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field color="#9c27b0" hide-details label="Dependent phone"
                                            density="comfortable" v-model="val.dependentphone" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.DepPhone.$error" style="color: red">
                                            {{ v$.DepPhone.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>

                            <v-row>
                                <v-col cols="12" lg="12">
                                    <v-textarea variant="outlined" label="Note" v-model="val.note" auto-grow outlined
                                        rows="3" row-height="25" shaped>
                                    </v-textarea>
                                </v-col>

                            </v-row>

                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Contact Details
                            <p style="font-size: 17px; color: red;">{{ grp_err_text }}</p>

                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-autocomplete :disabled="isGroup()" clearable hide-details label="Post Code " density="comfortable"
                                        v-model="val.zip" v-model:search="search" @input="clearOthers()"
                                        :items="postCodeList" variant="outlined">
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    Search for
                                                    <strong>Post Code</strong>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <!-- <v-col cols="12" md="3">
                                    <v-text-field color="#9c27b0" hide-details label="Country" density="comfortable"
                                        v-model="val.country" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.country.$error" style="color: red">
                                        {{ v$.country.$errors[0].$message }}
                                    </span>
                                </v-col> -->
                                <span v-if="v$.zib.$error" style="color: red">
                                    {{ v$.zib.$errors[0].$message }}
                                </span>
                                <v-col cols="12" md="4">
                                    <!-- <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="val.boroughId" clearable :items="boroughList" item-title="name"
                                        item-value="id" label="Address">
                                    </v-autocomplete> -->
                                    <v-autocomplete :disabled="isGroup()" label="Address" clearable aria-disabled="" density="comfortable"
                                        v-model="val.road" :items="addressList" item-title="line_1" variant="outlined">
                                    </v-autocomplete>
                                    <span v-if="v$.address.$error" style="color: red">
                                        {{ v$.address.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-autocomplete :disabled="isGroup()" label="Building No" clearable aria-disabled="" density="comfortable"
                                        v-model="val.houseno" :items="buildingList" variant="outlined">
                                    </v-autocomplete>
                                    <!-- <span v-if="v$.country.$error" style="color: red">
                                        {{ v$.country.$errors[0].$message }}
                                    </span> -->
                                    <!-- <v-text-field color="#9c27b0" hide-details label="Zib" density="comfortable"
                                        v-model="val.zip" variant="outlined">
                                    </v-text-field>  -->


                                </v-col>
                                <v-col cols="12" md="6" v-if="borough">
                                    <v-text-field label="Borough" disabled density="compact" :model-value="borough"
                                        variant="underlined">
                                    </v-text-field>
                                    <!-- <span v-if="v$.address.$error" style="color: red">
                                        {{ v$.address.$errors[0].$message }}
                                    </span> -->
                                </v-col>

                            </v-row>
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="3">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Education Details
                        </v-card-title>
                        <v-card-text>
                            <v-row>

                                <v-col cols="12" md="4">
                                    <v-text-field color="#9c27b0" hide-details label="Student ID" density="comfortable"
                                        v-model="val.studentid" variant="outlined">
                                    </v-text-field>
                                    <!-- <span v-if="v$.studentId.$error" style="color: red">
                                        {{ v$.studentId.$errors[0].$message }}
                                    </span> -->
                                </v-col>

                                <v-col cols="12" md="3">
                                    <!-- UNIVERSITY -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="val.universityId" clearable :items="universityLis" item-title="name"
                                        item-value="id" label="University">
                                    </v-autocomplete>
                                    <!-- <span v-if="v$.university.$error" style="color: red">
                                        {{ v$.university.$errors[0].$message }}
                                    </span> -->
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="date d-flex pt-3" style="">
                                        <p class="mr-2" style="color:#7d7d86;">Arrived date:</p>
                                        <input type="date" style="width:18px" v-model="val.arrivedin" />
                                        <p class="mr" style="color:#7d7d86;">{{ val.arrivedin?.split('T')[0] }}</p>

                                        <!-- <input type="date" v-model="ArrivedDate" /> -->
                                    </div>
                                    <!-- {{ this.ArrivedDate }} -->
                                    <span v-if="v$.ArrivedDate.$error" style="color: red">
                                        {{ v$.ArrivedDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="date d-flex" style="">
                                        <p class="mr-2 " style="color:#7d7d86;">Course Start Date:</p>
                                        <input type="date" style="width:18px" v-model="val.coursestart" />
                                        <p class="mr" style="color:#7d7d86;">{{ val.coursestart?.split('T')[0] }}</p>

                                        <!-- <input type="date" v-model="startDate" /> -->
                                    </div>
                                    <span v-if="v$.startDate.$error" style="color: red">
                                        {{ v$.startDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="date d-flex" style="">


                                        <p class="mr-2" style="color:#7d7d86;">Course End Date:</p>
                                        <input type="date" style="width:18px" v-model="val.courseend" />
                                        <p class="mr" style="color:#7d7d86;">{{ val.courseend?.split('T')[0] }}</p>
                                    </div>
                                    <span v-if="v$.EndDate.$error" style="color: red">
                                        {{ v$.EndDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="4">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Food Details
                        </v-card-title>
                        <v-card-text>
                            <v-row>

                                <v-col cols="12" md="3">
                                    <!--  Food Details -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="val.dietTypeId" clearable :items="dietReq" item-title="name"
                                        item-value="id" label="Dietary Requirements">
                                    </v-autocomplete>
                                    {{ deitryRequirment }}
                                    <span v-if="v$.deitryRequirment.$error" style="color: red">
                                        {{ v$.deitryRequirment.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="3">
                                    <!--  Food Details -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="val.eggs" clearable :items="eggList" item-title="name" item-value="id"
                                        label="Do you eat EGGS?">
                                    </v-autocomplete>
                                    <span v-if="v$.eggs.$error" style="color: red">
                                        {{ v$.eggs.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="6">
                                    <!--  Food Details -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="val.preferredcollectionday" clearable :items="prefCollection"
                                        label="Preferred Collection Day">
                                    </v-autocomplete>
                                    <span v-if="v$.collectionDay.$error" style="color: red">
                                        {{ v$.collectionDay.$errors[0].$message }}
                                    </span>

                                </v-col>

                            </v-row>
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="5">
                        <v-card-title class="text-h5  justify-space-between text-center">
                            Kid's Details
                        </v-card-title>
                        <v-card-text>
                            <v-row class="mb-3" v-for="(hh, i) in child" :key="hh">
                                <v-col cols="12" md="6" sm="6">
                                    <!-- <p>{{hh}}</p> -->
                                    <v-text-field :label="'Child Name' + (i + 1)" density="compact" hide-details=""
                                        v-model="hh.name" variant="underlined">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" sm="6" style="align-self: center">
                                    <div class="date d-flex" style="">
                                        <p class="mr" style="color:#7d7d86;">DOB:</p>
                                        <input type="date" style="width:18px" v-model="hh.dob" />
                                        <p class="mr" style="color:#7d7d86;">{{ hh.dob.split('T')[0] }}</p>

                                    </div>
                                </v-col>
                            </v-row>
                            <span v-if="errValue" style="color: red">
                                {{ errValue }}
                            </span>
                        </v-card-text>

                    </v-window-item>


                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                    <p v-if="isGroup()" class="ml-2" style="color: #f18205"> Some fields cannot be updated(Member belongs to a group) </p>
                    <v-spacer></v-spacer>
                    <v-btn v-if="step > 1" text @click="goBack()">
                        Back
                    </v-btn>
                    <v-btn v-if="step == 5" color="primary" depressed @click="nextwindowOnStep5()">
                        next
                    </v-btn>
                    <v-btn v-if="step < 4" color="primary" depressed @click="nextwindow()">
                        Next
                    </v-btn>
                    <v-btn v-if="step == 4" color="primary" depressed @click="submitMemeber()">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <dialogVue :visible="dialogBox" @close="dialogBox = false" @yes="dialogBox = false, step++" @no="noGroup" />
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import useVuelidate from "@vuelidate/core";
import { required, numeric, decimal, maxLength, email } from "@vuelidate/validators";
import dialogVue from '@/components/dialog.vue';
import moment from 'moment';
import axios from 'axios'

export default {
    name: 'addmemeberVue',
    data() {
        return {
            v$: useVuelidate(),
            errValue: '',
            grp_err_text: '',
            dialogBox: false,

            // flags
            roleFlag: false,

            DepEmail: '',
            DepPhone: '',
            DepId: '',

            step: 1,
            titles: '',
            firstName: '',
            secondName: '',
            phone: '',
            email: '',
            type: '',
            DependentName: '',
            NoKids: '',
            ExpectedDate: '',
            area: '',
            country: '',
            borough: '',
            address: '',
            zib: '',
            studentId: '',
            university: '',
            dialog: false,
            ArrivedDate: '',
            startDate: '',
            EndDate: '',
            deitryRequirment: '',
            eggs: '',
            collectionDay: '',
            Title: ['Mr', 'Mrs', 'Miss', 'Ms',],
            CHnumber: ['None', '1', '2', '3', '4', '5', 'Expected'],
            areas: [
                {
                    id: false,
                    name: 'city',
                },
                {
                    id: true,
                    name: 'Town',
                }
            ],
            dietReq: [
                {
                    id: 1,
                    name: 'Halal'
                },
                {
                    id: 2,
                    name: 'Non-Halal'
                },
                {
                    id: 3,
                    name: 'Vegiterian'
                },
            ],
            eggList: [
                {
                    id: true,
                    name: 'Yes'
                },
                {
                    id: false,
                    name: 'No'
                },
            ],
            child: [],
            prefCollection: ['TUESDAY AFTERNOON (STRICTLY Ladies with Children ONLY', 'FRIDAY AFTERNOON (STRICTLY Ladies with Children ONLY)', 'SATURDAY EVENING', 'TUESDAY EVENING'],
            search: '',
            buildingList: [],
            formAddress: [],
            heveDependent: null,
            isDependent: [
                {
                    val: true,
                    title: 'Yes'
                },
                {
                    val: false,
                    title: 'No'
                }
            ],
            memberRole: [
                {
                    id: 1,
                    type: 'Student'
                },
                // {
                //     id: 3,
                //     type: 'Dependent'
                // },
                {
                    id: 3,
                    type: 'family'
                },
                {
                    id: 4,
                    type: 'PSW'
                },
                {
                    id: 5,
                    type: 'TIER2'
                },
                {
                    id: 6,
                    type: 'Undocumented'
                },
                {
                    id: 7,
                    type: 'Benefits'
                },
            ],
            // tempKidsNo: ''

        }
    },
    components: {
        dialogVue
    },

    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        },
        val: {
            type: Array
        },
        tempKidsNo: {
            type: String
        },
        tempValue: {
            type: Array
        }
        // tempZib:{
        //     type: String
        // },
        // tempRoad:{
        //     type: String
        // },
        // tempHouser:{
        //     type: String
        // }

    },
    validations() {
        return {
            titles: { required },
            firstName: { required },
            // secondName: { required },
            phone: { required, numeric, maxLength: maxLength(12) },
            email: { required, email },
            type: { required },
            zib: { required },
            // DependentName: { required },
            NoKids: { required },
            area: { required },
            country: { required },
            borough: { required },
            address: { required },

            ArrivedDate: { required },
            startDate: { required },
            EndDate: { required },

            deitryRequirment: { required },
            eggs: { required },
            collectionDay: { required },

            DepEmail: { required, email },
            DepPhone: { required, numeric, maxLength: maxLength(12) },
            DependentName: { required },

            ExpectedDate: { required },

        }
    },
    watch: {
        // 'val.arrivedin'(newVl, oldVl) {
        //     console.log(newVl)
        //     this.ArrivedDate = newVl.split('T')[0]
        // },
        // deep: true,
        // 'val.coursestart'(newVl, oldVl) {
        //     // console.log(newVl)
        //     this.startDate = newVl.split('T')[0]
        // },
        // deep: true,
        // 'val.courseend'(newVl, oldVl) {
        //     // console.log(newVl)
        //     this.EndDate = newVl.split('T')[0]
        // },
        // deep: true,
        'val.expected'(newVl, oldVl) {
            // console.log(newVl)
            if (newVl) {
                this.ExpectedDate = newVl.split('T')[0]

            }
        },
        deep: true,
        'val.zip'(val) {
            // this.val.road = ''
            this.GET_ADDRESS_LSIT(val)
            this.borough = this.boroughFromAddress
            // console.log(val)
        },
        deep: true,
        'val.road'(val) {
            const add = this.$store.getters["form/getAddresByline_1"](val);
            // console.log(add)
            this.formAddress = add[0]
            // console.log(this.formAddress)
            this.buildingList = [];
            // this.val.houseno = ''
            add.forEach(el => {
                this.buildingList.push(el.building_number)
                // console.log(this.buildingList)
            })
        },
        deep: true,
        'val.membersRoleId'(dat) {
            if (this.roleFlag == true && dat) {
                const memType = this.memberRole.find(o => o.id == dat)
                this.val.membertype = memType.type
                // console.log(memType.type)
            }
        },
        deep: true,
        search(val) {
            // this.val.road = '';
            // this.val.houseno = '';
            this.GET_POST_CODE_LIST(val);

        },
        deep: true,
        visible(newVl, oldVl) {
            if (newVl == false) {
                this.step = 1;
                this.roleFlag = false
                this.v$.$reset();
            }
            if (newVl == true) {
                if (this.val.dependentname || this.val.dependentemail || this.val.dependentphonethis) {
                    this.heveDependent = true
                } else {
                    this.heveDependent = false
                }
            }
        },
        deep: true,
        heveDependent(newVl) {
            if (!newVl) {
                this.v$.DepEmail.$reset();
                this.v$.DepPhone.$reset();
                this.v$.DependentName.$reset();

            }
        },
        show(val) {
            if (val === true) {
                if (this.val.membersRoleId == 2 && this.val.membertype === "PSW") {
                    this.val.membersRoleId = 4
                }
                else if (this.val.membersRoleId == 2 && this.val.membertype === "TIER2") {
                    this.val.membersRoleId = 5
                }
                else if (this.val.membersRoleId == 2 && this.val.membertype === "Undocumented") {
                    this.val.membersRoleId = 6
                }
                else if (this.val.membersRoleId == 2 && this.val.membertype === "Benefits") {
                    this.val.membersRoleId = 7
                }
            }
        }


    },
    computed: {
        // ...mapState('member', ['memberRole']),
        ...mapState('borough', ['boroughList']),
        ...mapState('university', ['universityLis']),
        ...mapState('form', ['postCodeList', 'addressList', 'boroughFromAddress']),
        // ...mapState('priority', ['priorityList']),
        // ...mapState('workingCondition', ['conditionList']),
        // ...mapState('city', ['cityList']),
        // ...mapState('technician', ['techList']),
        // ...mapState('clients', ['clientList', 'editClientValue', 'editClientValue']),
        // computed: {
        //     currentTitle() {
        //         switch (this.step) {
        //             case 1: return 'Sign-up'
        //             case 2: return 'Create a password'
        //             default: return 'Account created'
        //         }
        //     },
        // },
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },

    methods: {
        ...mapActions('form', ['GET_POST_CODE_LIST', 'GET_ADDRESS_LSIT']),
        isGroup() {
            if (this.val.groupId) {
                return true

            }else{
                return false
            }
        },
        async nextwindow() {
            try {
                // console.log(this.v$)
                if (this.step == 1) {
                    this.titles = this.val.title
                    this.firstName = this.val.firstname
                    this.phone = this.val.phone
                    this.email = this.val.email
                    this.type = this.val.membersRoleId

                    this.v$.titles.$validate();
                    this.v$.firstName.$validate();
                    this.v$.phone.$validate();
                    this.v$.email.$validate();
                    this.v$.type.$validate();
                    //  this.v$.$validate();
                    if (this.heveDependent) {
                        this.DepEmail = this.val.dependentemail
                        this.DepPhone = this.val.dependentphone
                        this.DependentName = this.val.dependentname
                        // this.v$.DependentName.$validate();
                        this.v$.DepEmail.$validate();
                        this.v$.DepPhone.$validate();
                        this.v$.DependentName.$validate();
                    }
                    if (this.val.noofkids === 'Expected') {
                        this.v$.ExpectedDate.$validate();
                    }
                    if (this.v$.$errors.length == 0) {
                        this.child = [];
                        // console.log(this.val.noofkids)
                        if (this.val.noofkids === 'None' || this.val.noofkids === 'Expected') {
                            return this.step++
                        }
                        if (this.tempKidsNo != this.val.noofkids && !isNaN(this.val.noofkids)) {
                            // for (let i = 0; i < parseInt(this.val.noofkids); i++) {
                            //     const obj = {
                            //         name: '',
                            //         dob: ''
                            //     }
                            //     this.child.push(obj);
                            // }
                            // this.step = 5
                            if (this.tempKidsNo < this.val.noofkids && !isNaN(this.val.noofkids)) {
                                console.log(this.tempKidsNo)
                                this.child = this.val.childrens
                                const tNoOFChild = this.val.noofkids - this.tempKidsNo;
                                // console.log(tNoOFChild)
                                for (let i = 0; i < parseInt(tNoOFChild); i++) {
                                    const obj = {
                                        name: '',
                                        dob: ''
                                    }
                                    this.child.push(obj);
                                }
                                this.step = 5
                                // this.tempKidsNo = this.val.noofkids
                                this.$emit('changTempKidsNO', this.val.noofkids)
                            } else {
                                for (let i = 0; i < parseInt(this.val.noofkids); i++) {
                                    const obj = {
                                        name: '',
                                        dob: ''
                                    }
                                    this.child.push(obj);
                                }
                                this.step = 5
                            }
                        } else if (this.val.childrens.length > 0) {
                            this.child = this.val.childrens
                            return this.step = 5
                        } else {
                            this.step++
                        }

                        // if (this.val.noofkids && !isNaN(this.val.noofkids)) {
                        //     // this.step = 5
                        //     this.NoKids = this.val.noofkids;
                        //     // console.log(this.NoKids)
                        //     this.v$.NoKids.$validate();
                        //     this.child = []
                        //     if (this.v$.$errors.length == 0) {
                        //         for (let i = 0; i < parseInt(this.NoKids); i++) {
                        //             const obj = {
                        //                 name: '',
                        //                 dob: ''
                        //             }
                        //             this.child.push(obj);

                        //         }
                        //         console.log(this.child)

                        //         this.step = 5
                        //     }
                        // } else {
                        //     this.step++

                        // }
                    }


                } else if (this.step == 2) {
                    // this.area = this.val.area
                    // console.log(this.tempValue)
                    this.country = this.val.houseno
                    // this.borough = this.val.boroughId
                    this.address = this.val.road
                    this.zib = this.val.zip

                    // this.v$.area.$validate();
                    // this.v$.country.$validate();
                    // this.v$.borough.$validate();
                    this.v$.address.$validate();
                    this.v$.zib.$validate();

                    if (this.v$.$errors.length == 0) {
                        if (this.val.membersRoleId != 1) {
                            this.val.studentid = null;
                            this.val.universityId = null;
                            this.val.arrivedin = null;
                            this.val.coursestart = null;
                            this.val.courseend = null;
                            return this.step = 4
                        }
                        if (this.val.membersRoleId != 1 || this.ExpectedDate || !isNaN(this.val.noofkids)) return this.step++
                        if (this.tempValue.tempZip != this.val.zip && this.tempValue.tempRoad != this.val.road || this.tempValue.tempHouseNo != this.val.houseno) {
                            // console.log('helloo')
                            const groupCheck = await axios({
                                method: 'get',
                                url: `${process.env.VUE_APP_BASE_URL}members/checksame_address`,
                                params: {
                                    houseno: this.val.houseno,
                                    road: this.val.road,
                                    zip: this.val.zip,
                                }
                            })
                            // console.log(groupCheck.data)
                            if (groupCheck.data.response === 'failed') {
                                this.step++
                            } else {
                                this.dialogBox = true
                            }
                        } else {
                            this.grp_err_text = '';
                            this.step++;
                        }
                    }
                    //  this.step++
                } else if (this.step == 3) {
                    // this.ArrivedDate = this.val.arrivedin;
                    this.ArrivedDate = this.val.arrivedin;
                    this.startDate = this.val.coursestart;
                    this.EndDate = this.val.courseend;
                    this.v$.ArrivedDate.$validate();
                    this.v$.startDate.$validate();
                    this.v$.EndDate.$validate();


                    if (this.v$.$errors.length == 0) {
                        this.step++
                    }


                } else if (this.step == 5) {

                    for (let i = 0; i < this.child.length; i++) {
                        // console.log(this.child[i])
                        if (this.child[i].name == '' || this.child[i].dob == '') {
                            // console.log('please Add All values')
                            return this.errValue = 'please Add All values';

                        }
                        this.errValue = ''
                        this.step = 2
                        this.Borough = this.boroughList;
                        this.univer = this.universityLis;

                    }
                }
            } catch (error) {
                console.log(error)
            }

        },
        nextwindowOnStep5() {
            for (let i = 0; i < this.child.length; i++) {
                if (this.child[i].name == '' || this.child[i].dob == '') {
                    // console.log('please Add All values')
                    return this.errValue = 'please Add All values';

                }

            }
            this.errValue = ''
            this.step = 2
        },
        clearOthers() {
            this.val.road = '';
            this.val.houseno = '';
        },
        submitMemeber() {

            this.deitryRequirment = this.val.dietTypeId
            this.eggs = this.val.eggs
            this.collectionDay = this.val.preferredcollectionday

            // this.secondName = this.val.lastname
            // this.startDate = this.val.coursestart
            // this.EndDate = this.val.courseend
            this.DependentName = this.val.dependentname
            this.studentId = this.val.studentid
            this.university = this.val.universityId
            this.NoKids = this.val.noofkids

            this.v$.deitryRequirment.$validate();
            this.v$.eggs.$validate();
            this.v$.collectionDay.$validate();
            if (this.v$.$errors.length == 0) {
                const payload = {
                    id: this.val.id,
                    // statusId: 1,
                    title: this.titles,
                    firstname: this.firstName,
                    lastname: this.val.lastname,
                    phone: this.phone,
                    email: this.email,
                    membersroleId: this.type,
                    dependentname: this.DependentName,
                    dependentphone: this.DepPhone,
                    dependentemail: this.DepEmail,
                    noofkids: this.val.noofkids,
                    area: 1,
                    country: this.country,
                    // boroughId: this.borough,
                    borough: this.borough,
                    // address: this.formAddress.formatted_address.join(),
                    address: this.formAddress == undefined ? this.val.address : this.formAddress.formatted_address?.join(),
                    zip: this.zib,
                    studentid: this.studentId,
                    universityId: this.university,
                    arrivedin: this.ArrivedDate,
                    coursestart: this.startDate,
                    courseend: this.EndDate,
                    diettype: 1,
                    dietTypeId: this.deitryRequirment,
                    
                    eggs: this.eggs,
                    preferredcollectionday: this.collectionDay,
                    note: this.val.note,
                    membertype: this.val.membertype

                }
                if (this.val.noofkids === 'None') {
                    payload.membersroleId = 1
                }
                if (this.type > 3) {
                    payload.membersroleId = 2

                }
                if (this.val.noofkids && !isNaN(this.val.noofkids)) {
                    payload.children = this.child
                    payload.membersroleId = 3;
                    if (this.val.membersRoleId != 1) {
                        payload.membersroleId = 2;
                    }
                }

                if (this.val.noofkids == 'Expected') {
                    payload.noofkids = null;
                    payload.expected = this.ExpectedDate;
                    payload.membersroleId = 3;
                    // payload.children = []
                }
                // return console.log(payload);
                this.$emit('update_memeber', payload);
                this.step = 1;
                // console.log(payload)

            }
        },
        goBack() {
            if (this.step == 5) {
                this.step = 1
            }
            else if (this.step == 4 && this.val.membersRoleId != 1) {
                this.step = 2
            } else {
                this.step--
            }
        },
        noGroup() {
            this.grp_err_text = "Please Add Another Address";
            this.dialogBox = false;
            this.val.houseno = this.tempValue.tempHouseNo
            this.val.road = this.tempValue.tempRoad
            this.val.zip = this.tempValue.tempZip

            // this.v$.country.$reset();
            // this.v$.address.$reset();
            // this.v$.zib.$reset();
            // this.country = '';
            // this.zib = '';
            // this.address = '';
        },


    },
    created() {

    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
}
</style>