<template>
    <v-container class="px-10" fluid style="height:100vh; background:#e7f0f7 ;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            ADMIN
        </div>
        <searchAndFilterToolbar :btn_text="cl_text" @btn_action="dialog = true" />
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <th class="text-left">
                        USERNAME
                    </th>
                    <th class="text-left">
                        NAME
                    </th>
                    <th class="text-left">
                        ACTIONS
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in adminList" :key="item.id">

                    <td>{{ item.username }}</td>
                    <td>{{ item.fullname }}</td>
                    <!-- <td>{{ item.phone }}</td> -->
                    <!-- <td>{{ item.city }}</td>
                    <td>{{ item.careOff }}</td>
                    <td>{{ item.type }}</td> -->
                    <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="OpenEdit(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <!-- <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover> -->
                    </td>
                </tr>
            </tbody>
        </v-table>
        <addAdminVue :visible="dialog" @close="dialog = false" @save="addCategory" :title="dialog_title" />
        <EditAdmin :visible="edit_dialog" @close="edit_dialog = false" @save="updateSubAdmin" :title="edit_dialog_title"
            :item="editVal" />
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import EditAdmin from '@/components/admin/editAdmin.vue'
// import addClients from '@/components/addClients.vue';
// import addAdminVue from '@/components/add.vue';

export default {
    name: 'userPage',
    components: {
        searchAndFilterToolbar,
        // addAdminVue
        EditAdmin
    },
    data() {
        return {
            cl_text: '',
            admins: [
                {
                    id: 1,
                    name: 'admin',
                    email: 'admin@gmail.com'
                }
            ],
            edit_dialog: false,
            edit_dialog_title: 'Edit Admin',
            editVal: {}


        }
    },
    computed: {
        ...mapState('admin', ['adminList', 'adminWithID'])
    },
    methods: {
        ...mapActions('admin', ['GET_ALL_ADMIN', 'GET_ADMIN_WITH_ID']),
        OpenEdit(val) {
            this.GET_ADMIN_WITH_ID(val.id).then(()=>{
                this.editVal = this.adminWithID
                this.edit_dialog = true
            })
        }
    },
    mounted() {
        this.GET_ALL_ADMIN()
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.v-table .v-table__wrapper>table>tbody>tr td {
    border-bottom: none;
    font-size: small;
    font-weight: 500;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    border-bottom: none;
}

.v-table {
    font-family: Montserrat, sans-serif !important;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    font-weight: 600 !important;
}
</style>