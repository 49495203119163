import axios from 'axios'
export default {
    namespaced: true,
    state: {
        memberList: {},
        memberRoportList: {},
        memberTotalPage: '',
        memberTotalCount: '',
        memberRole: {},
        memmerWithId: {},
        memberEx: null,
        duplicateCheck: {}
        //  editServicetValue:{}
    },
    mutations: {
        SET_MEMBER_LIST(state, data) {
            state.memberList = data
        },
        SET_MEMBER_REPORT(state, data) {
            state.memberRoportList = data
        },
        SET_MEMBER_ROLE(state, data) {
            state.memberRole = data
        },
        SET_MEMBER_TOTAL_PAGE(state, data) {
            state.memberTotalPage = data.totalPages;
            state.memberTotalCount = data.totalCount;
        },
        SET_MEMBER_WITH_ID(state, data) {
            state.memmerWithId = data
        },
        SET_MEMBER_EXIST(state, data) {
            state.memberEx = data
        },
        SET_DUPLICATE_PHONE_EMAIL(state, data) {
            state.duplicateCheck = data
        }

    },
    actions: {
        async GET_MEMBER_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}members/list_of_members`,
                params: {
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if (query) {
                options.params = query
            }
            await axios(options).then((data) => {
                commit('SET_MEMBER_LIST', data.data.data);
                //  console.log(data)
                commit('SET_MEMBER_TOTAL_PAGE', data.data);
            }).catch(err => {
                // console.log(err)
                if (err.response.data.message == 'Unauthorized') {
                    localStorage.clear();
                    return this.$router.push({ name: 'signIn' })
                }
                this.$router.push({ name: 'signIn' })
            })

        },
        async GET_MEMBER_REPORT({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}members/list_of_members`,
                params: {
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if (query) {
                options.params = query
            }
            await axios(options).then((data) => {
                commit('SET_MEMBER_REPORT', data.data.data);
                //  console.log(data.data.data)
                // commit('SET_MEMBER_TOTAL_PAGE', data.data);
            }).catch(err => {
                console.log(err)
                if (err.response.data.message == 'Unauthorized') {
                    localStorage.clear();
                    return this.$router.push({ name: 'signIn' })
                }
                this.$router.push({ name: 'signIn' })
            })

        },
        async GET_MEMBER_ROLE({ commit }) {
            const auth = JSON.parse(localStorage.getItem('user'));

            await axios({
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}members/memberroles`,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }

            }).then((result) => {

                commit('SET_MEMBER_ROLE', result.data.data)
            }).catch(er => {
                console.log(er)
            })
        },
        async ADD_MEMBER({ commit }, payload) {
            try {

                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const add_member = await axios({
                    method: 'post',
                    url: `${process.env.VUE_APP_BASE_URL}members/add_member`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }

                })
                //  console.log(add_member);

            } catch (error) {
                console.log(error);
            }


        },

        async DELETE_MEMBER({ commit }, payload) {
            // console.log(payload)
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                const deleteMemeber = await axios({
                    method: 'get',
                    url: `${process.env.VUE_APP_BASE_URL}members/delete_member/${payload.id}`,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
                })
                // console.log(deleteMemeber)

            } catch (error) {
                console.log(error)
            }
        },

        async UPDATE_MEMBER({ commit }, payload) {
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method: 'post',
                    url: `${process.env.VUE_APP_BASE_URL}members/update_member`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
                })
                 console.log(update)

            } catch (error) {
                console.log(error)
            }
        },
        async GET_MEMBER_BY_ID({ commit, state }, payload) {
            try {
                // console.log(payload)
                const auth = JSON.parse(localStorage.getItem('user'));
                const memeberById = await axios({
                    method: 'get',
                    url: `${process.env.VUE_APP_BASE_URL}members/memberdetailsby_id/${payload.id}`,
                    // params: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }

                })
                // console.log(memeberById)

                commit('SET_MEMBER_WITH_ID', memeberById.data.data);
                // console.log(state.memmerWithId)

            } catch (error) {

            }
        },
        // async CHECK_MEMBER_EXIST({ commit }, query){
        //     // const auth = JSON.parse(localStorage.getItem('user'));
        //     const memberEx = await axios({
        //         method: 'get',
        //         url: `${process.env.VUE_APP_BASE_URL}members/memberby_id`,
        //         params: query
        //         // headers: {
        //         //     Authorization: 'Bearer ' + auth.access_token
        //         // }
        //     }) 
        //     console.log(memberEx);
        //     commit('SET_MEMBER_EXIST', memberEx.data.data);
        // },
        // async CHECK_MEMBER_EXIST({ commit }, query){
        //     // const auth = JSON.parse(localStorage.getItem('user'));
        //     const memberEx = await axios({
        //         method: 'get',
        //         url: `${process.env.VUE_APP_BASE_URL}members/memberby_id`,
        //         params: query
        //         // headers: {
        //         //     Authorization: 'Bearer ' + auth.access_token
        //         // }
        //     }) 
        //     console.log(memberEx);
        //     commit('SET_MEMBER_EXIST', memberEx.data.data);
        // },
        async CHECK_MEMBER_EXIST({ commit }, query) {
            // const auth = JSON.parse(localStorage.getItem('user'));
            const memberEx = await axios({
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}members/memberby_id`,
                params: query
                // headers: {
                //     Authorization: 'Bearer ' + auth.access_token
                // }
            })
            // console.log(memberEx);
            commit('SET_MEMBER_EXIST', memberEx.data.data);
        },
        async UPDATE_DEPENTENT({ commit }, payload) {
            const update = await axios({
                method: 'post',
                url: `${process.env.VUE_APP_BASE_URL}members/update_dependent`,
                data: payload

            })
            // console.log(update)
        },
        async CHECK_DUPLICATE_PHONE_EMAIL({ commit, state }, payload) {
            // const auth = JSON.parse(localStorage.getItem('user'));

            try {
                const emailcheck = await axios({
                    method: 'get',
                    url: `${process.env.VUE_APP_BASE_URL}members/emailnocheck`,
                    params: payload
                    // headers: {
                    //     Authorization: 'Bearer ' + auth.access_token
                    // }
                })
                commit('SET_DUPLICATE_PHONE_EMAIL', emailcheck.data);
                // console.log(state.duplicateCheck)
            } catch (error) {
                // console.log(error)
                commit('SET_DUPLICATE_PHONE_EMAIL', error.response.data);
                // console.log(state.duplicateCheck)
            }


        }

    },
    getters: {
        getClientById: (state) => (id) => {
            return state.clientList.find(list => list.id === id)
        }

    }

}