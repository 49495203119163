
<template>

    <v-container fluid="" class=""
        style="text-align: -webkit-center;  height: 100vh;background:linear-gradient(120deg, #2980b6, rgb(0 44 75))">
        <v-form ref="form" class="pa-4 rounded" v-model="valid" lazy-validation
            style="max-width: 500px;background: #fff;margin-top: 10%;">
            <!-- <h3 class="my-10">
                AL MOHANAD MEDICAL CO.
            </h3> -->
            <div>
                <v-img :src="logo"  style="height: 164px"></v-img> 
            </div>
            <v-text-field variant="outlined" class="mb-4" v-model="username" hide-details label="username">
            </v-text-field>

            <v-text-field variant="outlined" v-model="password" label="password"
                :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
                @click:append-inner="show1 = !show1" @keyup.enter="login()"></v-text-field>
            <p class="mt-3" style="color: red" v-if="error"> {{ error }} </p>

            <v-btn color="#f28103" class="mr-4 my-4" width="30%" @click="login()">
                login
            </v-btn>
        </v-form>
    </v-container>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'
export default {

    name: 'Login',
    data() {
        return {
            password: '',
            username: '',
            error: null,
            show1: false,
            logo: require('../assets/newham.jpeg')
        }
    },
    methods: {
        ...mapActions('user', ['USER_LOGIN']),
        login() {
            const payload = {
                username: this.username,
                password: this.password,

            }
            this.error = null
            this.USER_LOGIN(payload)
            .then(() => {
                this.$router.push({ name: 'dash' })
            }).catch(err => {
                console.log(err)
                this.error = err.response.data.message
             
            })

        }
    }
}
</script>

<style scoped>
p {
    line-height: 1rem;
}

.card {
    padding: 20px;
}

/* .form-group {
   input {
      margin-bottom: 20px;
   }
} */

/* .login-page {
   align-items: center;
   display: flex;
   height: 100vh;

   .wallpaper-login {
      background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
         no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
   }
   
   .fade-enter-active,
   .fade-leave-active {
  transition: opacity .5s;
}
   .fade-enter,
   .fade-leave-to {
      opacity: 0;
   }
   
   .wallpaper-register {
      background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
        no-repeat center center;
      background-size: cover;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
   }

   h1 {
      margin-bottom: 1.5rem;
   }
} */

.error {
    animation-name: errorShake;
    animation-duration: 0.3s;
}

@keyframes errorShake {
    0% {
        transform: translateX(-25px);
    }

    25% {
        transform: translateX(25px);
    }

    50% {
        transform: translateX(-25px);
    }

    75% {
        transform: translateX(25px);
    }

    100% {
        transform: translateX(0);
    }
}
</style>