<template>
    <v-container class="px-10" fluid style="height:100vh; background:#e7f0f7 ;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            EVENT
        </div>
        <searchAndFilterToolbar :btn_text="cl_text" @btn_action="dialog = true" />
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <!-- <th class="text-left">
                        id
                    </th> -->
                    <th class="text-left">
                        NAME
                    </th>
                    <th class="text-left">
                        STATUS
                    </th>
                    <!-- <th class="text-left">
                        TO DATE
                    </th> -->
                    <th class="text-left">
                        ACTOINS
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item) in eventList" :key="item.id">
                    <!-- <td>{{ item.id}}</td> -->
                    <td>{{ item.eventname }}</td>
                    <td>
                        <v-chip color="green" v-if="item.statusId == 1"
                            style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
                                    item.status.statustype
                            }}</span>
                            <v-menu activator="parent">
                                <v-list class="pa-0">
                                    <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                        :class="{ active: it.id == 1, inactive: it.id == 2 }" :key="it">
                                        <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-chip>
                        <v-chip color="#fd5e00" v-else-if="item.statusId == 2"
                            style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
                                    item.status.statustype
                            }}</span>
                            <v-menu activator="parent">
                                <!-- :class="{ Open: it.name === 'Active', Accepted: it.name === 'In-Active' }" -->
                                <v-list class="pa-0">
                                    <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                        :class="{ active: it.id == 1, inactive: it.id == 2 }" :key="it">
                                        <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                    </v-list-item>

                                </v-list>
                            </v-menu>
                        </v-chip>
                    </td>
                    <!-- <td>{{ item.to }}</td> -->
                    <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <paginationVue :length="eventTotalPage" @chanegePage="chanegePage" />

        <addEventsVue :visible="dialog" @close="dialog = false" @save="addEvents" :title="dialog_title" />
        <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false" :item="deleteValue"
            @delete="deleteEvent" />
        <editEventVue :visible="edit_dialog" @close="edit_dialog = false" @save="editEvent" :title="edit_dialog_title"
            :name="edit_value.eventname" :date="edit_value.date" />

    </v-container>
</template>

<script>
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import { mapActions, mapState } from 'vuex'
import deleteWarnVue from '@/components/deleteWarn.vue';
import addEventsVue from '@/components/events/addEvents.vue';
import editEventVue from '@/components/events/editEvents.vue';
import paginationVue from '@/components/pagination.vue';
// import editCity from '@/components/city/editCity.vue';

export default {
    name: 'eventVue',
    components: {
        searchAndFilterToolbar,
        addEventsVue,
        deleteWarnVue,
        editEventVue,
        paginationVue
    },
    data() {
        return {
            edit_dialog: false,
            edit_value: '',
            edit_id: '',
            edit_dialog_title: 'Edit Event',
            delete_dialog: false,
            cl_text: 'Add Event',
            dialog: false,
            deleteValue: {},
            dialog_title: 'Add Event',
            statuses: [
                {
                    id: 1,
                    name: 'Active'
                },
                {
                    id: 2,
                    name: 'In-Active'
                },
            ],

        }
    },
    computed: {
        ...mapState('events', ['eventList', 'eventTotalPage']),
    },
    methods: {
        ...mapActions('events', ['ADD_EVENTS', 'GET_EVENT_LIST', 'DELETE_EVENT', 'UPDATE_EVENT']),

        async addEvents(item) {
            await this.ADD_EVENTS(item);
            this.dialog = false;
            await this.GET_EVENT_LIST();
            // console.log(item)
        },
        openDeleteDialog(item) {
            this.deleteValue = item;

            this.delete_dialog = true;
        },
        async deleteEvent(item) {
            await this.DELETE_EVENT(item);
            this.delete_dialog = false;
            this.GET_EVENT_LIST()
        },
        openEditDialog(item) {
            this.edit_value = item;
            this.edit_id = item.id
            this.edit_dialog = true;
        },
        async editEvent(item) {
            item.id = this.edit_id
            await this.UPDATE_EVENT(item);
            this.edit_dialog = false;
            this.GET_EVENT_LIST();
        },
        changeStatus(stat, item){
                // console.log({'stat': stat, 'item': item } )
            const payload = {
                statusId: stat.id,
                id: item.id
            }
            this.UPDATE_EVENT(payload).then(()=>{
                this.GET_EVENT_LIST();
            }).catch(err=>{
                console.log(err)
            })
            // console.log(payload)
        }
    },
    mounted() {
        this.GET_EVENT_LIST()
    }
}
</script>

<style scoped>
.active {
    background: rgb(223 235 223);
    color: rgb(117 181 124);
}

.inactive {
    background: rgb(255 236 225);
    color: rgb(253 151 70)
}

tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.v-table .v-table__wrapper>table>tbody>tr td {
    border-bottom: none;
    font-size: small;
    font-weight: 500;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    border-bottom: none;
}

.v-table {
    font-family: Montserrat, sans-serif !important;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    font-weight: 600 !important;
}
</style>