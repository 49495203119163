<template>
    <v-container class="px-10" fluid style="height:100vh; background:#e7f0f7 ;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            APP VERSION
        </div>
        <v-card elevation="0" width="50%" class="ma-auto" style="font-family:'Montserrat', sans-serif !important">
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-avatar rounded size="30" class="ma-1" @click="openEditDialog()"
                     color="blue-darken-2" style="cursor: pointer;">
                    <v-icon size="16" icon="mdi-pencil-outline"></v-icon>
                </v-avatar>
            </div>
            <!-- <v-card-text>
                <span class="dataVal"> App Status:</span> {{ versionData.status }}
            </v-card-text> -->
            <v-card-text>
                <span class="dataVal"> Version:</span> {{ versionData.version }}
            </v-card-text>
            <v-card-text>
                <span class="dataVal">Supported Version:</span> {{ versionData.supportingVersion }}
            </v-card-text>
            <v-card-text>
               <span class="dataVal" >Support Number:</span>  {{ versionData.supportNumber }}
            </v-card-text>
        </v-card>
        <editVersion :visible="edit_dialog" @close="edit_dialog = false" @save="updateSubAdmin" :title="edit_dialog_title"
            :item="values" />
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import editVersion from '@/components/version/editVersio.vue'
export default {
    name: 'versionVue',
    data(){
        return {
            edit_dialog: false,
            values:{}
        }
    },  
    components:{
        editVersion
    },
    computed: {
        ...mapState('version', ['versionData'])
    },
    methods: {
        ...mapActions('version', ['GET_VERSION']),
        openEditDialog(){
            this.values = this.versionData
            this.edit_dialog = true
        }

    },
    mounted() {
        this.GET_VERSION()
    }

}


</script>

<style scoped>
.dataVal {
    font-weight: 600;
}
</style>