<template>
    <v-container class="px-10" fluid style="height: 100vh; background: #e7f0f7;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            EVENT LOG
        </div>
        <searchAndFilterToolbar :btn_text="serv_text" @btn_action="dialog = true" @filterBtn="openFilter()"
            :placeholder="searchPlaceHolder" @search="searhInEventLog" />
        <Transition name="slide-fade">
            <div class="mt-4 mb-2" v-if="filterDisplay">
                <v-row>
                    <v-col cols="12" md="3">
                        <v-autocomplete v-model="type" hide-details variant="outlined" density="comfortable"
                            :items="memberRole" item-title="type" clearable item-value="id" label="Type">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field color="" hide-details label="Event Name" density="comfortable" v-model="Ename"
                            variant="outlined" clearable>
                        </v-text-field>
                    </v-col>


                    <v-col cols="12" md="2" style="align-self: center;">
                        <v-btn style="background: rgb(4 43 76) ;" @click="filter()"> <span
                                style="color: white">filter</span> </v-btn>
                    </v-col>
                </v-row>
            </div>
        </Transition>
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <th class="text-left">
                        Member Id
                    </th>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-left">
                        type
                    </th>
                    <th class="text-left">
                        Event Name
                    </th>
                    <th class="text-left">
                        Distribution Date
                    </th>
                    <th class="text-left">
                        Actions
                    </th>


                </tr>
            </thead>
            <tbody>

                <tr v-for="item in eventLogList" :key="item.name">


                    <td>{{ item.memberId }}</td>
                    <td>{{ item.member.firstname + ' ' + item.member.lastname }}</td>
                    <td>{{ item.member.membersRole.type }}</td>
                    <td>{{ item.event.eventname }}</td>
                    <td>{{ item.event.date }}</td>
                    <td>
                        <!-- <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="toInnerPage(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-eye-outline"></v-icon>
                            </v-avatar>
                        </v-hover> -->
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteDailog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                    </td>

                </tr>
            </tbody>
        </v-table>
        <!-- <paginationVue :length="eventLogTotalPage" @chanegePage="chanegePage" /> -->
        <v-pagination rounded="circle" v-model="page" class="my-4" :length="eventLogTotalPage" :total-visible="6"
            @update:model-value="chanegePage"></v-pagination>
        <editEventLogVue :visible="dialog" @close="dialog = false" @save="addDistribution" :val="editVal"
            :title="serv_text" :temp="tempVal" />
        <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false" :item="deleteValue"
            @delete="deleteEventLog" />
        <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />


    </v-container>
</template>

<script>
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import { mapActions, mapState } from 'vuex';
import paginationVue from '@/components/pagination.vue';
import editEventLogVue from '@/components/EventLog/edditEventLog.vue';
import deleteWarnVue from '@/components/deleteWarn.vue';
import snackbarVue from '@/components/snackbar.vue'


// import addcalibrationVue from '@/components/addCalibration.vue';
export default {
    name: 'distribution',
    components: {
        searchAndFilterToolbar,
        paginationVue,
        editEventLogVue,
        deleteWarnVue,
        snackbarVue
    },
    data() {
        return {
            serv_text: '',
            dialog: false,
            filterDisplay: false,
            delete_dialog: false,
            type: '',
            Ename: '',
            editVal: {},
            deleteValue: {},
            tempVal: '',
            snackbar: false,
            mssg: '',
            snackColor: '',
            searchPlaceHolder: 'Search with Member Id and name',
            currentPage: '',
            page: 1,
        }
    },
    computed: {
        ...mapState('eventsLog', ['eventLogList', 'eventLogTotalPage']),
        ...mapState('member', ['memberRole'])
    },
    methods: {
        ...mapActions('events', ['ADD_EVENTS', 'GET_EVENT_LIST', 'UPDATE_EVENT']),
        ...mapActions('eventsLog', ['GET_EVENT_LOG_LIST', 'DELETE_EVENT_LOG']),
        ...mapActions('member', ['GET_MEMBER_ROLE']),
        openFilter() {

            this.filterDisplay = !this.filterDisplay
        },
        chanegePage(item) {
            this.currentPage = item
            this.$router.replace({ query: { page: this.currentPage } })

            const query = {
                page: item,
                size: 15
            }
            this.GET_EVENT_LOG_LIST(query)
        },
        filter() {
            this.page = 1;
            this.$router.replace({ query: { page: 1 } })

            const query = {
                page: 1,
                size: 15,
                rid: this.type,
                evnt: this.Ename
            }
            this.GET_EVENT_LOG_LIST(query);
        },
        openEditDialog(item) {
            this.editVal = {
                id: item.id,
                memberId: item.memberId,
                eventId: item.eventId,
                remark: item.remarks
            }
            this.tempVal = item.eventId
            // console.log(this.editVal)
            this.dialog = true
        },
        toInnerPage(item) {
            console.log(item.id)
        },
        openDeleteDailog(item) {
            this.deleteValue = item;

            this.delete_dialog = true
        },
        deleteEventLog(val) {
            this.DELETE_EVENT_LOG(val).then(() => {
                this.delete_dialog = false;
                this.GET_EVENT_LOG_LIST({ page: this.$route.query.page, size: 15 });
                this.mssg = 'Event Log Deleted Successfully';
                this.snackColor = 'success';
                this.snackbar = true

            })
        },
        searhInEventLog(val){
            this.page = 1;
            this.$router.replace({ query: { page: 1 } });

            const query = {
                search: val,
                page: 1,
                size: 15
            }
            this.GET_EVENT_LOG_LIST(query)
        }
    },
    mounted() {
        this.page = parseInt(this.$route.query.page)

        this.GET_EVENT_LOG_LIST({ page: this.$route.query.page, size: 15 });
        this.GET_MEMBER_ROLE();
        this.GET_EVENT_LIST();
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}
</style>