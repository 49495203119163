import axios from 'axios'
export default {
    namespaced: true,
    state: {
        adminList: {},
        adminWithID: {}
       
    },
    mutations: {
        SET_ALL_ADMIN( state, data){
            state.adminList = data
        },
        SET_ADMIN_WITH_ID( state, data ){
            state.adminWithID = data
        },
        // SET_APP_USER_WITH_ID( state, data){
        //     state.appUserWithId = data
        // }
      
    },
    actions: {
        async GET_ALL_ADMIN({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}list_of_users`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
                options.params = query
            }
            const data = await axios(options)
             commit('SET_ALL_ADMIN', data.data.data)
            //  console.log(data.data)
            //  commit('SET_APP_USER_TOTAL_PAGE', data.data.totalPages)
        },
        async GET_ADMIN_WITH_ID({ commit }, payload){
                // console.log(payload)
                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const  getAdmin = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}users/get_user/ ${ payload } `,
                    params: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
                })
                commit('SET_ADMIN_WITH_ID', getAdmin.data.data)
            //  console.log(getAdmin);
        },
        async UPDATE_ADMIN({ commit }, payload){
                 const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}users/update_users`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                //  console.log(update)

            
        },
       
    },
    getters: {
      
        
    }

}