<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-top-transition" activator="parent">
            <v-card width="800">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">{{ title }}</div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="Version" density="comfortable"
                                    v-model="item.version" variant="underlined">
                                </v-text-field>
                                <span v-if="v$.version.$error" style="color: red">
                                    {{ v$.version.$errors[0].$message }}
                                </span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="Supported Version" density="comfortable"
                                    v-model="item.supportingVersion" variant="underlined">
                                </v-text-field>
                                <span v-if="v$.supportVersion.$error" style="color: red">
                                    {{ v$.supportVersion.$errors[0].$message }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!-- <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="App Status" density="comfortable"
                                    v-model="item.status" variant="underlined">
                                </v-text-field>
                                <span v-if="v$.status.$error" style="color: red">
                                    {{ v$.status.$errors[0].$message }}
                                </span>
                            </v-col> -->
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="Support Number" density="comfortable"
                                    v-model="item.supportNumber" variant="underlined">
                                </v-text-field>
                                <span v-if="v$.number.$error" style="color: red">
                                    {{ v$.number.$errors[0].$message }}
                                </span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="updateVersion()">
                        save
                    </v-btn>
                </v-card-actions>
            </v-card>
            <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />
        </v-dialog>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, numeric, decimal, } from "@vuelidate/validators";
import { mapActions } from 'vuex';
import snackbarVue from '@/components/snackbar.vue'

export default {
    name: 'editAdmin',

    data() {
        return {
            v$: useVuelidate(),
            dialog: false,
            version: '',
            supportVersion: '',
            status: '',
            number: '',
            snackbar: true,
            mssg: '',
            snackColor: '',

        }
    },
    components() {
        snackbarVue
    },
    validations() {
        return {
            version: { required },
            supportVersion: { required },
            // status: { required },
            number: { required },

            // password: { required },
            // confirmPass: { required },
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        },
        item: {
            type: Array
        }

    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        ...mapActions('version', ['UPDATE_VERSION', 'GET_VERSION']),

        updateVersion() {
            this.version = this.item.version;
            this.supportVersion = this.item.supportingVersion;
            this.status = this.item.status;
            this.number = this.item.supportNumber;
            this.v$.$validate();
            if (!this.v$.$error) {
                const payload = {
                    id: this.item.id,
                    version: this.version,
                    supportingVersion: this.supportVersion,
                    supportNumber: this.number,
                    status: this.status

                }
                console.log(payload)
                this.UPDATE_VERSION(payload).then(() => {
                    this.show = false;
                    this.GET_VERSION();
                    this.snackColor = 'success';
                    this.mssg = 'Version Updated successfully';
                    this.snackbar = true

                })
            }




        },
        
    },
    mounted() {

    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>