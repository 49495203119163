<template>
  <v-container class="pa-10" fluid style="height:100vh; background: #e7f0f7;">
    <div class="mb-5">
      <dashboardCard :dash="dashValue1" />
    </div>
    <p class="card_text_sub">Weekly Collection </p>
    <v-divider></v-divider>
    <div class="mt-7">
      <dashboardCard :dash="dashValue2" />
    </div>
  </v-container>
</template>

<script>
import dashboardCard from '../components/dashboardCards.vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'dashboard',
  data() {
    return {
      dashValue1: [],
      dashValue2: [],

    }
  },
  components: {
    dashboardCard,

  },
  computed: {
    ...mapState('dashboard', ['dashboardValue']),
    // weekNo(){
    //   return moment().isoWeek()
    // }
  },
  methods: {
    ...mapActions('dashboard', ['GET_DASHBOARD']),
    async getDashValues() {
      await this.GET_DASHBOARD().then(() => {
        const values1 = [
          {
            id: 1,
            title: 'Students',
            data: this.dashboardValue.activestudentscount + '/' + this.dashboardValue.studentscount
          },
          {
            id: 2,
            title: 'Non-students',
            data: this.dashboardValue.activenonstudentscount + '/' + this.dashboardValue.nonstudentscount
          },
          {
            id: 3,
            title: 'Family',
            data: this.dashboardValue.activefamilycount + '/' + this.dashboardValue.familycount
          },
          // {
          //   id: 4,
          //   title: 'Students Collected',
          //   data: this.dashboardValue.studentlog + '/' + this.dashboardValue.studentscount
          // },
          // {
          //   id: 5,
          //   title: 'Non-student Collected',
          //   data: this.dashboardValue.nonstudentlog + '/' + this.dashboardValue.nonstudentscount
          // },
          // {
          //   id: 6,
          //   title: 'Family Collected',
          //   data: this.dashboardValue.familylog + '/' + this.dashboardValue.familycount
          // },
        ]
        const values2 = [
          {
            id: 4,
            title: 'Students Collected',
            data: this.dashboardValue.studentlog + '/' + this.dashboardValue.studentscount
          },
          {
            id: 5,
            title: 'Non-student Collected',
            data: this.dashboardValue.nonstudentlog + '/' + this.dashboardValue.nonstudentscount
          },
          {
            id: 6,
            title: 'Family Collected',
            data: this.dashboardValue.familylog + '/' + this.dashboardValue.familycount
          },
        ]
        this.dashValue1 = values1
        this.dashValue2 = values2
        // console.log(this.dashboardValue)
      }).catch(err => {
        console.log(err)
        if (err.response.status == 401) {
          return this.$router.push({ name: 'signIn' })
        }
      })
    }

  },
  mounted() {
    this.getDashValues()
  }


}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.card_text_sub {
  font-family: Montserrat, sans-serif !important;
  font-weight: 500;
  font-size: 22px;
  /* width: min-content; */
  /* text-align: center; */
  line-height: normal;
}
</style>
