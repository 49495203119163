import axios from 'axios'
export default {
    namespaced: true,
    state: {
        supportList: {},
        supportTotalPage: '',
        supportWithId: {}
        //  editServicetValue:{}
    },
    mutations: {
        SET_SUPPORT_LIST( state, data){
            state.supportList = data
        },
        SET_SUPPORT_TOTAL_PAGE( state, data ){
            state.supportTotalPage = data
        },
        SET_SUPPORT_WITH_ID(state, data){
            state.supportWithId = data

        }
      
    },
    actions: {
        async GET_SUPPORT_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}support/list_of_supportrequests`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
                options.params = query
            }
            const data = await axios(options)
             commit('SET_SUPPORT_LIST', data.data.data)
            //  console.log(data.data)
             commit('SET_SUPPORT_TOTAL_PAGE', data.data.totalPages)
        },
        async GET_SUPPORT_WITH_ID({ commit, state }, payload){
            try {

                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const  getSupport = await axios({
                    method: 'get',
                    url :`${process.env.VUE_APP_BASE_URL}support/list_of_supportrequests`,
                    // data: payload,
                    params: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
             console.log(getSupport);
             commit('SET_SUPPORT_WITH_ID', getSupport.data.data[0] );
             console.log(state.supportWithId)

            } catch (error) {
                console.log(error);
            }
            

        },
      
        async DELETE_SUPPORT({ commit }, payload){
            // console.log(payload)
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                const deleteSupport = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}support/delete_request/${ payload.id }`,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
                })
                // console.log(deleteSupport)

            } catch (error) {
                console.log(error)
            }
        } ,
       
        async UPDATE_SUPPORT({ commit }, payload){
            try{
                 const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}support/update_request`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                //  console.log(update)

            }catch(error){
                console.log(error)
            }
        },
       
    },
    getters: {
        getClientById:(state) => (id) =>{
            return state.clientList.find( list => list.id === id )
        } 
        
    }

}