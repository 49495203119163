<template>

  <v-navigation-drawer @click="rail = false" v-model="drawer" :rail="rail" permanent color="rgb(0 44 75)">

    <!-- <v-list-item prepend-icon="mdi-alpha-m-box" height="50" class="just"> -->
    <v-list-item  :prepend-avatar="logo" height="80" class="just">
      <!-- <v-img :src="require('@/assets/logo 2 white.png')"></v-img> -->
      <img style="width: 113px; height: 53px;" :src="require('@/assets/logo 2 white.png')" alt="">
      <!-- <v-spacer></v-spacer> -->
      <template v-slot:append>
        <v-btn variant="text" color="white" icon="mdi-chevron-left" @click.stop="rail = !rail"></v-btn>
      </template>
    </v-list-item>

    <v-list density="default" nav class="mt-6" color="rgb(0 44 75)" bg-color="rgb(0 44 75)"
      style="font-family: 'Montserrat', sans-serif;">
      <v-list-item prepend-icon="mdi-view-dashboard-outline" :to="{ name: 'dash' }" title="Dashboard" value="dashboard">

      </v-list-item>
      <v-list-item prepend-icon="mdi-face-agent" :to="{ name: 'support' } " title="Support" value="support">

      </v-list-item>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item color="#e4e5e9" prepend-icon="mdi-package-variant" v-bind="props" title="Distribution"
            value="DistributionManagement">
          </v-list-item>
        </template>
        <v-list-item color="#e4e5e9" prepend-icon="" :to="{ name: 'members', query: { page: 1 } }" title="Members" value="Members">
        </v-list-item>
        <v-list-item color="#e4e5e9" prepend-icon="" :to="{ name: 'distribution_log', query: { page: 1 } }" title="Distribution Log"
          value="Distribution"></v-list-item>
        <v-list-item color="#e4e5e9" prepend-icon="" :to="{ name: 'event_log', query: { page: 1 } }" title="Event Log" value="Events">
        </v-list-item>
      </v-list-group>


      <!-- <v-list-item prepend-icon="mdi-view-dashboard-outline" title="Reports" value="report">
      </v-list-item> -->
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item color="#e4e5e9" prepend-icon="mdi-chart-box-outline" v-bind="props" title="Reports"
            value="report">
          </v-list-item>
        </template>
        <v-list-item color="#e4e5e9" prepend-icon="" :to="{ name: 'memberReport' }" title="Members" value="mem">
        </v-list-item>
        <v-list-item color="#e4e5e9" prepend-icon="" :to="{ name: 'distributionReport' }" title="Distributions"
          value="distr">
        </v-list-item>

      </v-list-group>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item color="#e4e5e9" prepend-icon="mdi-account-group-outline" v-bind="props" title="Users"
            value="users">
          </v-list-item>
        </template>
        <v-list-item color="#e4e5e9" prepend-icon="" :to="{ name: 'admin' }" title="Admin" value="admin"></v-list-item>
        <v-list-item color="#e4e5e9" prepend-icon="" :to="{ name: 'app_user' }" title="App user" value="app_user">
        </v-list-item>

      </v-list-group>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item color="#e4e5e9" prepend-icon="mdi-cog-outline" v-bind="props" title="Settings" value="reports">
          </v-list-item>
        </template>
        <v-list-item color="#e4e5e9" prepend-icon="" title="Extras" value="city" :to="{ name: 'extras' }"></v-list-item>
        <v-list-item color="#e4e5e9" prepend-icon="" title="Event" value="event" :to="{ name: 'event' }">
        </v-list-item>
        <!-- <v-list-item color="#e4e5e9" prepend-icon="" title="Borough" value="borough" :to="{ name: 'borough' }">
        </v-list-item> -->
        <v-list-item color="#e4e5e9" prepend-icon="" title="University" value="university" :to="{ name: 'university' }">
        </v-list-item>
        <v-list-item color="#e4e5e9" prepend-icon="" title="Version" value="version" :to=" { name: 'version' } ">
        </v-list-item>
        <v-list-item color="#e4e5e9" prepend-icon="" title="Log Out" value="logout" @click="logoutWarn()">
        </v-list-item>
      </v-list-group>
    </v-list>

  </v-navigation-drawer>


  <!-- APP BAR -->


  <v-app-bar class="ma-0" elevation="0" color="rgb(254 254 254)" height="50">
    <v-app-bar-nav-icon style="color:grey" @click.stop="rail = !rail"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <!-- <v-avatar class="mr-4">
      <v-img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John"></v-img>
    </v-avatar> -->
    <!-- <div class="name mr-6" style="">
      Fasal Rahman
    </div>-->
    <!-- <v-badge content="1" offset-x="4" v-model="bell" color="error">
      <v-icon size="large" color="black">mdi-bell-outline</v-icon>
    </v-badge> -->

  </v-app-bar>
  <v-divider></v-divider>



  <!-- ROUT VIEW -->
  <v-container fluid class="pa-0">
    <deleteWarnVue :visible="log_dialog" @close="log_dialog = false" :item="deleteValue" :mssg="'Are you sure you want to logout ?'" :title="'Confirm Logout'" @delete="logOut()"
      :log="true" />

    <router-view />
  </v-container>
</template>

<script>
import axios from 'axios'
import deleteWarnVue from './deleteWarn.vue';
export default {
  name: 'HelloWorld',
  components: {
    deleteWarnVue
  },
  data: () => ({
    drawer: true,
    bell: true,
    log_dialog: false,
    items: [
      { title: 'Home', icon: 'mdi-home-city' },
      { title: 'My Account', icon: 'mdi-account' },
      { title: 'Users', icon: 'mdi-account-group-outline' },
    ],
    rail: false,
    logo: require('@/assets/logo 1.png')


  }),
  methods: {

    logoutWarn() {
      this.log_dialog = true
    },
    async logOut() {
      const auth = JSON.parse(localStorage.getItem('user'))
      await axios({
        method: 'get',
        url: `${process.env.VUE_APP_BASE_URL}auth/log_out`,
        headers: {
          Authorization: 'Bearer ' + auth.access_token
        }
      }).then(() => {
        localStorage.clear();
        this.$router.push({ name: 'signIn' })
      })
    }
  },
  mounted() {
    if (window.innerWidth < 780) {
      this.rail = true
    }
  }



}
</script>
<style scoped>
.v-icon {
  color: white;
}

.v-list--nav {
  color: white;
  overflow: hidden;
}

.v-list-item--active {

  color: rgb(0 44 75);

  background: rgb(82 157 226);
}

.name {
  font-family: Montserrat, sans-serif !important;
  font-weight: 500;
  color: #4b4b4b;
}

/* .v-list-group--prepend {
  --parent-padding: calc(var(--indent-padding) + 2px);
} */

/* .v-list-item--density-compact {} */
</style>
<style>
.v-navigation-drawer--rail>.v-navigation-drawer__content {
  max-width: 86%;
}
</style>
