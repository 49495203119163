<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-top-transition" activator="parent">
            <v-card width="400">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">{{ title }}</div>
                </v-card-title>

                <v-card-text>


                    <v-text-field color="#9c27b0" label="name" density="comfortable" v-model="name"
                        variant="outlined">
                    </v-text-field>
                    <v-autocomplete  variant="outlined" density="comfortable" v-model="type" clearable
                        :items="distribution" item-title="name" item-value="id" label="Distribution Cycle">
                    </v-autocomplete>
                    <v-autocomplete hide-details variant="outlined" density="comfortable" v-model="role" clearable
                        :items="memberRole" item-title="type" item-value="id" label="Member Type">
                    </v-autocomplete>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="addExtra()">
                        save
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'AddCity',
    data() {
        return {
            dialog: false,
            name: '',
            type: '',
            role:'',
            
            distribution:[
                {
                    id:1,
                    name: 'Weekly'
                },
                {
                    id:2,
                    name: 'Monthly'
                },
                {
                    id:3,
                    name: 'Every two weeks'
                },
            ]
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        }

    },
    computed: {
        ...mapState('member', ['memberRole']),
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        ...mapActions('member', ['GET_MEMBER_LIST', 'GET_MEMBER_ROLE', 'ADD_MEMBER', 'DELETE_MEMBER', 'GET_MEMBER_BY_ID', 'UPDATE_MEMBER']),

        addExtra() {

            const payload = {
                extrasname: this.name,
                extrasTypeId: this.type,
                membersRoleId: this.role,
                extrasStatusId: 1
                
            }
            if(!this.name || !this.type) return alert('enter all values')
            this.$emit('save', payload);
            this.name = '';
             this.type= ''
             this.v$.$reset();

        }
    },
    created() {
        this.name = '';
        this.GET_MEMBER_ROLE()

    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>