<template>
    <v-container class="px-10 " fluid style="height:100vh; background:#e7f0f7 ;">
        <v-icon @click="routerBack()">mdi-arrow-left</v-icon>
        <v-card class=" mt-3 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-title class="text-h5">
                Distribution Details
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Member Id: </span> {{ destributionLogWithId.memberId}} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap" v-if="destributionLogWithId.member">
                                <span class="detval"> Name: </span> {{
                                destributionLogWithId.member.firstname + ' ' + destributionLogWithId.member.lastname
                                }}
                            </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Month: </span> {{ NumToMonth(destributionLogWithId.month) }}
                            </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> WeeK Number: </span> {{ destributionLogWithId.weekNumber }}
                            </span>
                        </v-card-title>
                        <!-- <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap"> <span class="detval"> Year: </span> {{
                                destributionLogWithId.year
                            }} </span>
                        </v-card-title> -->
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap" v-if="destributionLogWithId.createdAt"> <span class="detval"> Collection
                                    Date:
                                </span> {{ destributionLogWithId.createdAt.split('T')[0] }} </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap" > <span class="detval"> Staff :
                                </span>
                                <span v-if="destributionLogWithId.staff"> {{ destributionLogWithId.staff.fullname }} </span>
                                <!-- <span v-else> Admin </span> -->
                             </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <!-- <td v-if="item.staff">{{ item.staff.fullname }}</td>
                    <td v-else>Admin</td> -->
                            <span class="cap"> <span class="detval"> Extras:
                                </span> <span v-for="(extras, i) in destributionLogWithId.collectionLogs" :key="i">
                                    {{ extras.extra.extrasname }} <span
                                        v-if="i != destributionLogWithId.collectionLogs.length - 1">,</span>
                                </span> </span>

                        </v-card-title>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-4 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-text>
                <v-card-title class="pa-1 " style="line-height:1.5rem">
                    <span> <span class="detval"> Remarks: </span> {{ destributionLogWithId.remarks}} </span>
                </v-card-title>
            </v-card-text>
        </v-card>



    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment'
export default {
    name: 'serviceInner',
    computed: {
        ...mapState('destributionLog', ['destributionLogWithId']),


    },
    methods: {
        ...mapActions('destributionLog', ['GET_DESTRIBUTION_LOG_LIST', 'GET_DISTRIBUTION_LOG_BY_ID']),

        routerBack() {
            this.$router.back()
        },
        NumToMonth(num) {

            return moment(num, 'M').format('MMMM')
        }

    },
    mounted() {
        const payload = {
            id: this.$route.params.id
        }
        console.log(payload);
        this.GET_DISTRIBUTION_LOG_BY_ID(payload)
    }
} 
</script>

<style scoped>
.details {
    font-size: large;
    font-weight: 500;
    margin-top: 19px;
    border: 1px solid #bbbfee;
    padding: 23px;
}

.detval {
    font-weight: 600;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

.cap {
    text-transform: capitalize;
}
</style>