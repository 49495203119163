<template>
    <v-container class="px-10" fluid style="height:100vh; background:#e7f0f7 ;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            BOROUGH
        </div>
        <searchAndFilterToolbar :btn_text="cl_text" @btn_action="dialog = true" :filButton="false" @search="searchFilter" />
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <th class="text-left">
                        id
                    </th>
                    <th class="text-left">
                        NAME
                    </th>
                    
                    <th class="text-left">
                        ACTOINS
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item) in boroughList" :key="item.id">
                    <td>{{ item.id}}</td>
                    <td>{{ item.name }}</td>
                    
                    <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                    </td>
                </tr>
            </tbody>
        </v-table>
         <paginationVue :length="boroughTotalPage"  @chanegePage="chanegePage"/>
        <addBoroughVue :visible="dialog" @close="dialog = false" @save="addBorough" :title="dialog_title" />
        <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false" :item="deleteValue"
            @delete="deleteCareof" />
        <editBorough :visible="edit_dialog" @close="edit_dialog = false" @save="editBorough"  :title="edit_dialog_title"
            :item="edit_value"/>

    </v-container>
</template>

<script>
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import { mapActions, mapState } from 'vuex'
import deleteWarnVue from '@/components/deleteWarn.vue';
import addBoroughVue from '@/components/borough/addBorough.vue'
import editBorough from '@/components/borough/editBorough.vue'
import paginationVue from '@/components/pagination.vue';
export default {
    name: 'borough',
    components: {
        searchAndFilterToolbar,
        addBoroughVue,
        deleteWarnVue,
         editBorough,
         paginationVue
    },
    data() {
        return {
            edit_dialog: false,
            edit_value: '',
            edit_id: '',
            edit_dialog_title: 'Edit Borough',
            delete_dialog: false,
            cl_text: 'Add Borough',
            dialog: false,
            deleteValue: {},
            dialog_title: 'Add Borough',
            borough:[
                {
                    id: 1,
                    name: 'borough 1',
                },
                {
                    id: 2,
                    name: 'borough 2',
                }
            ]

        }
    },
    computed: {
        ...mapState('borough',['boroughList', 'boroughTotalPage'])
    },
    methods: {
        ...mapActions('borough', ['GET_BORUGH_LIST', 'ADD_BOROUGH', 'UPDATE_BORUGH', 'DELETE_BOROUGH']),
        async addBorough(item) {
           await this.ADD_BOROUGH(item);
            this.dialog = false;
            await this.GET_BORUGH_LIST()

        },
        openDeleteDialog(item) {
           this.deleteValue = item;
           this.delete_dialog = true
        },
        async deleteCareof(item) {
            await this.DELETE_BOROUGH(item);
            this.delete_dialog = false;
            await this.GET_BORUGH_LIST()

        },
         openEditDialog(item){
            this.edit_value = item.name;
            this.edit_id = item.id
           this.edit_dialog = true
        },
        async editBorough(item){
            item.id = this.edit_id;
           await this.UPDATE_BORUGH(item);
           this.edit_dialog = false;
           await this.GET_BORUGH_LIST()

            
        },
        searchFilter(item){
            const query = {
                name: item,
                page: 15,
            }
            this.GET_BORUGH_LIST(query)

        },
        chanegePage(item){
            const query = {
                page: item,
                size: 15
            }
            this.GET_BORUGH_LIST(query)
        }
    },
    mounted() {
        this.GET_BORUGH_LIST()
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.v-table .v-table__wrapper>table>tbody>tr td {
    border-bottom: none;
    font-size: small;
    font-weight: 500;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    border-bottom: none;
}

.v-table {
    font-family: Montserrat, sans-serif !important;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    font-weight: 600 !important;
}
</style>