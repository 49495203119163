<template>
    <v-container class="px-10" fluid style="height:100vh; background:#e7f0f7 ;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            SUPPORT
        </div>
        <searchAndFilterToolbar :btn_text="cl_text" @btn_action="dialog = true" :filButton="true" @search="searhSupport"
            @filterBtn="filterDisplay = !filterDisplay" :placeholder="'Search by Name'" />
        <Transition name="slide-fade">
            <div class="mt-4 mb-2" v-if="filterDisplay">
                <v-row>
                    <v-col cols="12" md="3">
                        <v-autocomplete v-model="selStatus" hide-details variant="outlined" density="comfortable"
                            :items="statuses" item-title="name" clearable item-value="id" label="Status">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="selSubs" hide-details variant="outlined" density="comfortable"
                            :items="subjects" clearable  label="Type">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2" style="align-self: center;">
                        <div class="d-flex">
                            <v-btn style="background: rgb(4 43 76) ;" @click="filterdata()"> <span
                                    style="color: white">filter</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </Transition>
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <!-- <th class="text-left">
                        #
                    </th> -->
                    <th class="text-left">
                        NAME
                    </th>
                    <th class="text-left">
                        TYPE
                    </th>
                    <th class="text-left">
                        EMAIL
                    </th>
                    <th class="text-left">
                        MOBILE
                    </th>
                    <th class="text-left">
                        STATUS
                    </th>
                    <th class="text-left">
                        ACTIONS
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for=" item in supportList" :key="item.id">
                    <!-- <td>{{ i + 1 }}</td> -->
                    <td>{{ item.fullname }}</td>
                    <td>{{ item.subject }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.mobile }}</td>
                    <td class="">
                        <v-chip
                            :class="{active: item.supportStatusId == 1, inProgress :item.supportStatusId == 2, clossed :item.supportStatusId == 3, cancel:item.supportStatusId == 4 }"
                            style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
                            item.supportStatus.statustype
                            }}</span>
                            <v-menu activator="parent">
                                <v-list class="pa-0">
                                    <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                        :class="{ active: it.id == 1, inProgress: it.id == 2, clossed: it.id == 3, cancel: it.id == 4 }"
                                        :key="it">
                                        <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-chip>
                        <!-- <v-chip color="#fd5e00" v-else-if="item.supportStatusId == 2"
                            style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
                                    item.extrasStatus.status
                            }}</span>
                            <v-menu activator="parent">
                                 :class="{ Open: it.name === 'Active', Accepted: it.name === 'In-Active' }"
                                <v-list class="pa-0">
                                    <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                       :class="{ active: it.id == 1, inactive: it.id == 2 }"
                                        :key="it">
                                        <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                    </v-list-item>

                                </v-list>
                            </v-menu>
                        </v-chip> -->
                    </td>
                    <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="toInnerPage(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-eye-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <paginationVue :length="supportTotalPage" @chanegePage="chanegePage" />

        <editSupportVue :visible="edit_dialog" @close="edit_dialog = false" @save="editSupport" :val="supportWithId" />
        <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false" :item="deleteValue"
            @delete="deleteSupport" />
        <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />


    </v-container>
</template>

<script>
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import deleteWarnVue from '@/components/deleteWarn.vue';
import editSupportVue from '@/components/support/editSupport.vue';
import snackbarVue from '@/components/snackbar.vue';
import paginationVue from '@/components/pagination.vue'
import { mapActions, mapState } from 'vuex';
export default {
    name: 'support',
    components: {
        searchAndFilterToolbar,
        editSupportVue,
        deleteWarnVue,
        snackbarVue,
        paginationVue
    },
    data() {
        return {
            cl_text: '',
            dialog: false,
            snackbar: false,
            mssg: '',
            snackColor:'',
            edit_dialog: false,
            filterDisplay: false,
            edit_value: '',
            edit_id: '',
            edit_dialog_title: 'Edit Extra',
            dialog_title: 'Add Extra',
            delete_dialog: false,
            deleteValue: {},
            subjects: ['Employment', 'Landlord/property housing issues', 'Immigration', 'Other'],
            statuses: [
                {
                    id: 1,
                    name: 'Open'
                },
                {
                    id: 2,
                    name: 'In-Progress'
                },
                {
                    id: 3,
                    name: 'Clossed'
                },
                {
                    id: 4,
                    name: 'Cancel'
                },
            ],
            selStatus: '',
            selSubs: '',


        }
    },
    computed: {
        ...mapState('support', ['supportList', 'supportWithId', 'supportTotalPage'])
    },
    methods: {
        ...mapActions('support', ['GET_SUPPORT_LIST', 'GET_SUPPORT_WITH_ID', 'UPDATE_SUPPORT', 'DELETE_SUPPORT']),
        toInnerPage(item) {
            this.$router.push({ name: 'supportInner', params: { spId: item.id } })
        },
        async AddExtra(payload) {
            //    await this.ADD_EXTRAS(payload);
            //    this.dialog = false;
            //    await this.GET_EXTRA_LIST();
        },
        openDeleteDialog(item) {
            this.deleteValue = item;
            this.delete_dialog = true;
        },
        async deleteSupport(payload) {
            // console.log(payload)
            await this.DELETE_SUPPORT(payload).then(()=>{
                this.delete_dialog = false;
                this.mssg = 'Support Deleted Succesfully';
                this.snackColor = 'success';
                this.snackbar = true;
                // this.snac

            })

            await this.GET_SUPPORT_LIST();
        },
        openEditDialog(item) {
            const payload = {
                id: item.id
            }
            this.GET_SUPPORT_WITH_ID(payload).then(() => {
                this.edit_dialog = true;
            })
            // this.edit_value = item;
            // console.log(item)

        },
        async editSupport(item) {
            // console.log(item)
            await this.UPDATE_SUPPORT(item)
            this.edit_dialog = false;
            await this.GET_SUPPORT_LIST();

        },
        async changeStatus(stat, item) {
            // console.log({'stat': stat, 'item': item } )
            const payload = {
                supportStatusId: stat.id,
                id: item.id
            }
            //     // console.log(payload)
            await this.UPDATE_SUPPORT(payload);
            await this.GET_SUPPORT_LIST();
        },
        filterdata() {
            const query = {
                statusid: this.selStatus,
                subject: this.selSubs
            }
            this.GET_SUPPORT_LIST(query)
        },
        searhSupport(val){
            const query = {
                name: val
            }
            this.GET_SUPPORT_LIST(query)
        },
        chanegePage(item){
            const query = {
                page: item,
                statusid: this.selStatus,
                subject: this.selSubs,
                size: 15
            }
            this.GET_SUPPORT_LIST(query)
        }
    },
    mounted() {
        this.GET_SUPPORT_LIST()
    }
}
</script>

<style scoped>
.active {
    background: rgb(223 235 223);
    color: rgb(117 181 124);
}

.inProgress {
    background: rgb(255 236 225);
    color: rgb(253 151 70)
}

.clossed {
    background: rgb(225, 246, 255);
    color: rgb(69, 197, 248)
}

.cancel {
    background: rgb(255, 225, 225);
    color: rgb(248, 69, 69)
}

tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.v-table .v-table__wrapper>table>tbody>tr td {
    border-bottom: none;
    font-size: small;
    font-weight: 500;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    border-bottom: none;
}

.v-table {
    font-family: Montserrat, sans-serif !important;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    font-weight: 600 !important;
}
</style>