<template>
  <v-container>
    <!-- <v-row>
      <v-col cols="12" md="6" sm="12" class="pa-0"> -->


        <v-card class="mx-auto pa-10 " width="460" rounded="0" min-height="680">
          <!-- <div class="text-center" style="width: 100%">
            <v-img :src="logo" class="ma-auto mt-" height="100" width="210" cover="" ></v-img>

          </div> -->
          <v-window v-model="step" :touch="false">
            <v-window-item :value="1">
              <v-card-title class="text-h5  justify-space-between text-center">
                Personal Details
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="3" sm="3">
                    <v-autocomplete variant="underlined" density="comfortable" v-model="titles" dense :items="Title"
                      item item-title="name" item-value="id" label="Title">
                    </v-autocomplete>
                    <span v-if="v$.titles.$error" style="color: red">
                      {{ v$.titles.$errors[0].$message }}
                    </span>

                  </v-col>
                  <v-col cols="12" md="5" sm="5">
                    <v-text-field label="First Name" density="compact" v-model="firstName" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.firstName.$error" style="color: red">
                      {{ v$.firstName.$errors[0].$message }}
                    </span>

                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <v-text-field label="Last Name" density="compact" v-model="secondName" variant="underlined">
                    </v-text-field>

                  </v-col>

                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-text-field label="Phone" density="compact" v-model="phone" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.phone.$error" style="color: red">
                      {{ v$.phone.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field label="Email" density="compact" v-model="email" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.email.$error" style="color: red">
                      {{ v$.email.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete @click="set_role()" variant="underlined" density="compact" v-model="type" clearable
                      :items="Role" item-title="type" item-value="id" label="Type">
                    </v-autocomplete>
                    <span v-if="v$.type.$error" style="color: red">
                      {{ v$.type.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="">


                  <v-col cols="12" sm="12" md="12">
                    <v-text-field label="Dependent Name" density="compact" v-model="DependentName" variant="underlined">
                    </v-text-field>


                  </v-col>


                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-autocomplete variant="underlined" density="compact" v-model="NoKids" clearable :items="CHnumber"
                      label="Number Of kids">
                    </v-autocomplete>

                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-title class="text-h5  justify-space-between text-center">
                Contact Details
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-autocomplete variant="underlined" density="compact" v-model="area" clearable :items="areas"
                      item-title="name" item-value="id" label="Area">
                    </v-autocomplete>
                    <span v-if="v$.area.$error" style="color: red">
                      {{ v$.area.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-text-field label="Country" density="compact" v-model="country" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.country.$error" style="color: red">
                      {{ v$.country.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-autocomplete variant="underlined" density="compact" v-model="borough" clearable :items="Borough"
                      item-title="name" item-value="id" label="Borough">
                    </v-autocomplete>
                    <span v-if="v$.borough.$error" style="color: red">
                      {{ v$.borough.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-text-field label="Zip" density="compact" v-model="zib" variant="underlined">
                    </v-text-field>
                    <span v-if="v$.zib.$error" style="color: red">
                      {{ v$.zib.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-textarea variant="underlined" label="Address" v-model="address" auto-grow outlined rows="2"
                      row-height="25" shaped>
                    </v-textarea>
                    <span v-if="v$.address.$error" style="color: red">
                      {{ v$.address.$errors[0].$message }}
                    </span>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-title class="text-h5  justify-space-between text-center">
                Education Details
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <v-text-field label="Student ID" density="compact" v-model="studentId" variant="underlined">
                    </v-text-field>
                    <!-- <span v-if="v$.studentId.$error" style="color: red">
                                        {{ v$.studentId.$errors[0].$message }}
                                    </span> -->
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <!-- UNIVERSITY -->
                    <v-autocomplete variant="underlined" density="compact" v-model="university" clearable
                      :items="univer" item-title="name" item-value="id" label="University">
                    </v-autocomplete>
                    <!-- <span v-if="v$.university.$error" style="color: red">
                                        {{ v$.university.$errors[0].$message }}
                                    </span> -->
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <div class="date d-flex pt-3" style="">
                      <p class="mr-2" style="color:#7d7d86;">Arrived date:</p>
                      <input type="date" v-model="ArrivedDate" />
                    </div>
                    <span v-if="v$.ArrivedDate.$error" style="color: red">
                      {{ v$.ArrivedDate.$errors[0].$message }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <div class="date d-flex" style="">
                      <p class="mr-2 " style="color:#7d7d86;">Course Start Date:</p>
                      <input type="date" v-model="startDate" />
                    </div>
                    <!-- <span v-if="v$.startDate.$error" style="color: red">
                                        {{ v$.startDate.$errors[0].$message }}
                                    </span> -->
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <div class="date d-flex" style="">
                      <p class="mr-2" style="color:#7d7d86;">Course End Date:</p>
                      <input type="date" v-model="EndDate" />
                    </div>
                    <!-- <span v-if="v$.EndDate.$error" style="color: red">
                                        {{ v$.EndDate.$errors[0].$message }}
                                    </span> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-window-item>
            <v-window-item :value="4">
              <v-card-title class="text-h5  justify-space-between text-center">
                Food Details
              </v-card-title>
              <v-card-text>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <!--  Food Details -->
                    <v-autocomplete variant="underlined" density="compact" v-model="deitryRequirment" clearable
                      :items="dietReq" item-title="name" item-value="id" label="Dietary Requirements">
                    </v-autocomplete>
                    <span v-if="v$.deitryRequirment.$error" style="color: red">
                      {{ v$.deitryRequirment.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="12">
                    <!--  Food Details -->
                    <v-autocomplete variant="underlined" density="compact" v-model="eggs" clearable :items="eggList"
                      item-title="name" item-value="id" label="Do you eat EGGS?">
                    </v-autocomplete>
                    <span v-if="v$.eggs.$error" style="color: red">
                      {{ v$.eggs.$errors[0].$message }}
                    </span>

                  </v-col>
                </v-row>
                <v-row class="">

                  <v-col cols="12" md="12">
                    <!--  Food Details -->
                    <v-autocomplete variant="underlined" density="compact" v-model="collectionDay" clearable
                      :items="prefCollection" label="Preferred Collection Day">
                    </v-autocomplete>
                    <span v-if="v$.collectionDay.$error" style="color: red">
                      {{ v$.collectionDay.$errors[0].$message }}
                    </span>

                  </v-col>

                </v-row>
                <!-- <v-row class="mb-4">
                  <div style="font-family: Montserrat, sans-serif !important;font-weight: 600;">

                    <p> <input type="checkbox" v-model="select" id="TC" name="TC" value="TC">
                      I hereby agree that this data will be stored and processed for the purpose of establishing
                      contact. I am aware that I can revoke my consent at any time by contacting us.
                    </p>
                  </div>
                </v-row> -->
              </v-card-text>
            </v-window-item>
          </v-window>

          <!-- <v-divider></v-divider> -->

          <v-card-actions>
            <!-- <v-spacer></v-spacer> -->
            <v-btn v-if="step > 1" text @click="step--" rounded="0" height="48" width="80" variant="outlined">
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="step < 4" color="white" style="background: #323232;" height="48" width="80" plain depressed
              rounded="0" @click="nextwindow()">
              Next
            </v-btn>
            <v-btn v-if="step == 4" color="black" style="background: #323232;" height="48" width="80" plain depressed
              rounded="0" @click="submitMemeber()">
              Submit
            </v-btn>
          </v-card-actions>
         
          <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />

        </v-card>
      <!-- </v-col>
     
    </v-row> -->
  </v-container>
</template>

<script>
/* eslint-disable */
import { mapState, mapActions } from 'vuex'
import useVuelidate from "@vuelidate/core";
import { required, numeric, decimal, maxLength, email } from "@vuelidate/validators";
import snackbarVue from '@/components/snackbar';

export default {
  name: 'addmemeberVue',
  components: {
    snackbarVue,
    
  },
  data() {
    return {
      v$: useVuelidate(),
      snackbarIn: true,
      snackbar: false,
      mssg: 'hello',
      snackColor: 'green',
      select: '',
      logo: require('@/assets/newham.jpeg'),
      step: 1,
      titles: '',
      firstName: '',
      secondName: '',
      phone: '',
      email: '',
      type: '',
      DependentName: null,
      NoKids: null,
      area: '',
      country: '',
      borough: '',
      address: '',
      zib: '',
      studentId: '',
      university: '',
      dialog: false,
      ArrivedDate: new Date().toISOString().substr(0, 10),
      startDate: new Date().toISOString().substr(0, 10),
      EndDate: new Date().toISOString().substr(0, 10),
      deitryRequirment: '',
      eggs: '',
      collectionDay: '',
      Title: ['Mr', 'Mrs', 'Miss', 'Ms',],
      CHnumber: ['1', '2', '3', '4', '5'],
      areas: [
        {
          id: 0,
          name: 'city',
        },
        {
          id: 1,
          name: 'Town',
        }
      ],
      dietReq: [
        {
          id: 0,
          name: 'Halal'
        },
        {
          id: 1,
          name: 'Vegiterian'
        },
      ],
      eggList: [
        {
          id: 1,
          name: 'Yes'
        },
        {
          id: 0,
          name: 'No'
        },
      ],
      Role: [{ id: 1, type: 'student' }],
      Borough: [{ id: 1, name: 'student' }],
      univer: [{ id: 1, name: 'student' }],
      prefCollection: ['TUESDAY AFTERNOON (STRICTLY Ladies with Children ONLY', 'FRIDAY AFTERNOON (STRICTLY Ladies with Children ONLY)', 'SATURDAY EVENING ( ALL OTHERS_)']


    }
  },
  props: {
    visible: {
      type: Boolean,
    },
    title: {
      type: String
    }

  },
  validations() {
    return {
      titles: { required },
      firstName: { required },
      // secondName: { required },
      phone: { required, numeric, maxLength: maxLength(12) },
      email: { required, email },
      type: { required },
      zib: { required },
      // DependentName: { required },
      NoKids: { required },
      area: { required },
      country: { required },
      borough: { required },
      address: { required },

      ArrivedDate: { required },

      deitryRequirment: { required },
      eggs: { required },
      collectionDay: { required }

    }
  },
  watch: {

  },
  computed: {
    ...mapState('member', ['memberRole']),
    ...mapState('borough', ['boroughList']),
    ...mapState('university', ['universityLis']),

    computed: {
      currentTitle() {
        switch (this.step) {
          case 1: return 'Sign-up'
          case 2: return 'Create a password'
          default: return 'Account created'
        }
      },
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    ...mapActions('member', ['GET_MEMBER_LIST', 'GET_MEMBER_ROLE', 'ADD_MEMBER', 'DELETE_MEMBER', 'GET_MEMBER_BY_ID', 'UPDATE_MEMBER']),
    ...mapActions('borough', ['GET_BORUGH_LIST']),
    ...mapActions('university', ['GET_UNIVERSITY_LIST']),
    nextwindow() {
      // console.log(this.v$)
      if (this.step == 1) {
        this.v$.titles.$validate();
        this.v$.firstName.$validate();
        this.v$.phone.$validate();
        this.v$.email.$validate();
        this.v$.type.$validate();
        //  this.v$.$validate();

        // console.log(this.v$)
        if (this.v$.$errors.length == 0) {
          this.step++
          this.Borough = this.boroughList;
          this.univer = this.universityLis;
        }


      } else if (this.step == 2) {
        this.v$.area.$validate();
        this.v$.country.$validate();
        this.v$.borough.$validate();
        this.v$.address.$validate();
        this.v$.zib.$validate();
        if (this.v$.$errors.length == 0) {
          this.step++
        }
        //  this.step++
      } else if (this.step == 3) {
        this.v$.ArrivedDate.$validate();

        if (this.v$.$errors.length == 0) {
          this.step++
        }


      }

    },
    async submitMemeber() {
      this.v$.deitryRequirment.$validate();
      this.v$.eggs.$validate();
      this.v$.collectionDay.$validate();
      if (this.v$.$errors.length == 0) {
        // if (!this.select) {
        //   this.snackColor = 'error';
        //   this.mssg = 'Please Agree to Terms and Conditions '
        //   this.snackbar = true;

        // } else {


          const payload = {
            statusId: 1,
            title: this.titles,
            firstname: this.firstName,
            lastname: this.secondName,
            phone: this.phone,
            email: this.email,
            membersroleId: this.type,
            dependentname: this.DependentName,
            noofkids: this.NoKids,
            area: this.area,
            country: this.country,
            boroughId: this.borough,
            address: this.address,
            zip: this.zib,
            studentid: this.studentId,
            universityId: this.university,
            arrivedin: this.ArrivedDate,
            coursestart: this.startDate,
            courseend: this.EndDate,
            diettype: this.deitryRequirment,
            eggs: this.eggs,
            preferredcollectionday: this.collectionDay
          }
          // this.$emit('add_memeber', payload)
         await this.ADD_MEMBER(payload).then(()=>{
          this.titles = ''
          this.firstName = ''
          this.secondName = ''
          this.phone = ''
          this.email = ''
          this.type = ''
          this.DependentName = ''
          this.NoKids = ''
          this.area = ''
          this.country = ''
          this.borough = ''
          this.address = ''
          this.zib = ''
          this.studentId = ''
          this.university = ''
          this.ArrivedDate = ''
          this.startDate = ''
          this.EndDate = ''
          this.deitryRequirment = ''
          this.eggs = ''
          this.collectionDa = ''
          this.step = 1
          this.v$.$reset();
          this.snackColor = 'success';
          this.mssg = 'Registration completed successfully'
          this.snackbar = true;
          this.$router.back()
         }).catch(err =>{
          console.log(err)
          this.snackColor = 'error';
          this.mssg = err;
          this.snackbar = true;
         })
          
        }

    //   }
    },
    set_role() {
      this.Role = this.memberRole;
    },
    set_borough() {

    },
    submitMemebers() {


    }




  },

  beforeMount() {
    const query = {
      size: 100
    }
    this.GET_MEMBER_LIST();
    this.GET_MEMBER_ROLE();
    this.GET_BORUGH_LIST(query);
    this.GET_UNIVERSITY_LIST(query);



    // boroughList
    // universityLis

  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&family=Montserrat:wght@400;500;600;800&family=Sora:wght@400;500&display=swap');
</style>
<style>


.input {
  outline: none;
}
</style>
<style scoped>
.v-card-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 600;
  font-family: Montserrat, sans-serif !important;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif */
}
</style>
