<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-top-transition" activator="parent">
            <v-card width="800">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">{{ title }}</div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="Username" density="comfortable" v-model="item.username"
                                    variant="underlined">
                                </v-text-field>
                                <span v-if="v$.username.$error" style="color: red">
                                    {{ v$.username.$errors[0].$message }}
                                </span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="Full name" density="comfortable" v-model="item.fullname"
                                    variant="underlined">
                                </v-text-field>
                                <span v-if="v$.username.$error" style="color: red">
                                    {{ v$.username.$errors[0].$message }}
                                </span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="email" density="comfortable" v-model="item.email"
                                    variant="underlined">
                                </v-text-field>
                                <span v-if="v$.email.$error" style="color: red">
                                    {{ v$.email.$errors[0].$message }}
                                </span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="mobile" density="comfortable" v-model="item.mobile"
                                    variant="underlined">
                                </v-text-field>
                                <span v-if="v$.mobile.$error" style="color: red">
                                    {{ v$.mobile.$errors[0].$message }}
                                </span>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="Password" density="comfortable" v-model="password"
                                    variant="underlined">
                                </v-text-field>
                                <!-- <span v-if="v$.password.$error" style="color: red">
                                    {{ v$.password.$errors[0].$message }}
                                </span> -->
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field color="#9c27b0" label="Confirm password" density="comfortable"
                                    v-model="confirmPass" variant="underlined">
                                </v-text-field>
                                  <!-- <span v-if="v$.confirmPass.$error" style="color: red">
                                    {{ v$.confirmPass.$errors[0].$message }}
                                </span> -->
                                <span v-if="confirmError" style="color: red"> {{ confirmError }} </span>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select :items="userTypes" item-title="name" item-value="id" v-model="item.staffTypeId"
                                    label="User Type" variant="underlined"></v-select>
                                <span v-if="confirmError" style="color: red"> {{ confirmError }} </span>
                                <span v-if="v$.selUserTypes.$error" style="color: red">
                                    {{ v$.selUserTypes.$errors[0].$message }}
                                </span>
                                
                            </v-col>
                        </v-row>
                         <p style="color: red" v-if="errorValue"> {{ errorValue }} </p>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="addCat()">
                        save
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, numeric, decimal, maxLength, email } from "@vuelidate/validators";
        import { mapActions } from 'vuex'

export default {
    name: 'editAdmin',
   
    data() {
        return {
            v$: useVuelidate(),
            dialog: false,
            username: "",
            fullname:'',
            email: "",
            mobile: "",
            password: "",
            confirmPass: "",
            confirmError: "",
            errorValue:'',

            userTypes: [{ id: 1, name: 'Distribution' }, { id: 2, name: 'Support' }],
            selUserTypes: '',

        }
    },
    validations() {
        return {
            username: { required, maxLength: maxLength(30) },
            fullname: { required, maxLength: maxLength(30) },
            email: { required, email, maxLength: maxLength(40) },
            mobile: { required, numeric, maxLength: maxLength(12) },
            selUserTypes: { required },
            // password: { required },
            // confirmPass: { required },
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        },
        item:{
            type: Array
        }

    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        ...mapActions('appUser', ['ADD_APP_USER', 'GET_APP_USER_LIST', 'UPDATE_APP_USER']),

        addCat() {
            this.username = this.item.username;
            this.email = this.item.email;
            this.mobile = this.item.mobile;
            this.fullname = this.item.fullname
            this.selUserTypes = this.item.staffTypeId
            this.v$.$validate();
            if (!this.v$.$error) {
                if (this.password) {
                    if(this.password != this.confirmPass) return this.confirmError = 'password doesnot match'
                    const payload = {
                        id: this.item.id,
                        username: this.item.username,
                        fullname: this.item.fullname,
                        email: this.item.email,
                        mobile: this.item.mobile,
                        password: this.password,
                        type: this.selUserTypes
                    }
                 
                    this.confirmError = ''
                    // console.log(payload)
                    // this.$emit('save', payload);
                     this.UPDATE_APP_USER(payload).then(()=>{
                        this.errorValue = '';
                        this.show = false;
                        this.GET_APP_USER_LIST();
                     }).catch((err) => {
                        this.errorValue = err.response.data.message.errors[0].message
                        
                        // this.errorValue = err.response.data.message;
                    })
                } else {
                    const payload = {
                        id: this.item.id,
                        fullname: this.item.fullname,
                        username: this.item.username,
                        email: this.item.email,
                        mobile: this.item.mobile,
                        type: this.selUserTypes
               
                    }
                    // console.log(payload)
                    //    this.$emit('save', payload);
                    this.UPDATE_APP_USER(payload).then(()=>{
                         this.errorValue = '';
                        this.show = false;
                        this.GET_APP_USER_LIST();

                     }).catch((err) => {
                        this.errorValue = err.response.data.message.errors[0].message

                        // this.errorValue = err.response.data.message;
                    })
                }

            }


        }
    },
    mounted() {
        
    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>