import { createRouter, createWebHistory } from 'vue-router'


import HomeView from '../views/HomeView.vue';
import dashboard from '../views/dashboard.vue';
// import category from '../views/categories/category.vue';
// import subcat from '../views/categories/subCategories.vue';
// import subEquipment from '../views/categories/equipment.vue';
// import equipment from '../views/equipments/equipment.vue';
// import saleEquip from '../views/equipments/saleEquipInner.vue';
// import client from '../views/client/client.vue';
// import clientInnerPage from '../views/client/clientInnerPage.vue';
import admin from '../views/users/admin.vue';
import app_user from '../views/users/appUser.vue';
import members from '../views/distribution/members/members.vue';
import membersInner from '../views/distribution/members/membersInner.vue';
// import serviceInner from '../views/services/serviceInner.vue';
import distributionLog from '../views/distribution/distributionLog/distributionLog.vue';
import distribution_log_inner_page from '../views/distribution/distributionLog/distributionLogInnerPage.vue';
import eventsLog from '../views/distribution/EventLogs/eventsLog.vue';
// import preventiveReminders from '../views/reminders/preventiveReminders.vue';
// import calibrationReminders from '../views/reminders/calibrationReminders.vue';
import extras from '../views/extras/extras.vue';
import event from '../views/event/event.vue';
import borough from '../views/borough/borough.vue';
import university from '../views/university/university.vue';
import signIn from '../views/signIn.vue';
import addMember from '../views/distribution/addMemberPageView.vue';
import memberReport from '@/views/reports/memberReport.vue'
import distributionReport from '@/views/reports/distributionReport.vue' ;
import support from '@/views/support/support.vue';
import supportInner from '@/views/support/supportInner.vue';
import version from '@/views/version/versionCheck.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: 'dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dash',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: dashboard
      },
      {
        path: 'support',
        name: 'support',
        component: support
      },
      {
        path: 'support/:spId',
        name: 'supportInner',
        component: supportInner
      },
      // {
      //   path: 'category',
      //   name: 'category',
      //   component: category
      // },
      // {
      //   path: 'category/:cat_name',
      //   name: 'subCat',
      //   component: subcat,

      // },
      // {
      //   path:'category/:cat_name/:sub_name/:id',
      //   name: 'subEquipment',
      //   component: subEquipment,
      // },
      // {
      //   path: 'equipments',
      //   name: 'equipment',
      //   component: equipment,
      // },
      // {
      //   path: 'equipments/:id',
      //   name: 'saleEquip',
      //   component: saleEquip
      // },
      // {
      //   path: 'client',
      //   name: 'client',
      //   component: client,
      // },
      // {
      //   path: 'client/:id',
      //   name: 'clientInnerPage',
      //   component: clientInnerPage
      // },
      {
        path: 'admin',
        name: 'admin',
        component: admin,
      },
      {
        path: 'app_user',
        name: 'app_user',
        component: app_user
      },
      {
        path: 'distribution/members',
        name: 'members',
        component: members
      },
      {
        path: 'distribution/members/addMember',
         name: 'addmembers',
        component: addMember
      },
      {
        path: 'distribution/members/:memberId',
        name: 'membersInner',
        component: membersInner
      },
      {
        path: 'distribution/distribution_log',
        name: 'distribution_log',
        component: distributionLog
      },
      {
        path: 'distribution/distribution_log/:id',
        name: 'distribution_log_inner_page',
        component: distribution_log_inner_page
      },
      {
        path: 'distribution/event_log',
        name: 'event_log',
        component: eventsLog
      },
      {
        path: 'reports/members',
        name: 'memberReport',
        component: memberReport
      },
      {
        path: 'reports/distributions',
        name: 'distributionReport',
        component: distributionReport
      },

      {
        path: 'settings/Extras',
        name: 'extras',
        component: extras
      },
      {
        path: 'settings/events',
        name: 'event',
        component: event
      },
      {
        path: 'settings/borough',
        name: 'borough',
        component: borough
      },
      {
        path: 'settings/university',
        name: 'university',
        component: university
      },
      {
        path: 'settings/version',
        name: 'version',
        component: version
      },
    ]
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: signIn
  },


]
const history = createWebHistory();
const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history,
  routes
})

export default router
