import axios from 'axios'
export default {
    namespaced: true,
    state: {
        appUserList: {},
        appUserTotalPage: '',
        appUserWithId: {},
        //  editServicetValue:{}
    },
    mutations: {
        SET_APP_USER_LIST( state, data){
            state.appUserList = data
        },
        SET_APP_USER_TOTAL_PAGE( state, data ){
            state.appUserTotalPage = data
        },
        SET_APP_USER_WITH_ID( state, data){
            state.appUserWithId = data
        }
      
    },
    actions: {
        async GET_APP_USER_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}list_of_staffs`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options)
             commit('SET_APP_USER_LIST', data.data.data)
            //  console.log(data.data)
             commit('SET_APP_USER_TOTAL_PAGE', data.data.totalPages)
        },
        async ADD_APP_USER({ commit }, payload){
                
                const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const  addUser = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}staff/sign_up`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
                })
            //  console.log(addUser);

           
            

        },
        async GET_APP_USER_WITH_ID({ commit }, payload){
                // console.log(payload)
                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const  getUser = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}list_of_staffs`,
                    params: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
                })
                commit('SET_APP_USER_WITH_ID', getUser.data.data[0] )
            //  console.log(getUser.data.data[0]);

           
            

        },
      
        async DELETE_USER({ commit }, payload){
            // console.log(payload)
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                const deleteBorough = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}staffs/delete_staff/${ payload.id }`,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                // console.log(deleteBorough)

            } catch (error) {
                console.log(error)
            }
        } ,
       
        async UPDATE_APP_USER({ commit }, payload){
           
                 const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}staffs/update_staffs`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                //  console.log(update)

            
        },
       
    },
    getters: {
        getClientById:(state) => (id) =>{
            return state.clientList.find( list => list.id === id )
        } 
        
    }

}