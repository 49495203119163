<template>
    <v-container class="px-10" fluid style="height:100vh; background:#e7f0f7 ;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            Extras
        </div>
        <searchAndFilterToolbar :btn_text="cl_text" @btn_action="dialog = true" :filButton="false" />
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <th class="text-left">
                        #
                    </th>
                    <th class="text-left">
                        NAME
                    </th>
                    <th class="text-left">
                        DISTRIBUTION CYCLE
                    </th>
                    <th class="text-left">
                        MEMBER TYPE
                    </th>
                    <th class="text-left">
                        STATUS
                    </th>
                    <th class="text-left">
                        ACTIONS
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in extrasList" :key="item.name">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.extrasname }}</td>
                    <td>{{ item.extrasType.type }}</td>
                    <td>{{ item.membersRole.type }}</td>
                    <td class="">
                        <v-chip color="green" v-if="item.extrasStatusId == 1"
                            style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
                                    item.extrasStatus.status
                            }}</span>
                            <v-menu activator="parent">
                                <v-list class="pa-0">
                                    <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                       :class="{ active: it.id == 1, inactive: it.id == 2 }"
                                        :key="it">
                                        <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-chip>
                        <v-chip color="#fd5e00" v-else-if="item.extrasStatusId == 2"
                            style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
                                    item.extrasStatus.status
                            }}</span>
                            <v-menu activator="parent">
                                 <!-- :class="{ Open: it.name === 'Active', Accepted: it.name === 'In-Active' }" -->
                                <v-list class="pa-0">
                                    <v-list-item @click="changeStatus(it, item)" v-for="it in statuses"
                                       :class="{ active: it.id == 1, inactive: it.id == 2 }"
                                        :key="it">
                                        <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                    </v-list-item>

                                </v-list>
                            </v-menu>
                        </v-chip>
                    </td>
                    <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                    </td>
                </tr>
            </tbody>
        </v-table>
        <addExtras :visible="dialog" @close="dialog = false" @save="AddExtra" :title="dialog_title" />
        <editExtras :visible="edit_dialog" @close="edit_dialog = false" @save="editExtra" :title="edit_dialog_title" :val="edit_value" :name="edit_value.extrasname" />
        <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false"  :item="deleteValue" @delete="deleteExtra" />
        <pagination :length="extrasTotalPage" @chanegePage="chanegePage" />


    </v-container>
</template>

<script>
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import deleteWarnVue from '@/components/deleteWarn.vue';
import addExtras from '@/components/extras/addExtras.vue';
import editExtras from '@/components/extras/editExtras.vue';
import pagination from '@/components/pagination.vue';
import { mapActions, mapState } from 'vuex'
export default {
    name: 'city',
    components: {
        searchAndFilterToolbar,
        addExtras,
        deleteWarnVue,
        editExtras,
        pagination
    },
    data() {
        return {
            cl_text: 'Add Extra',
            dialog: false,
            edit_dialog: false,
            edit_value: '',
            edit_id: '',
            edit_dialog_title: 'Edit Extra',
            dialog_title: 'Add Extra',
            delete_dialog: false,
            deleteValue:{},
            statuses: [
                {
                    id: 1,
                    name: 'Active'
                },
                {
                    id: 2,
                    name: 'In-Active'
                },
            ],
            extra:[
                {
                    id: 1,
                    name: 'extra 1',
                    dist: 'Weekily'
                },
                {
                    id: 2,
                    name: 'extra 2',
                    dist: 'Monthly'
                },
            ]
           
        }
    },
    computed:{
        ...mapState('extras',['extrasList', 'extrasTotalPage'])
    },
    methods:{
        ...mapActions('extras',['GET_EXTRA_LIST', 'ADD_EXTRAS', 'UPDATE_EXTRAS', 'DELETE_EXTRA']),
        async AddExtra(payload){
           await this.ADD_EXTRAS(payload);
           this.dialog = false;
           await this.GET_EXTRA_LIST();
        },
        openDeleteDialog(item){
            this.deleteValue = item;
            this.delete_dialog = true;
        },
        async deleteExtra(payload){
         await this.DELETE_EXTRA(payload);
         this.delete_dialog = false;
         await this.GET_EXTRA_LIST();
        },
        openEditDialog(item){
            this.edit_value = item;
            console.log(item)
            this.edit_dialog = true;
        },
        async editExtra(item){
     
           await this.UPDATE_EXTRAS(item);
           this.edit_dialog = false;
           await this.GET_EXTRA_LIST();
            
        },
        async changeStatus(stat, item){
            // console.log({'stat': stat, 'item': item } )
            const payload = {
                extrasStatusId: stat.id,
                id: item.id
            }
            // console.log(payload)
           await this.UPDATE_EXTRAS(payload);
           await this.GET_EXTRA_LIST();
        },
        chanegePage(val){
            this.GET_EXTRA_LIST({ page: val })
        }
    },
    mounted(){
        this.GET_EXTRA_LIST()
    }
}
</script>

<style scoped>
.active{
    background: rgb(223 235 223);
    color: rgb(117 181 124);
}
.inactive{
     background: rgb(255 236 225);
    color: rgb(253 151 70)
}
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.v-table .v-table__wrapper>table>tbody>tr td {
    border-bottom: none;
    font-size: small;
    font-weight: 500;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    border-bottom: none;
}

.v-table {
    font-family: Montserrat, sans-serif !important;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    font-weight: 600 !important;
}
</style>