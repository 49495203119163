<template>
    <v-container class="px-10 " fluid style="height:100vh; background:#e7f0f7 ;">
        <v-icon @click="routerBack()">mdi-arrow-left</v-icon>
        <v-card class=" mt-3 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <!-- <v-card-title class="text-h5">
                Distribution Details
            </v-card-title> -->
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap">
                                <span class="detval"> Name: {{ supportWithId.fullname }} </span>
                            </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Email: </span> {{ supportWithId.email }} </span>
                        </v-card-title>
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span> <span class="detval"> Mobile Number: </span> {{ supportWithId.mobile }} </span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span v-if="supportWithId.createdAt"> <span class="detval"> Date: </span> {{
                            supportWithId.createdAt.split('T')[0] }} </span>
                        </v-card-title>
                        <!-- <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap"> <span class="detval"> Year: </span> {{
                                destributionLogWithId.year
                            }} </span>
                        </v-card-title> -->
                        <!-- <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span class="cap"> <span class="detval"> Collection Date:
                                </span>  </span>
                        </v-card-title> -->
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1" style="line-height:1.5rem">
                            <span v-if="supportWithId.supportStatus"> <span class="detval"> Status </span> {{
                            supportWithId.supportStatus.statustype }} </span>
                        </v-card-title>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-4 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Employment Type : </span> 
                            <span v-if="supportWithId.type"> Full Time Employee </span>
                            <span v-else> Part Time Employee </span>
                            </span>
                        </v-card-title>
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> First Language : </span> {{ supportWithId.firstLanguage }}
                            </span>
                        </v-card-title>
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> English : </span> {{ supportWithId.english }}
                            </span>
                        </v-card-title>
                    </v-col>
                    <!-- <v-col cols="12" md="4">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Full time employee : </span> {{ supportWithId.fulltimeEmployment }}
                            </span>
                        </v-card-title>
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Part time paid employee : </span> {{ supportWithId.partEmployment }}
                            </span>
                        </v-card-title>
                    </v-col> -->
                    <v-col cols="12" md="8">
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Accommodation : </span> {{ supportWithId.accommodation }}
                            </span>
                        </v-card-title>
                        <v-card-title class="pa-1 " style="line-height:1.5rem">
                            <span> <span class="detval"> Immigration Status : </span> {{ supportWithId.immigrationStatus }}
                            </span>
                        </v-card-title>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="mt-4 rounded-lg" style="font-family: 'Montserrat', sans-serif;">
            <v-card-text>
                <v-card-title class="pa-1 " style="line-height:1.5rem">
                    <span> <span class="detval"> Subject: </span> {{ supportWithId.subject }} </span>
                </v-card-title>
                <v-card-title class="pa-1 " style="line-height:1.5rem">
                    <span> <span class="detval"> Description: </span> {{ supportWithId.description }} </span>
                </v-card-title>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment'
export default {
    name: 'supportInner',
    computed: {
        ...mapState('support', ['supportWithId']),
    },
    methods: {
        ...mapActions('support', ['GET_SUPPORT_WITH_ID']),

        routerBack() {
            this.$router.back()
        },
        NumToMonth(num) {

            return moment(num, 'M').format('MMMM')
        }

    },
    mounted() {
        console.log(this.$route.params)
        const payload = {
            id: this.$route.params.spId
        }
        this.GET_SUPPORT_WITH_ID(payload)

        // console.log(payload);
        // this.GET_DISTRIBUTION_LOG_BY_ID(payload)
    }
} 
</script>

<style scoped>
.details {
    font-size: large;
    font-weight: 500;
    margin-top: 19px;
    border: 1px solid #bbbfee;
    padding: 23px;
}

.detval {
    font-weight: 600;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

.cap {
    text-transform: capitalize;
}
</style>