<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-bottom-transition" activator="parent">
            <v-card width="800">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">{{ title }}</div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field color="#9c27b0" hide-details="" label="Member ID" density="comfortable" v-model="id"
                                    variant="outlined">
                                </v-text-field>
                                <span v-if="v$.id.$error" style="color: red">
                                    {{ v$.id.$errors[0].$message }}
                                </span>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete variant="outlined" hide-details density="comfortable" v-model="extrasID" multiple
                                    dense @click="CheckUserColl()" clearable :items="extrasList" item-title="extrasname"
                                    item-value="id" label="Extrass"></v-autocomplete>
                                <span v-if="v$.extrasID.$error" style="color: red">
                                    {{ v$.extrasID.$errors[0].$message }}
                                </span>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete v-if="appUserList" v-model="staffId" hide-details variant="outlined"
                                    density="comfortable" :items="appUserList" item-title="fullname" clearable
                                    item-value="id" label="Staff">
                                </v-autocomplete>
                                <span v-if="v$.staffId.$error" style="color: red">
                                    {{ v$.staffId.$errors[0].$message }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-textarea variant="outlined" hide-details label="Remarks" v-model="remarks" auto-grow
                                outlined rows="2" row-height="25" shaped>
                            </v-textarea>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="addDistribution()">
                        save
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />

</template>
<script>
import { mapState, mapActions } from 'vuex';
import snackbarVue from '@/components/snackbar';
import axios from 'axios';
import useVuelidate from "@vuelidate/core";
import { helpers, required, numeric, decimal, maxLength, email } from "@vuelidate/validators";


export default {
    name: 'addDistribution',
    components: {
        snackbarVue
    },
    data() {
        return {
            v$: useVuelidate(),


            dialog: false,
            id: '',
            extrasID: [],
            snackbar: false,
            snackColor: '',
            mssg: '',
            extrasLists: [
                {
                    id: 0,
                    extrasname: ''

                }
            ],
            remarks: '',
            staffId: ''
        }
    },
    validations() {
        return {
            id: { required, numeric },
            extrasID: { required },
            staffId: { required },
            // name: { required },
            // email: { required, email },
            // mobile: { required, numeric, maxLength: maxLength(12) },
            // selSub: { required },
            // desc: { required },
        }
    },
    watch: {
        extrasID(newVl, oldVl) {
            if (newVl.length > 0) {
                this.checkExtra(newVl[newVl.length - 1])
            }
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        }

    },
    computed: {
        ...mapState('extras', ['extrasList']),
        ...mapState('destributionLog', ['userCollection']),
        ...mapState('appUser', ['appUserList', 'appUserWithId', 'appUserTotalPage']),

        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        ...mapActions('extras', ['GET_EXTRA_LIST']),
        ...mapActions('destributionLog', ['GET_DESTRIBUTION_LOG_LIST', 'CHECK_USER_COLLECTIONS', 'EXTRAS_CHECK', 'ADD_DISTRIBUTION']),

        async addDistribution() {
            this.v$.$validate();
            // console.log(this.v$)
            if (!this.v$.$error) {
                const payload = {
                    memberId: this.id,
                    extraId: this.extrasID[0],
                    collectionLog: this.extrasID,
                    remarks: this.remarks,
                    staffId: this.staffId
                }
                const payload2 = {
                    mid: this.id
                }
                await this.CHECK_USER_COLLECTIONS(payload2).then(() => {
                    // console.log(this.userCollection)
                    // if (this.userCollection.data != null) {
                    //     query.role = this.userCollection.data.membersRoleId
                    // }
                    if (this.userCollection.response == "failed" || this.userCollection.response == "error") {
                        this.snackColor = 'error'
                        this.mssg = this.userCollection.message
                        return this.snackbar = true
                    } else {
                        this.ADD_DISTRIBUTION(payload).then(() => {
                            this.show = false;
                            this.GET_DESTRIBUTION_LOG_LIST();
                            this.id = '';
                            this.extrasID = [],
                                this.remarks = '',
                                this.staffId = '';
                            this.mssg = 'Distribution Added Successfully';
                            this.snackColor = 'success';
                            this.snackbar = true;
                            this.v$.$reset();
                        }).catch(err=>{
                            console.log(err)
                        })
                    }
                }).catch(err => {
                    console.log(err)
                })
            }

            // this.$emit('save', payload);
            // this.name = '';
            // this.image = '';
        },
        async CheckUserColl() {
            if (this.id) {
                const payload = {
                    mid: this.id
                }
                const query = {
                    role: ''
                }
                await this.CHECK_USER_COLLECTIONS(payload).then(async () => {
                    // console.log(this.userCollection)
                    if (this.userCollection.data != null) {
                        query.role = this.userCollection.data.membersRoleId
                    }
                    if (this.userCollection.response == "failed" || this.userCollection.response == "error") {
                        this.snackColor = 'error'
                        this.mssg = this.userCollection.message
                        return this.snackbar = true
                    } else {
                        await this.GET_EXTRA_LIST(query).then(() => {
                            this.extrasLists.push(this.extrasList)
                        })
                    }
                })


            }
        },
        async checkExtra(ext) {
            // console.log(ext)
            const payload = {
                extra: ext,
                mid: this.id
            }
            // this.EXTRAS_CHECK(payload)
            const auth = JSON.parse(localStorage.getItem('user'));
            const extrasCheck = await axios({
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}extras/check_extras`,
                params: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                },

            })
            // console.log(extrasCheck.data.response)
            if (extrasCheck.data.response == 'failed') {
                this.extrasID.pop()
                this.snackColor = 'error'
                this.mssg = extrasCheck.data.message
                return this.snackbar = true

            }
        }
    },
    created() {
        this.name = '';
        this.image = '';
        this.modelNo = ''
    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
}
</style>