import axios from 'axios'
export default {
    namespaced: true,
    state: {
        versionData:{}
        //  editServicetValue:{}
    },
    mutations: {
        SET_VERSION( state, data){
            state.versionData = data
        },
      
    },
    actions: {
        async GET_VERSION({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}version`,
                // params:{
                //     size: 15
                // },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            // if(query){
            //     options.params = query
            // }
            const data = await axios(options)
            // console.log(data.data)
             commit('SET_VERSION', data.data)

        },
        async UPDATE_VERSION({ commit }, payload){
            const auth = JSON.parse(localStorage.getItem('user'));
            const data = await axios({
                method: 'post',
                url:  `${process.env.VUE_APP_BASE_URL}update_version/ ${ payload.id }`,
                data: payload,
                // params:{
                //     id: payload.id
                // },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }

            })
            // console.log(data)
        }
        
       
    },
    getters: {
       
        
    }

}