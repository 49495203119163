import axios from 'axios'
export default {
    namespaced: true,
    state: {
        eventLogList: {},
        eventLogTotalPage: ''
        //  editServicetValue:{}
    },
    mutations: {
        SET_EVENT_LOG_LIST( state, data){
            state.eventLogList = data
        },
        SET_EVENT_LOG_TOTAL_PAGE( state, data ){
            state.eventLogTotalPage = data
        }
      
    },
    actions: {
        async GET_EVENT_LOG_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}distribution/list_of_eventslog`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options);
             commit('SET_EVENT_LOG_LIST', data.data.data);
            //  console.log(data);
             commit('SET_EVENT_LOG_TOTAL_PAGE', data.data.totalPages);
        },
        async DELETE_EVENT_LOG({ commit, state }, payload) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}/distribution/delete_eventslog/ ${ payload.id }`,
               
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
        
            const data = await axios(options);
            //  console.log(data);
             
        },
        async UPDATE_EVENT_LOG({ commit, state }, payload) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'post',
                url: `${process.env.VUE_APP_BASE_URL}/distribution/update_eventslog`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
        
            const data = await axios(options);
            //  console.log(data);
        },

        

       
    },
    getters: {
    
        
    }

}