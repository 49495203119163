<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-top-transition" activator="parent">
            <v-card :width="width">
                <v-card-title>{{ title }}</v-card-title>
                <!-- <v-card-title> Are You Sure ?  </v-card-title> -->
                
                <v-card-title>   {{ mssg }} </v-card-title>
                <!-- <v-card-title v-if="!log"> Do you want to delete  {{item.categoryName}} </v-card-title> -->
               
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);"  @click="deletethis()">
                        yes
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name:'deletWarn',
    data() {
        return {
            dialog: false,
            name: '',
            image: '',
            modelNo: ''
        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        item:{
            type: Array
        },
        equipment:{
            type: Boolean,
            default: false
        },
        log:{
            type: Boolean,
            default: false
        },
        mssg:{
            type: String,
            default: "Do you want to delete"
        },
        title:{
            type: String,
            default: "Are You Sure ?"
        },
        width:{
            type: String,
            default: "400"
        },

    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods:{
        deletethis(){  
            this.$emit( 'delete', this.item);   
        }
    },
    created(){
        this.name = '';
        this.image = '';
        this.modelNo = ''
    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}
</style>