<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-bottom-transition" activator="parent" scrollable>

            <v-card class="mx-auto">
                <v-window v-model="step">
                    <v-window-item :value="1">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Personal Details
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="2">
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="titles" dense clearable :items="Title" item item-title="name"
                                        item-value="id" label="Title">
                                    </v-autocomplete>
                                    <span v-if="v$.titles.$error" style="color: red">
                                        {{ v$.titles.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field color="#9c27b0" hide-details label="First Name" density="comfortable"
                                        v-model="firstName" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.firstName.$error" style="color: red">
                                        {{ v$.firstName.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field color="#9c27b0" hide-details label="Last Name" density="comfortable"
                                        v-model="secondName" variant="outlined">
                                    </v-text-field>

                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field color="#9c27b0" hide-details label="Phone" density="comfortable"
                                        v-model="phone" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.phone.$error" style="color: red">
                                        {{ v$.phone.$errors[0].$message }}
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field color="#9c27b0" hide-details label="Email" density="comfortable"
                                        v-model="email" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.email.$error" style="color: red">
                                        {{ v$.email.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete hide-details variant="outlined" density="comfortable" v-model="type"
                                        clearable :items="Role" item-title="type" item-value="id" label="Type">
                                    </v-autocomplete>
                                    <span v-if="v$.type.$error" style="color: red">
                                        {{ v$.type.$errors[0].$message }}
                                    </span>
                                </v-col>

                                <!-- <v-col cols="12" md="3" v-if="type == 3">
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="NoKids" clearable :items="CHnumber" label="Number Of kids">
                                    </v-autocomplete>
                                </v-col> -->
                                <!-- <v-row class="" > -->
                                <v-col cols="12" md="6" v-if="type != 3">
                                    <v-autocomplete variant="outlined" density="comfortable" hide-details
                                        v-model="heveDependent" clearable :items="isDependent" item-title="title"
                                        item-value="val" label="Does the Member have dependent-living in UK ?">
                                    </v-autocomplete>
                                    <!-- <span v-if="v$.NoKids.$error" style="color: red">
                                         {{ v$.NoKids.$errors[0].$message }}
                                        </span> -->
                                </v-col>
                                <v-col cols="12" md="6" v-if="type != 3">
                                    <v-autocomplete variant="outlined" density="comfortable" hide-details
                                        v-model="NoKids" clearable :items="CHnumber"
                                        label="Number Of kids Living with Member in UK">
                                    </v-autocomplete>
                                    <span v-if="v$.NoKids.$error" style="color: red">
                                        {{ v$.NoKids.$errors[0].$message }}
                                    </span>
                                </v-col>

                                <!-- </v-row> -->
                                <v-row v-if="NoKids == 'Expected'">
                                    <v-col cols="12" md="12">
                                        <div class="date d-flex pl-3" style="">
                                            <p class="mr" style="color:#7d7d86;">Expected Date(Aprox):</p>
                                            <input type="date" v-model="ExpectedDate" />
                                        </div>
                                        <span v-if="v$.ExpectedDate.$error" style="color: red">
                                            {{ v$.ExpectedDate.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-row>
                            <div v-if="heveDependent">
                                <v-row class="">
                                    <v-col cols="12" md="4">
                                        <v-text-field label="Dependent Name" hide-details density="comfortable"
                                            v-model="dependentName" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.dependentName.$error" style="color: red">
                                            {{ v$.dependentName.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                    <!-- </v-row>
                                    <v-row class=""> -->
                                    <v-col cols="12" md="4">
                                        <v-text-field label="Dependent Phone" hide-details density="comfortable"
                                            v-model="dependentPhone" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.dependentPhone.$error" style="color: red">
                                            {{ v$.dependentPhone.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                    <!-- </v-row>
                                    <v-row class=""> -->
                                    <v-col cols="12" md="4">
                                        <v-text-field label="Dependent Email" hide-details density="comfortable"
                                            v-model="dependentemail" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.dependentemail.$error" style="color: red">
                                            {{ v$.dependentemail.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                            <div v-if="type == 3">
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field color="#9c27b0" hide-details label="Registered Student Email"
                                            density="comfortable" v-model="DepEmail" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.DepEmail.$error" style="color: red">
                                            {{ v$.DepEmail.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field color="#9c27b0" hide-details label="Registered Student Phone"
                                            density="comfortable" v-model="DepPhone" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.DepPhone.$error" style="color: red">
                                            {{ v$.DepPhone.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="py-4">OR</v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field color="#9c27b0" hide-details label="Student ID"
                                            density="comfortable" v-model="DepId" variant="outlined">
                                        </v-text-field>
                                        <span v-if="v$.DepId.$error" style="color: red">
                                            {{ v$.DepId.$errors[0].$message }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Contact Details
                            <p style="font-size: 17px; color: red;">{{ grp_err_text }}</p>

                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <!-- <v-autocomplete hide-details variant="outlined" density="comfortable" v-model="area"
                                        clearable :items="areas" item-title="name" item-value="id" label="Area">
                                    </v-autocomplete>
                                    <span v-if="v$.area.$error" style="color: red">
                                        {{ v$.area.$errors[0].$message }}
                                    </span> -->
                                    <v-autocomplete clearable hide-details label="Post Code " density="comfortable"
                                        v-model="zib" v-model:search="search" :items="postCodeList" variant="outlined">
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    Search for
                                                    <strong>Post Code</strong>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>

                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-autocomplete label="Address" hide-details clearable aria-disabled=""
                                        density="comfortable" v-model="address" :items="addressList" item-title="line_1"
                                        variant="outlined">
                                    </v-autocomplete>
                                    <span v-if="v$.address.$error" style="color: red">
                                        {{ v$.address.$errors[0].$message }}
                                    </span>
                                    <!-- <v-text-field color="#9c27b0" hide-details label="County" density="comfortable"
                                        v-model="country" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.country.$error" style="color: red">
                                        {{ v$.country.$errors[0].$message }}
                                    </span> -->
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-autocomplete label="Building No" hide-details clearable aria-disabled=""
                                        density="comfortable" v-model="country" :items="buildingList"
                                        variant="outlined">
                                    </v-autocomplete>
                                    <span v-if="v$.country.$error" style="color: red">
                                        {{ v$.country.$errors[0].$message }}
                                    </span>
                                    <!-- <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="borough" clearable :items="boroughList" item-title="name"
                                        item-value="id" label="Borough">
                                    </v-autocomplete>
                                    <span v-if="v$.borough.$error" style="color: red">
                                        {{ v$.borough.$errors[0].$message }}
                                    </span> -->
                                </v-col>
                                <v-col cols="12" md="4" v-if="borough">
                                    <v-text-field label="Borough" hide-details disabled density="comfortable"
                                        :model-value="borough" variant="outlined">
                                    </v-text-field>
                                    <!-- <v-text-field color="#9c27b0" hide-details label="Zip" density="comfortable"
                                        v-model="zib" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.zib.$error" style="color: red">
                                        {{ v$.zib.$errors[0].$message }}
                                    </span> -->

                                </v-col>
                                <v-col cols="12" md="6">
                                    <!-- <v-textarea variant="outlined" hide-details label="Address" v-model="address"
                                        auto-grow outlined rows="2" row-height="25" shaped>
                                    </v-textarea>
                                    <span v-if="v$.address.$error" style="color: red">
                                        {{ v$.address.$errors[0].$message }}
                                    </span> -->
                                </v-col>

                            </v-row>
                        </v-card-text>
                    </v-window-item>

                    <v-window-item :value="3">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Education Details
                        </v-card-title>
                        <v-card-text>
                            <v-row>

                                <v-col cols="12" md="4">
                                    <v-text-field color="#9c27b0" hide-details label="Student ID" density="comfortable"
                                        v-model="studentId" variant="outlined">
                                    </v-text-field>
                                    <span v-if="v$.studentId.$error" style="color: red">
                                        {{ v$.studentId.$errors[0].$message }}
                                    </span>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <!-- UNIVERSITY -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="university" clearable :items="universityLis" item-title="name"
                                        item-value="id" label="University">
                                    </v-autocomplete>
                                    <span v-if="v$.university.$error" style="color: red">
                                        {{ v$.university.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="date d-flex pt-3" style="">
                                        <p class="mr-2" style="color:#7d7d86;">Arrived date:</p>
                                        <input type="date" v-model="ArrivedDate" />
                                    </div>
                                    <span v-if="v$.ArrivedDate.$error" style="color: red">
                                        {{ v$.ArrivedDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="date d-flex" style="">
                                        <p class="mr-2 " style="color:#7d7d86;">Course Start Date:</p>
                                        <input type="date" v-model="startDate" />
                                    </div>
                                    <span v-if="v$.startDate.$error" style="color: red">
                                        {{ v$.startDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="date d-flex" style="">
                                        <p class="mr-2" style="color:#7d7d86;">Course End Date:</p>
                                        <input type="date" v-model="EndDate" />
                                    </div>
                                    <span v-if="v$.EndDate.$error" style="color: red">
                                        {{ v$.EndDate.$errors[0].$message }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="4">
                        <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            Food Details
                        </v-card-title>
                        <v-card-text>
                            <v-row>

                                <v-col cols="12" md="3">
                                    <!--  Food Details -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="deitryRequirment" clearable :items="dietReq" item-title="name"
                                        item-value="id" label="Dietary Requirements">
                                    </v-autocomplete>
                                    <span v-if="v$.deitryRequirment.$error" style="color: red">
                                        {{ v$.deitryRequirment.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="3">
                                    <!--  Food Details -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable" v-model="eggs"
                                        clearable :items="eggList" item-title="name" item-value="id"
                                        label="Do you eat EGGS?">
                                    </v-autocomplete>
                                    <span v-if="v$.eggs.$error" style="color: red">
                                        {{ v$.eggs.$errors[0].$message }}
                                    </span>

                                </v-col>
                                <v-col cols="12" md="6">
                                    <!--  Food Details -->
                                    <v-autocomplete hide-details variant="outlined" density="comfortable"
                                        v-model="collectionDay" clearable :items="prefCollection"
                                        label="Preferred Collection Day">
                                    </v-autocomplete>
                                    <span v-if="v$.collectionDay.$error" style="color: red">
                                        {{ v$.collectionDay.$errors[0].$message }}
                                    </span>

                                </v-col>

                            </v-row>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="5">
                        <v-card-title class="text-h5  justify-space-between text-center">
                            Kid's Details
                        </v-card-title>
                        <v-card-text v-if="!isNaN(this.NoKids)">

                            <v-row  class="mb-3" v-for="hh in parseInt(this.NoKids)" :key="hh">
                                <v-col cols="12" md="6" sm="6">
                                    <!-- <p>{{hh}}</p> -->
                                    <v-text-field :label="'Child Name' + ' ' + hh" density="compact" hide-details=""
                                        v-model="child[hh - 1].name" variant="underlined">
                                    </v-text-field>

                                </v-col>
                                <v-col cols="12" md="6" sm="6" style="align-self: center">
                                    <div class="date d-flex" style="">
                                        <p class="mr" style="color:#7d7d86;">DOB:</p>
                                        <input type="date" v-model="child[hh - 1].dob" />
                                    </div>


                                </v-col>
                            </v-row>
                            <span v-if="errValue" style="color: red">
                                {{ errValue }}
                            </span>
                        </v-card-text>

                    </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="step > 1" text @click="goBack()">
                        Back
                    </v-btn>

                    <v-btn v-if="step == 5" color="primary" depressed @click="nextwindow33()">
                        next
                    </v-btn>
                    <v-btn v-if="step < 4" color="primary" depressed @click="nextwindow()">
                        {{ nextbtnName }}
                    </v-btn>
                    <v-btn v-if="step == 4" color="primary" depressed @click="submitMemeber()">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <snackbarInitail :visible="snackbarIn" @close="snackbarIn = false" />
        <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />
        <dialogVue :visible="dialogBox" @close="dialogBox = false" @yes="dialogBox = false, step++" @no="noGroup" />


    </div>
</template>
<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import useVuelidate from "@vuelidate/core";
import { required, numeric, decimal, maxLength, email } from "@vuelidate/validators";
import snackbarVue from '@/components/snackbar';
import dialogVue from '@/components/dialog.vue'


export default {
    name: 'addmemeberVue',
    components: {
        snackbarVue,
        dialogVue
    },
    data() {
        return {
            v$: useVuelidate(),

            snackbar: false,
            snackColor: 'green',
            nextbtnName: 'Next',
            errValue: '',
            dialogBox: false,
            grp_err_text: null,

            step: 1,
            titles: '',
            firstName: '',
            secondName: '',
            phone: '',
            email: '',
            type: '',
            DependentName: null,
            NoKids: null,
            ExpectedDate: '',
            area: '',
            country: '',
            borough: '',
            address: '',
            zib: '',
            studentId: '',
            university: '',
            dialog: false,
            ArrivedDate: '',
            startDate: '',
            EndDate: '',

            DepEmail: '',
            DepPhone: '',
            DepId: '',

            deitryRequirment: '',
            eggs: '',
            collectionDay: '',
            Title: ['Mr', 'Mrs', 'Miss', 'Ms',],
            CHnumber: ['None', '1', '2', '3', '4', '5', 'Expected'],
            areas: [
                {
                    id: 0,
                    name: 'city',
                },
                {
                    id: 1,
                    name: 'Town',
                }
            ],
            dietReq: [
                {
                    id: 1,
                    name: 'Halal'
                },
                {
                    id: 2,
                    name: 'Non-Halal'
                },
                {
                    id: 3,
                    name: 'Vegiterian'
                },
            ],
            eggList: [
                {
                    id: 1,
                    name: 'Yes'
                },
                {
                    id: 0,
                    name: 'No'
                },
            ],
            Role: [
                {
                    id: 1,
                    type: 'Student'
                },
                {
                    id: 3,
                    type: 'Dependent'
                },
                // {
                //     id: 3,
                //     type: 'Student with Children'
                // },
                // family id : 2
                {
                    id: 4,
                    type: 'PSW'
                },
                {
                    id: 5,
                    type: 'TIER2'
                },
                {
                    id: 6,
                    type: 'Undocumented'
                },
                {
                    id: 7,
                    type: 'Benefits'
                },
            ],
            child: [],
            prefCollection: ['TUESDAY AFTERNOON (STRICTLY Ladies with Children ONLY', 'FRIDAY AFTERNOON (STRICTLY Ladies with Children ONLY)', 'SATURDAY EVENING', 'TUESDAY EVENING'],
            heveDependent: null,
            isDependent: [
                {
                    val: true,
                    title: 'Yes'
                },
                {
                    val: false,
                    title: 'No'
                }
            ],
            dependentName: null,
            dependentPhone: '',
            dependentemail: '',
            search: '',
            buildingList: [],
            formAddress: [],
            memberType: ''


        }
    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        }

    },
    validations() {
        return {
            titles: { required },
            firstName: { required },
            // secondName: { required },
            phone: { required, numeric, maxLength: maxLength(12) },
            email: { required, email },
            type: { required },
            zib: { required },
            // DependentName: { required },
            NoKids: { required },
            ExpectedDate: { required },
            area: { required },
            country: { required },
            borough: { required },
            address: { required },

            studentId: { required },
            university: { required },
            ArrivedDate: { required },
            startDate: { required },
            EndDate: { required },

            deitryRequirment: { required },
            eggs: { required },
            collectionDay: { required },

            DepEmail: { required, email },
            DepPhone: { required, numeric, maxLength: maxLength(12) },
            DepId: { required },

            dependentemail: { required, email },
            dependentPhone: { required, numeric, maxLength: maxLength(12) },
            dependentName: { required },

        }
    },
    watch: {
        type(newVl, oldVl) {
            if (newVl == 3) {
                this.nextbtnName = 'submit'
            } else {
                this.nextbtnName = 'next'

            }
            if (newVl) {
                const memType = this.Role.find(o => o.id == newVl)
                this.memberType = memType.type
            }

        },
        heveDependent(newV) {
            if (newV) {
                this.v$.dependentemail.$reset();
                this.v$.dependentPhone.$reset();
                this.v$.dependentName.$reset();
            }
        },
        search(val) {
            this.GET_POST_CODE_LIST(val);

        },
        zib(val) {
            this.address = ''
            this.GET_ADDRESS_LSIT(val)
            this.borough = this.boroughFromAddress
            // console.log(val)
        },
        address(val) {
            const add = this.$store.getters["form/getAddresByline_1"](val);
            // console.log(add)
            this.formAddress = add[0]
            // console.log(this.formAddress)
            this.buildingList = [];
            this.country = ''
            add.forEach(el => {
                this.buildingList.push(el.building_number)
                // console.log(this.buildingList)
            })
        },
        step(val) {
            if (val == 2) {
                this.grp_err_text = null
            }
        }
    },
    computed: {
        ...mapState('member', ['memberRole', 'memberEx', 'duplicateCheck']),
        ...mapState('borough', ['boroughList']),
        ...mapState('university', ['universityLis']),
        ...mapState('form', ['postCodeList', 'addressList', 'boroughFromAddress']),

        // ...mapState('priority', ['priorityList']),
        // ...mapState('workingCondition', ['conditionList']),
        // ...mapState('city', ['cityList']),
        // ...mapState('technician', ['techList']),
        // ...mapState('clients', ['clientList', 'editClientValue', 'editClientValue']),
        computed: {
            currentTitle() {
                switch (this.step) {
                    case 1: return 'Sign-up'
                    case 2: return 'Create a password'
                    default: return 'Account created'
                }
            },
        },
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        ...mapActions('form', ['GET_POST_CODE_LIST', 'GET_ADDRESS_LSIT']),
        ...mapActions('member', ['GET_MEMBER_LIST', 'GET_MEMBER_ROLE', 'ADD_MEMBER', 'GET_MEMBER_BY_ID', 'UPDATE_MEMBER', 'CHECK_MEMBER_EXIST', 'UPDATE_DEPENTENT', 'CHECK_DUPLICATE_PHONE_EMAIL']),
        nextwindow33() {
            // console.log(this.child);
            for (let i = 0; i < this.child.length; i++) {
                // console.log(this.child[i])
                if (this.child[i].name == '' || this.child[i].dob == '') {
                    // console.log('please Add All values')
                    return this.errValue = 'please Add All values';

                }
                this.errValue = ''
                this.step = 2
                this.Borough = this.boroughList;
                this.univer = this.universityLis;

            }
            // this.v$.child.$validate();
            // console.log( this.v$)

        },
        async nextwindow() {
            // console.log(this.v$)
            if (this.step == 1) {
                this.v$.titles.$validate();
                this.v$.firstName.$validate();
                this.v$.phone.$validate();
                this.v$.email.$validate();
                this.v$.type.$validate();
                //  this.v$.$validate();
                if (this.type == 3) {
                    // this.v$.DependentName.$validate();
                    // this.v$.DepEmail.$validate();
                    // this.v$.DepPhone.$validate();
                    // this.v$.DepId.$validate();
                    if (!this.DepEmail && !this.DepPhone && !this.DepId) {
                        this.mssg = 'Please Add Registered Student Details';
                        this.snackColor = 'error';
                        return this.snackbar = true
                    }
                    if (this.DepEmail || this.DepPhone) {
                        this.v$.DepEmail.$validate();
                        this.v$.DepPhone.$validate();
                    }
                    else {
                        this.v$.DepId.$validate();

                    }
                }
                if (this.NoKids == 'Expected') {
                    this.v$.ExpectedDate.$validate();
                }
                if (this.heveDependent) {
                    this.v$.dependentemail.$validate();
                    this.v$.dependentPhone.$validate();
                    this.v$.dependentName.$validate();

                }


                // console.log(this.v$)
                if (this.v$.$errors.length == 0) {
                    if (this.type == 3) {
                        const query = {
                            phne: this.DepPhone,
                            id: this.DepId,
                            email: this.DepEmail
                        }
                        await this.CHECK_MEMBER_EXIST(query).then(() => {
                            console.log(this.memberEx)
                            if (this.memberEx == null) {
                                this.snackColor = 'error';
                                this.mssg = 'No member found'
                                this.snackbar = true;
                            } else if (this.memberEx.dependentemail || this.memberEx.dependentphone) {
                                this.snackColor = 'error';
                                this.mssg = 'Member already has an Dependent'
                                this.snackbar = true;
                            } else {
                                const payload = {
                                    id: this.memberEx.id,
                                    dependentname: this.firstName + ' ' + this.secondName,
                                    dependentphone: this.DepPhone,
                                    dependentemail: this.DepEmail
                                }
                                this.UPDATE_DEPENTENT(payload).then(() => {
                                    this.titles = ''
                                    this.firstName = ''
                                    this.secondName = ''
                                    this.phone = ''
                                    this.email = ''
                                    this.DepPhone = ''
                                    this.DepEmail = ''
                                    this.DepId = ''
                                    this.snackColor = 'success';
                                    this.mssg = 'Registration completed successfully'
                                    this.snackbar = true;
                                    this.v$.$reset();
                                    this.show = false
                                })

                            }
                        }).catch(err => {
                            console.log(err)
                        })

                    }
                    else if (this.NoKids && !isNaN(this.NoKids)) {
                        this.v$.NoKids.$validate();

                        if (this.v$.$errors.length == 0) {

                            const checkDet = {
                                phone: this.phone,
                                email: this.email
                            }
                            this.CHECK_DUPLICATE_PHONE_EMAIL(checkDet).then(() => {
                                if (this.duplicateCheck.response == 'failed') {
                                    console.log(this.duplicateCheck)
                                    this.mssg = this.duplicateCheck.message;
                                    this.snackColor = 'error';
                                    return this.snackbar = true

                                } else {
                                    for (let i = 0; i < parseInt(this.NoKids); i++) {
                                        const tests = {
                                            name: '',
                                            dob: ''
                                        }
                                        this.child.push(tests);

                                    }
                                    // console.log(this.child)
                                    this.step = 5
                                }
                            })


                        }

                    }
                    else {
                        const checkDet = {
                            phone: this.phone,
                            email: this.email
                        }
                        this.CHECK_DUPLICATE_PHONE_EMAIL(checkDet).then(() => {
                            if (this.duplicateCheck.response == 'failed') {
                                this.mssg = this.duplicateCheck.message;
                                this.snackColor = 'error';
                                return this.snackbar = true

                            } else {
                                this.step++

                                this.Borough = this.boroughList;
                                this.univer = this.universityLis;
                            }
                        })
                        // this.step++
                        // this.Borough = this.boroughList;
                        // this.univer = this.universityLis;
                    }

                }


            } else if (this.step == 2) {
                // this.v$.area.$validate();
                // this.v$.country.$validate();
                // this.v$.borough.$validate();
                this.v$.address.$validate();
                this.v$.zib.$validate();
                if (this.v$.$errors.length == 0) {
                    // this.step++
                    if (this.type != 1) {
                        this.studentId = null
                        this.university = null;
                        this.ArrivedDate = null;
                        this.startDate = null;
                        this.EndDate = null;
                        return this.step = 4
                    }
                    if (this.heveDependent || this.NoKids && !isNaN(this.NoKids) || this.ExpectedDate) return this.step++
                    const groupCheck = await axios({
                        method: 'get',
                        url: `${process.env.VUE_APP_BASE_URL}members/checksame_address`,
                        params: {
                            houseno: this.country,
                            road: this.address,
                            zip: this.zib
                        }
                    })
                    console.log(groupCheck.data)
                    if (groupCheck.data.response === 'failed') {
                        this.step++
                    } else {
                        this.dialogBox = true
                    }

                }
                //  this.step++
            } else if (this.step == 3) {


                this.v$.studentId.$validate();
                this.v$.university.$validate();
                this.v$.ArrivedDate.$validate();
                this.v$.startDate.$validate();
                this.v$.EndDate.$validate();

                if (this.v$.$errors.length == 0) {
                    this.step++
                }

            }

        },
        noGroup() {
            this.grp_err_text = "Please Add Another Address";
            this.dialogBox = false;
            this.v$.country.$reset();
            this.v$.address.$reset();
            this.v$.zib.$reset();
            this.country = '';
            this.zib = '';
            this.address = '';
        },
        submitMemeber() {
            this.v$.deitryRequirment.$validate();
            this.v$.eggs.$validate();
            this.v$.collectionDay.$validate();
            if (this.v$.$errors.length == 0) {
                const payload = {
                    statusId: 1,
                    title: this.titles,
                    firstname: this.firstName,
                    lastname: this.secondName,
                    phone: this.phone,
                    email: this.email,
                    membersroleId: this.type,
                    dependentname: this.DependentName,
                    noofkids: this.NoKids,
                    area: 0,
                    road: this.address,
                    country: this.formAddress.county,
                    borough: this.borough,
                    address: this.formAddress.formatted_address.join(),
                    houseno: this.country,
                    zip: this.zib,
                    studentid: this.studentId,
                    universityId: this.university,
                    arrivedin: this.ArrivedDate,
                    coursestart: this.startDate,
                    courseend: this.EndDate,
                    diettype: 1,
                    dietTypeId: this.deitryRequirment,
                    eggs: this.eggs,
                    preferredcollectionday: this.collectionDay,
                    dependentname: this.dependentName,
                    dependentphone: this.dependentPhone,
                    dependentemail: this.dependentemail,
                    membertype: this.memberType
                    // expected: this.ExpectedDate
                }
                // if (this.type == 3) {
                //     payload.children = this.child
                // }
                if (this.type > 3) {
                    payload.membersroleId = 2

                }
                if (this.NoKids && !isNaN(this.NoKids)) {
                    payload.children = this.child
                    payload.membersroleId = 3
                    if (this.type != 1) {
                        payload.membersroleId = 2
                    }
                }


                if (this.NoKids == 'Expected') {
                    payload.noofkids = null;
                    payload.expected = this.ExpectedDate;
                    payload.membersroleId = 3;
                }

                this.$emit('add_memeber', payload)
                console.log(payload)
                this.titles = ''
                this.firstName = ''
                this.secondName = ''
                this.phone = ''
                this.email = ''
                this.type = ''
                this.DependentName = ''
                // this.NoKids = ''
                this.area = ''
                this.country = ''
                this.borough = ''
                this.address = ''
                this.zib = ''
                this.studentId = ''
                this.university = ''
                this.ArrivedDate = ''
                this.startDate = ''
                this.EndDate = ''
                this.deitryRequirment = ''
                this.eggs = ''
                this.collectionDa = ''
                this.step = 1
                this.ExpectedDate = ''
                this.heveDependent = null
                this.dependentName = ''
                this.dependentPhone = ''
                this.dependentemail = ''


                this.v$.$reset();

            }
        },
        goBack() {
            if (this.step == 5) {
                this.step = 1

            }
            else if (this.step == 4 && this.type != 1) {
                this.step = 2
            }
            else {
                this.step--
            }
        },


    },
    created() {

    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}

.v-card {
    width: 1000px
}

/* For Desktop View */
@media screen and (min-width: 1024px) {}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {}
</style>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
}
</style>