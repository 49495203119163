<template>
    <div class="text-center">
        <v-dialog v-model="show" transition="dialog-bottom-transition" activator="parent">
            <v-card width="800">
                <v-card-title>
                    <div class="text-h6" style="font-family: Montserrat, sans-serif !important;">{{ title }}</div>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field color="#9c27b0" label="Member ID" density="comfortable"
                                    v-model="item.memberId" variant="outlined"> 
                                </v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" md="6">
                                <v-autocomplete variant="outlined" density="comfortable" v-model="extrasID" multiple 
                                    dense @click="CheckUserColl()" clearable :items="extrasList"
                                    item-title="extrasname" item-value="id" label="Extrass"></v-autocomplete>
                            </v-col> -->
                        </v-row>
                        <v-row>
                            <p class="mb-4"> Extras </p>
                            <v-checkbox v-for="ext in extrasList" :key="ext.id" v-model="extrasID" :label="ext.extrasname" :value="ext.id" @click="check_exras(ext.id)"> </v-checkbox>
                           
                        </v-row>
                        <v-row>
                            <v-textarea variant="outlined" hide-details label="Remarks" v-model="item.remarks" auto-grow
                                outlined rows="2" row-height="25" shaped>
                            </v-textarea>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="(show = false)">
                        cancel
                    </v-btn>
                    <v-btn color="white" style="background: rgb(4 43 76);" @click="updateDistribution()">
                        save
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <snackbarVue :visible="snackbar" :text="mssg" :button="false" :color="snackColor" @close="snackbar = false" />

</template>
<script>
import { mapState, mapActions } from 'vuex';
import snackbarVue from '@/components/snackbar';
import axios from 'axios'

export default {
    name: 'editDistribution',
    components: {
        snackbarVue
    },
    data() {
        return {
            dialog: false,
            id: '',
            extrasID: [],
            snackbar: false,
            snackColor: '',
            mssg: '',
            lenComp: [],
            extrasLists: [
                {
                    id: 0,
                    extrasname: ''

                }
            ],
            remarks: ''
        }
    },
    watch: {
        // extrasID(newVl, oldVl) {


        //     // console.log(newVl[newVl.length-1])
        //     // this.checkExtra(newVl[newVl.length - 1]);
        // },
        visible(newVl, oldVl) {
            if (newVl == true) {
                // this.lenComp = this.item.collectionLogs.length;
                const payload = {
                    role: this.item.member.membersRoleId
                }
                this.GET_EXTRA_LIST(payload)


                this.extrasID = [];
                this.item.collectionLogs.forEach(el => {
                    this.extrasID.push(el.extraId);
                    // this.lenComp.push(el.extraId);
                })
            }
        },
        'item.memberId'(newVl, oldVl){
            if(newVl.length >= 4){
            // console.log(newVl.length)
            this.CheckUserColl()

            }

        },
        deep: true

    },
    props: {
        visible: {
            type: Boolean,
        },
        title: {
            type: String
        },
        item: {
            type: Array
        }

    },
    computed: {
        ...mapState('extras', ['extrasList']),
        ...mapState('destributionLog', ['userCollection']),
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {

        ...mapActions('extras', ['GET_EXTRA_LIST']),
        ...mapActions('destributionLog', ['GET_DESTRIBUTION_LOG_LIST', 'CHECK_USER_COLLECTIONS', 'EXTRAS_CHECK', 'ADD_DISTRIBUTION', 'UPDATE_DISTRIBUTION']),

        async updateDistribution() {
            const payload = {
                id: this.item.id,
                memberId: this.item.memberId,
                // extraId: this.extrasID[0],
                collectionLog: this.extrasID,
                remarks: this.item.remarks
            }
            // console.log(payload)
            this.UPDATE_DISTRIBUTION(payload).then(()=>{
                this.show = false;
                this.GET_DESTRIBUTION_LOG_LIST({page: this.$route.query.page, size: 15});
            }).catch(err=>{
                console.log(err)
            })

            // const payload2 = {
            //     mid: this.item.memberId
            // }
            // await this.CHECK_USER_COLLECTIONS(payload2).then(() => {
            //     console.log(this.userCollection)
            //     // if (this.userCollection.data != null) {
            //     //     query.role = this.userCollection.data.membersRoleId
            //     // }
            //     if (this.userCollection.response == "failed" || this.userCollection.response == "error") {
            //         this.snackColor = 'error'
            //         this.mssg = this.userCollection.message
            //         this.snackbar = true
            //     } else {
            //         console.log(payload)
            //         // this.ADD_DISTRIBUTION(payload).then(()=>{
            //         //     this.visible =  false;
            //         //     this.GET_DESTRIBUTION_LOG_LIST();
            //         //     this.id ='';
            //         //     this.extrasID = [],
            //         //     this.remarks = ''


            //         // })
            //     }
            // }).catch(err => {
            //     console.log(err)
            // })
            // this.$emit('save', payload);
            // this.name = '';
            // this.image = '';
        },
        async CheckUserColl() {
            if (this.item.memberId) {
                const payload = {
                    mid: this.item.memberId
                }
                // console.log(payload)
                const query = {
                    role: ''
                }
                await this.CHECK_USER_COLLECTIONS(payload).then(async () => {
                    // console.log(this.userCollection)
                    if (this.userCollection.data != null) {
                        query.role = this.userCollection.data.membersRoleId
                    }
                    if (this.userCollection.response == "failed" || this.userCollection.response == "error") {
                        this.snackColor = 'error'
                        this.mssg = this.userCollection.message
                        this.item
                        return this.snackbar = true
                    }else{
                    await this.GET_EXTRA_LIST(query).then(() => {
                        this.extrasID = []
                        this.item.collectionLogs.forEach(el => {
                            this.extrasID.push(el.extraId)
                        })
                        this.extrasLists.push(this.extrasList)
                    })
                    }
                })


            }
        },
        async check_exras(ext){
            const payload = {
                extra: ext,
                mid: this.item.memberId
            }
            console.log(payload)
           
            const auth = JSON.parse(localStorage.getItem('user'));
            const extrasCheck = await axios({
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}extras/check_extras`,
                params: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                },

            })
            console.log(extrasCheck)
            if (extrasCheck.data.response == 'failed') {
                this.extrasID.pop()
                this.snackColor = 'error'
                this.mssg = extrasCheck.data.message
                return this.snackbar = true

            }

        },
        async checkExtra(ext) {
            // console.log(ext)
            const payload = {
                extra: ext,
                mid: this.id
            }
            // this.EXTRAS_CHECK(payload)
            const auth = JSON.parse(localStorage.getItem('user'));
            const extrasCheck = await axios({
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}extras/check_extras`,
                params: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                },

            })
            console.log(extrasCheck.data.response)
            if (extrasCheck.data.response == 'failed') {
                this.extrasID.pop()
                this.snackColor = 'error'
                this.mssg = extrasCheck.data.message
                return this.snackbar = true

            }
        }
    },
    created() {
        this.name = '';
        this.image = '';
        this.modelNo = ''
    }
}
</script>
<style scoped>
.v-input__prepend {
    display: none !important;
}

input:focus {
    outline: none !important;
}
</style>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
}
</style>