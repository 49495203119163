<template>
  <v-row class="" justify="center">
    <v-col cols="12" md="4" sm="6" v-for="item in dash" :key="item.id">
      <v-card class="mx-auto" rounded="lg" height="220">
        <v-card-header class="justify-center flex-column" style="height: 100%;">
          <!-- <div> -->
          <div class="card_text text-h3 " style="color:#00284a;">
            {{ item.data }}
          </div>
          <div class="card_text_sub text-h6 pt-2" style="color:#00284a; width: 100%">

            {{ item.title }}

          </div>
          <!-- </div> -->
        </v-card-header>

        <!-- <v-card-actions>
      <v-btn variant="outlined">
        Button
      </v-btn>
    </v-card-actions> -->
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'dashCard',
  props: {
    dash: {
      type: Array
    }
  },
  data: () => ({
    items: [
      {
        id: 1,
        title: 'Students',
        data: 1024
      },
      {
        id: 2,
        title: 'Non-students',
        data: 987
      },
      {
        id: 3,
        title: 'Family',
        data: 56
      },
      {
        id: 4,
        title: 'Students Collected',
        data: '522/1024'
      },
      {
        id: 4,
        title: 'Non-student Collected',
        data: '453/987'
      },
      {
        id: 6,
        title: 'Family Collected',
        data: '38/56'
      },

    ]
  })
}
</script>

<style scoped>
.card_text {
  font-family: Montserrat, sans-serif !important;

}

.card_text_sub {
  font-family: Montserrat, sans-serif !important;
  font-weight: 500;
  width: min-content;
  text-align: center;
  line-height: normal;
}
</style>