import axios from 'axios'
export default {
    namespaced: true,
    state: {
        destributionLogList: {},
        destributionLogReport: {},
        destributionLogTotalPage: '',
        destributionLogTotalCount: '',
        userCollection:{ },
        destributionLogWithId:{ },
        //  editServicetValue:{}
    },
    mutations: {
        SET_DESTRIBUTION_LOG_LIST( state, data){
            state.destributionLogList = data
        },
        SET_DESTRIBUTION_LOG_ROPORT( state, data){
            state.destributionLogReport = data
        },
        SET_DESTRIBUTION_LOG_TOTAL_PAGE( state, data ){
            state.destributionLogTotalPage = data.totalPages
            state.destributionLogTotalCount = data.totalCount
        },
        SET_USER_COLLECTION( state, data ){
            state.userCollection = data
        },
        SET_DIST_LOG_WITH_ID( state, data ){
            state.destributionLogWithId = data
        },
      
    },
    actions: {
        async GET_DESTRIBUTION_LOG_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}distribution/list_of_distribution`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options);
             commit('SET_DESTRIBUTION_LOG_LIST', data.data.data);
            //  console.log(data);
             commit('SET_DESTRIBUTION_LOG_TOTAL_PAGE', data.data);
        },
        async GET_DESTRIBUTION_LOG_REPORT({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}distribution/list_of_distribution`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
                options.params = query
            }
            const data = await axios(options);
             commit('SET_DESTRIBUTION_LOG_ROPORT', data.data.data);
            //  console.log(data.data.data);
            //  commit('SET_DESTRIBUTION_LOG_TOTAL_PAGE', data.data.totalPages);
        },
        async CHECK_USER_COLLECTIONS({commit, state  }, query){
            const auth = JSON.parse(localStorage.getItem('user'));
            const userCollection = await axios({
                method: 'get',
                url:  `${process.env.VUE_APP_BASE_URL}check_userdistribution`,
                params: query,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                },
                
            })
            commit('SET_USER_COLLECTION', userCollection.data)
            // console.log(userCollection)
        },
        async EXTRAS_CHECK({ commit }, payload){
            const auth = JSON.parse(localStorage.getItem('user'));
            const extrasCheck = await axios({
                method: 'get',
                url:  `${process.env.VUE_APP_BASE_URL}extras/check_extras`,
                params: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                },
                
            })
            // console.log(extrasCheck)

            
        },
        async ADD_DISTRIBUTION({ commit }, payload){
            const auth = JSON.parse(localStorage.getItem('user'));
            console.log(payload)
            const addDist = await axios({
                method: 'post',
                url:  `${process.env.VUE_APP_BASE_URL}distribution/add_distribution`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                },
                
            })
            // console.log(addDist)

            
        },
        async GET_DISTRIBUTION_LOG_BY_ID({ commit, state }, query){
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}distribution/list_of_distribution`,
                params:{
                   
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
                options.params = query
            }
            const data = await axios(options);
            // console.log(data.data.data)
            commit('SET_DIST_LOG_WITH_ID', data.data.data[0]);
            // console.log(state.destributionLogWithId)

        },
       async UPDATE_DISTRIBUTION({ commit }, payload){
            const auth = JSON.parse(localStorage.getItem('user'));
            const upadateDist = await axios({
                method: 'post',
                url: `${process.env.VUE_APP_BASE_URL}distribution/update_distribution/${payload.id}`,
                data: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            })
            // console.log(upadateDist)

        },
       async DELETE_DIST_LOG({ commit }, payload){
            const auth = JSON.parse(localStorage.getItem('user'));
            const deleteDist = await axios({
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}distribution/delete_distribution/${payload.id}`,
                // data: payload,
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            })
            // console.log(deleteDist)

        }
        

       
    },
    getters: {
    
        
    }

}