import axios from 'axios'
export default {
    namespaced: true,
    state: {
        universityLis: {},
        universityTotalPage: ''
        //  editServicetValue:{}
    },
    mutations: {
        SET_UNIVERSITY_LIST( state, data){
            state.universityLis = data
        },
        SET_UNIVERSITY_TOTAL_PAGE( state, data ){
            state.universityTotalPage = data
        }
      
    },
    actions: {
        async GET_UNIVERSITY_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}list_of_university`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options)
             commit('SET_UNIVERSITY_LIST', data.data.data)
            //  console.log(data)
             commit('SET_UNIVERSITY_TOTAL_PAGE', data.data.totalPages)
        },
        async ADD_UNIVERSITY({ commit }, payload){
            try {

                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const  adduniversity = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}add_university`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
            //  console.log(adduniversity);

            } catch (error) {
                console.log(error);
            }
            

        },
      
        async DELETE_UNIVERSTY({ commit }, payload){
            // console.log(payload)
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                const deleteUniversity = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}delete_university/${ payload.id }`,
                
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                // console.log(deleteUniversity)

            } catch (error) {
                console.log(error)
            }
        } ,
       
        async EDIT_UNIVERSITY({ commit }, payload){
            try{
                 const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}update_university/${ payload.id }`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                //  console.log(update)

            }catch(error){
                console.log(error)
            }
        },
       
    },
    getters: {
        getClientById:(state) => (id) =>{
            return state.clientList.find( list => list.id === id )
        } 
        
    }

}