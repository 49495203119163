import axios from 'axios'
export default {
    namespaced: true,
    state: {
        extrasList: {},
        extrasTotalPage: ''
        //  editServicetValue:{}
    },
    mutations: {
        SET_EXTRA_LIST( state, data){
            state.extrasList = data
        },
        SET_EXTRA_TOTAL_PAGE( state, data ){
            state.extrasTotalPage = data
        }
      
    },
    actions: {
        async GET_EXTRA_LIST({ commit, state }, query) {
            const auth = JSON.parse(localStorage.getItem('user'));
            // console.log(auth)
            const options = {
                method: 'get',
                url: `${process.env.VUE_APP_BASE_URL}list_of_extras`,
                params:{
                    size: 15
                },
                headers: {
                    Authorization: 'Bearer ' + auth.access_token
                }
            }
            if(query){
               
                options.params = query
            }
            const data = await axios(options)
             commit('SET_EXTRA_LIST', data.data.data)
            //  console.log(data.data)
             commit('SET_EXTRA_TOTAL_PAGE', data.data.totalPages)
        },
        async ADD_EXTRAS({ commit }, payload){
            try {

                const auth = JSON.parse(localStorage.getItem('user'));
                // console.log(payload)
                const  add_extras = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}add_extras`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
            //  console.log(add_extras);

            } catch (error) {
                console.log(error);
            }
            

        },
      
        async DELETE_EXTRA({ commit }, payload){
            // console.log(payload)
            try {
                const auth = JSON.parse(localStorage.getItem('user'));
                const deleteBorough = await axios({
                    method:'get',
                    url :`${process.env.VUE_APP_BASE_URL}delete_extras/${ payload.id }`,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                // console.log(deleteBorough)

            } catch (error) {
                console.log(error)
            }
        } ,
       
        async UPDATE_EXTRAS({ commit }, payload){
            try{
                 const auth = JSON.parse(localStorage.getItem('user'));
                console.log(payload)
                const update = await axios({
                    method:'post',
                    url :`${process.env.VUE_APP_BASE_URL}update_extras/${ payload.id }`,
                    data: payload,
                    headers: {
                        Authorization: 'Bearer ' + auth.access_token
                    }
    
                })
                //  console.log(update)

            }catch(error){
                console.log(error)
            }
        },
       
    },
    getters: {
        getClientById:(state) => (id) =>{
            return state.clientList.find( list => list.id === id )
        } 
        
    }

}