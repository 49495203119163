<template>
    <v-container class="px-10" fluid style="height:100vh; background:#e7f0f7 ;">
        <div class="text-h6 mb-2" style="font-family:'Montserrat', sans-serif !important">
            APP USER
        </div>
        <searchAndFilterToolbar :btn_text="cl_text" @btn_action="dialog = true" @filterBtn="filterDisplay = !filterDisplay" @search="searchUser" :placeholder="'Search with Name'"/>
        <Transition name="slide-fade">
            <div class="mt-4 mb-2" v-if="filterDisplay">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-autocomplete v-model="selStatus" hide-details variant="outlined" density="comfortable"
                            :items="statuses" item-title="name" clearable item-value="id" label="Status">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-autocomplete v-model="selType" hide-details variant="outlined" density="comfortable"
                            :items="types" item-title="type" clearable item-value="id" label="Type">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="2" style="align-self: center;">
                        <v-btn style="background: rgb(4 43 76) ;" @click="filterdata()"> <span
                                style="color: white">filter</span> </v-btn>
                    </v-col>
                </v-row>
            </div>
        </Transition>
        <v-table class="rounded-lg mt-4">
            <thead>
                <tr>
                    <th class="text-left">
                        #
                    </th>
                    <th class="text-left">
                        NAME
                    </th>
                    <th class="text-left">
                        USER NAME
                    </th>
                    <th class="text-left">
                        EMAIL
                    </th>
                    <th class="text-left">
                        TYPE
                    </th>
                    <th class="text-left">
                        STATUS
                    </th>
                    <th class="text-left">
                        ACTIONS
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in appUserList" :key="item.id">

                    <td>{{ i + 1 }}</td>
                    <td>{{ item.fullname }}</td>
                    <td>{{ item.username }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.staffType.type }}</td>
                    <!-- <td>{{ item.status.statustype }}</td> -->
                    <td> 
                        <v-chip :color="item.status.statustype === 'Active' ? 'green' : 'red' " style="min-width: 100px; cursor: pointer;"> <span class="ma-auto">{{
                            item.status.statustype
                            }}</span>
                            <v-menu activator="parent">
                                <v-list class="pa-0">
                                    <v-list-item @click="changeStatus(it, item)" v-for="it in statuses" :key="it">
                                        <v-list-item-title style="color:  ;"> {{ it.name }} </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-chip>
                    </td>
                    <!-- <td>{{ item.city }}</td>
                    <td>{{ item.careOff }}</td>
                    <td>{{ item.type }}</td> -->

                    <td>
                        <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openEditDialog(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="blue-darken-2"
                                style="cursor: pointer;">
                                <v-icon size="18" icon="mdi-pencil-outline"></v-icon>
                            </v-avatar>
                        </v-hover>
                        <!-- <v-hover v-slot="{ isHovering, props }">
                            <v-avatar rounded size="small" v-bind="props" class="mr-1" @click="openDeleteWarn(item)"
                                :class="isHovering ? 'elevation-12' : 'elevation-2'" color="#e9bc10"
                                style="cursor: pointer;">
                                <v-icon size="18" color="white" icon="mdi-trash-can-outline"></v-icon>
                            </v-avatar>
                        </v-hover> -->
                    </td>
                </tr>
            </tbody>
        </v-table>
         <paginationVue :length="appUserTotalPage"  @chanegePage="chanegePage"/>

        <addUser :visible="dialog" @close="dialog = false" @save="addUser" :title="dialog_title" />
        <editUser :visible="edit_dialog" @close="edit_dialog = false" @save="updateSubAdmin" :title="edit_dialog_title"
            :item="appUserWithId" />
        <deleteWarnVue :visible="delete_dialog" @close="delete_dialog = false" :item="deleteValue"
            @delete="deleteAppUser" />
    </v-container>
</template>

<script>

import deleteWarnVue from '@/components/deleteWarn.vue'
import searchAndFilterToolbar from '@/components/searchAndFilterToolbar.vue';
import { mapActions, mapState } from 'vuex'
// import addClients from '@/components/addClients.vue';
import addUser from '@/components/user/addUser.vue';
import editUser from '@/components/user/edituser.vue';
import paginationVue from '@/components/pagination.vue';

export default {
    name: 'app_user',
    components: {
        searchAndFilterToolbar,
        addUser,
        deleteWarnVue,
        editUser,
        paginationVue

    },
    data() {
        return {

            cl_text: 'Add App User',
            dialog: false,
            filterDisplay: false,
            dialog_title: 'Add App User',
            edit_dialog_title: 'Edit App User',
            edit_dialog: false,
            delete_dialog: false,
            deleteValue: {},
            editValue: [],
            editSubAdminId: '',
            statuses: [
                {
                    id: 1,
                    name: 'Active'
                },
                {
                    id: 2,
                    name: 'In-Active'
                },
            ],
            types: [
                {
                    id: 1,
                    type: 'Distribution'
                },
                {
                    id: 2,
                    type: 'Support'
                    
                },
            ],
            selStatus:'',
            selType: ''

        }
    },
    computed: {
        ...mapState('appUser', ['appUserList','appUserWithId', 'appUserTotalPage'])
    },
    methods: {
        ...mapActions('appUser', ['ADD_APP_USER', 'GET_APP_USER_LIST' ,'GET_APP_USER_WITH_ID', 'DELETE_USER', 'UPDATE_APP_USER']),

        //    async addUser(payload){

        //     this.ADD_APP_USER(payload).catch((err)=>{
        //         this.errorValue = err.response.data.message
        //     })
        //   console.log(payload);
        // },
        openDeleteWarn(item) {
            this.deleteValue = item;
            this.delete_dialog = true;
        },
        async deleteAppUser(item) {
                // console.log(item)
               await this.DELETE_USER(item);
               this.delete_dialog = false;
               this.GET_APP_USER_LIST();
        },
        openEditDialog(item) {
            const payload = {
                id: item.id
            }
            this.GET_APP_USER_WITH_ID(payload);
            // this.editValue = item;
            this.edit_dialog = true;
        },
        chanegePage(item){
            const query = {
                page: item,
                size: 15,
                statusId: this.selStatus,
                type: this.selType
            }
            this.GET_APP_USER_LIST(query)
        },
       async changeStatus(it, item){
            const payload = {
                statusId: it.id,
                id: item.id
            }
            await this.UPDATE_APP_USER(payload);
            await this.GET_APP_USER_LIST();
        },
        filterdata(){
            const query = {
                statusId: this.selStatus,
                type: this.selType
            }
        this.GET_APP_USER_LIST(query)

        },
        searchUser(val){
            const query = {
                search: val
            }
        this.GET_APP_USER_LIST(query)

        }
    },
    mounted() {
        this.GET_APP_USER_LIST()
    }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.v-table .v-table__wrapper>table>tbody>tr td {
    border-bottom: none;
    font-size: small;
    font-weight: 500;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    border-bottom: none;
}

.v-table {
    font-family: Montserrat, sans-serif !important;
}

.v-locale--is-ltr .v-table>.v-table__wrapper>table>thead>tr>th {
    font-weight: 600 !important;
}
</style>